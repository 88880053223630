import { filterNumbers, getRangeNumbers, getRangeNumbersFromTo } from "./numberUtils"

const getHoursRangeNumbersFromPeriods = (periods, step = 1) => {
  const hours = []

  for (const { from, to } of periods) {
    if (from < to) {
      const range = {
        from: from.minutes() === 0 && from.seconds() === 0 ? from.hours() : from.hours() + 1,
        to: to.minutes() === 59 && to.seconds() === 59 ? to.hours() : to.hours() - 1,
      }
      hours.push(...getRangeNumbersFromTo(range, step))
    }
  }

  return filterNumbers(hours)
}

const getMinutesRangeNumbersFromPeriods = (periods, disabledHours, selectedHours, step = 1) => {
  const minutes = []

  if (disabledHours.includes(selectedHours)) {
    return getRangeNumbers(60, 0)
  }

  for (const { from, to } of periods) {
    if (from < to) {
      if (from.hours() === selectedHours) {
        const range = {
          from: from.seconds() === 0 ? from.minutes() : from.minutes() + 1,
          to: to.hours() === selectedHours ? (to.seconds() === 59 ? to.minutes() : to.minutes() - 1) : 59,
        }
        minutes.push(...getRangeNumbersFromTo(range, step))
      } else if (to.hours() === selectedHours) {
        const range = {
          from: 0,
          to: to.seconds() === 59 ? to.minutes() : to.minutes() - 1,
        }
        minutes.push(...getRangeNumbersFromTo(range, step))
      }
    }
  }

  return filterNumbers(minutes)
}

const getSecondsFromPeriods = (periods, disabledHours, selectedHours, disabledMinutes, selectedMinutes, step = 1) => {
  const seconds = []

  if (disabledHours.includes(selectedHours) || disabledMinutes.includes(selectedMinutes)) {
    return getRangeNumbers(60, 0)
  }

  for (const { from, to } of periods) {
    if (from <= to) {
      if (from.hours() === selectedHours && from.minutes() === selectedMinutes) {
        const range = {
          from: from.seconds(),
          to: to.hours() === selectedHours && to.minutes() === selectedMinutes ? to.seconds() : 59,
        }
        seconds.push(...getRangeNumbersFromTo(range, step))
      } else if (to.hours() === selectedHours && to.minutes() === selectedMinutes) {
        const range = { from: 0, to: to.seconds() }
        seconds.push(...getRangeNumbersFromTo(range, step))
      }
    }
  }

  return filterNumbers(seconds)
}

export { getHoursRangeNumbersFromPeriods, getMinutesRangeNumbersFromPeriods, getSecondsFromPeriods }
