"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormQVInitializer = void 0;
class FormQVInitializer {
    constructor(form) {
        this.ignoredTypes = [];
        for (const sel of form.selectors.values) {
            if (!this.ignoredTypes.includes(sel.type)) {
                form.qualifications[sel.UID] = !sel.hasQualifications;
                form.qualificationsErrors[sel.UID] = null;
                form.validations[sel.UID] = !sel.hasValidations;
                form.validationsErrors[sel.UID] = null;
            }
        }
    }
}
exports.FormQVInitializer = FormQVInitializer;
