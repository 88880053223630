import { PropertyName, PropertySemanticType } from "./types"

export const componentNames = [
  "bodypartpicker",
  "button",
  "checkbox",
  "checkboxes",
  "datepicker",
  "displayitem",
  "hidden",
  "html",
  "htmlelement",
  "image",
  "link",
  "mask",
  "message",
  "modal",
  "notificationbar",
  "radio",
  "radiocard",
  "searchableradiocard",
  "select",
  "status",
  "switch",
  "table",
  "textarea",
  "textfield",
  "tile",
  "timepicker",
  "upload",
  "legacyupload",
] as const

// Some properties do not require a component name and can be given a property type alone
export const propertyNames = [...componentNames, "array", "number", "integer", "string", "object"] as const

export const semanticTypeMap: Record<PropertyName, PropertySemanticType> = {
  array: PropertySemanticType.Display,
  bodypartpicker: PropertySemanticType.Input,
  button: PropertySemanticType.Display,
  checkbox: PropertySemanticType.Display, // Checkbox uses its own <label> to identify the input
  checkboxes: PropertySemanticType.InputGroup,
  datepicker: PropertySemanticType.Input,
  displayitem: PropertySemanticType.Display,
  hidden: PropertySemanticType.Display,
  html: PropertySemanticType.Display,
  htmlelement: PropertySemanticType.Display,
  image: PropertySemanticType.Display,
  integer: PropertySemanticType.Input,
  link: PropertySemanticType.Display,
  mask: PropertySemanticType.Input,
  message: PropertySemanticType.Display,
  modal: PropertySemanticType.Display,
  notificationbar: PropertySemanticType.Display,
  number: PropertySemanticType.Input,
  object: PropertySemanticType.Display,
  radio: PropertySemanticType.InputGroup,
  radiocard: PropertySemanticType.InputGroup,
  searchableradiocard: PropertySemanticType.InputGroup,
  select: PropertySemanticType.Input,
  status: PropertySemanticType.Display,
  string: PropertySemanticType.Input,
  switch: PropertySemanticType.Display, // Switch uses its own <label> to identify the input
  table: PropertySemanticType.Display,
  textarea: PropertySemanticType.Input,
  textfield: PropertySemanticType.Input,
  tile: PropertySemanticType.Display,
  timepicker: PropertySemanticType.Input,
  upload: PropertySemanticType.Input,
  legacyupload: PropertySemanticType.Input,
}
