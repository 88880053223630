class FilterOption {
  static get supportedFilterOptions() {
    return ["children", "label", "value"]
  }

  constructor(options) {
    this.filterBy = {}
    this.setFilterBy(options)
  }

  setFilterBy(options) {
    if (options) {
      switch (typeof options) {
        case "object":
          if (Array.isArray(options))
            for (const option of options) {
              this.setFilterBy(option)
            }
          break
        case "string":
          this.addFilterOption(options)
          break
        default:
          break
      }
    }
  }

  addFilterOption(optionKey) {
    if (this.isValidFilterOption(optionKey)) {
      this.filterBy[optionKey] = true
    }
  }

  isValidFilterOption(optionKey) {
    return FilterOption.supportedFilterOptions.includes(optionKey)
  }

  hasFilterOptionActivated() {
    return FilterOption.supportedFilterOptions.some((filterKey) => this.filterBy[filterKey])
  }

  buildFilterOptionFunction() {
    if (!this.hasFilterOptionActivated()) {
      return (input, option) =>
        option.label && new RegExp(this.normalize(input), "gi").exec(this.normalize(option.label))
    }
    return (input, option) => {
      return FilterOption.supportedFilterOptions.some((filterKey) => {
        if (!this.filterBy[filterKey]) return false

        if (!option[filterKey]) return false

        const valueToNormalize = filterKey === "label" ? option?.label?.props?.label : option[filterKey]

        return new RegExp(this.normalize(input), "gi").exec(this.normalize(valueToNormalize))
      })
    }
  }

  normalize(value = "") {
    return value
      .toLowerCase()
      .normalize("NFKD")
      .replace(/\p{Diacritic}/gu, "")
  }
}

export { FilterOption }
