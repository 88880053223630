import React, { useContext } from "react"
import useTranslation from "../../hooks/useTranslation"
import { FormContext } from "../../context/form/formState"
import { PrinterIcon } from "./../Icons"
import { Button, Html } from "../index"
import styles from "./DownloadPrintable.module.css"

export const DownloadPrintable = () => {
  const { translate } = useTranslation()
  const { form } = useContext(FormContext)
  const printableVersionUrl = form?.schema?.def?.printableVersion?.url

  if (!printableVersionUrl) {
    return null
  }

  let id = "download-printable"
  if (form.schema["$id"]) {
    id += `-${form.schema["$id"]}`
  }

  return (
    <div className={styles.DownloadPrintable}>
      <Button
        href={printableVersionUrl}
        id={id}
        icon={<PrinterIcon />}
        label={<Html content={translate("downloadPrintable")} />}
        target="_blank"
        type="text"
      />
    </div>
  )
}
