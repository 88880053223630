"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SchemaResolver = void 0;
const tslib_1 = require("tslib");
const AbstractBuilderResolver_1 = require("./AbstractBuilderResolver");
class SchemaResolver extends AbstractBuilderResolver_1.AbstractBuilderResolver {
    resolve(resources, schema) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!Array.isArray(resources)) {
                resources = [resources];
            }
            schema = yield this.loadResources(resources);
            if (!schema) {
                throw new Error("Malformed/Invalid json schema definition object");
            }
            return schema;
        });
    }
}
exports.SchemaResolver = SchemaResolver;
