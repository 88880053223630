"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ValueCompareValidator = void 0;
const tslib_1 = require("tslib");
const AbstractValidator_1 = require("./AbstractValidator");
const utils_1 = require("../../utils");
class ValueCompareValidator extends AbstractValidator_1.AbstractValidator {
    constructor(options, type) {
        super(type);
        this.options = Array.isArray(options) ? options : [options];
    }
    validate({ form, prop }) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            for (const opts of this.options) {
                let answer;
                const selectors = opts.selectors || [prop.UID];
                switch (opts.should) {
                    case "equal":
                        answer = this.shouldEqual(form, selectors, opts);
                        break;
                    case "notEqual":
                        answer = this.shouldNotEqual(form, selectors, opts);
                        break;
                    case "equalOneOf":
                        answer = this.shouldEqualOneOf(form, selectors, opts);
                        break;
                    case "notEqualOneOf":
                        answer = this.shouldNotEqualOneOf(form, selectors, opts);
                        break;
                    case "include":
                        answer = this.shouldInclude(form, selectors, opts);
                        break;
                    case "includeOneOf":
                        answer = this.shouldIncludeOneOf(form, selectors, opts);
                        break;
                    case "partiallyInclude":
                        answer = this.shouldPartiallyInclude(form, selectors, opts);
                        break;
                    case "partiallyIncludeOneOf":
                        answer = this.shouldPartiallyIncludeOneOf(form, selectors, opts);
                        break;
                    default:
                        answer = this.getInvalidOptionAnswer();
                        break;
                }
                if (!answer[0]) {
                    return answer;
                }
            }
            return [true, null];
        });
    }
    getInvalidOptionAnswer() {
        return this.formatResult([false, { code: "INVALID_OPTION" }], this.options);
    }
    shouldEqual(form, selectors, opts) {
        const value = this.getValue(form, opts);
        if (value === undefined) {
            return this.getInvalidOptionAnswer();
        }
        for (const selector of selectors) {
            const [isValidSelector, selectorVal] = form.dataSelector.tryGet(selector);
            if (!isValidSelector ||
                (!Array.isArray(selectorVal) && selectorVal !== value) ||
                (Array.isArray(selectorVal) && !(0, utils_1.arrayEqual)(selectorVal, value))) {
                return this.getErrorAnswer(opts);
            }
        }
        return [true, null];
    }
    shouldNotEqual(form, selectors, opts) {
        const value = this.getValue(form, opts);
        if (value === undefined) {
            return this.getInvalidOptionAnswer();
        }
        for (const selector of selectors) {
            const [isValidSelector, selectorVal] = form.dataSelector.tryGet(selector);
            if (!isValidSelector ||
                (!Array.isArray(selectorVal) && selectorVal === value) ||
                (Array.isArray(selectorVal) && (0, utils_1.arrayEqual)(selectorVal, value))) {
                return this.getErrorAnswer(opts);
            }
        }
        return [true, null];
    }
    shouldEqualOneOf(form, selectors, opts) {
        const values = this.getValues(form, opts);
        if (values.length === 0) {
            return this.getInvalidOptionAnswer();
        }
        for (const selector of selectors) {
            const [isValidSelector, selectorVal] = form.dataSelector.tryGet(selector);
            if (!isValidSelector || (!Array.isArray(selectorVal) && !values.includes(selectorVal))) {
                return this.getErrorAnswer(opts);
            }
            else if (Array.isArray(selectorVal)) {
                let error = true;
                for (const value of values) {
                    if ((0, utils_1.arrayEqual)(value, selectorVal)) {
                        error = false;
                        break;
                    }
                }
                if (error) {
                    return this.getErrorAnswer(opts);
                }
            }
        }
        return [true, null];
    }
    shouldNotEqualOneOf(form, selectors, opts) {
        const values = this.getValues(form, opts);
        if (values.length === 0) {
            return this.getInvalidOptionAnswer();
        }
        for (const selector of selectors) {
            const [isValidSelector, selectorVal] = form.dataSelector.tryGet(selector);
            if (!isValidSelector || (!Array.isArray(selectorVal) && values.includes(selectorVal))) {
                return this.getErrorAnswer(opts);
            }
            else if (Array.isArray(selectorVal)) {
                for (const value of values) {
                    if ((0, utils_1.arrayEqual)(value, selectorVal)) {
                        return this.getErrorAnswer(opts);
                    }
                }
            }
        }
        return [true, null];
    }
    shouldInclude(form, selectors, opts) {
        const value = this.getValue(form, opts);
        if (value === undefined) {
            return this.getInvalidOptionAnswer();
        }
        for (const selector of selectors) {
            const [isValidSelector, selectorVal] = form.dataSelector.tryGet(selector);
            if (!isValidSelector ||
                (!Array.isArray(selectorVal) && selectorVal !== value) ||
                (Array.isArray(selectorVal) && !selectorVal.includes(value))) {
                return this.getErrorAnswer(opts);
            }
        }
        return [true, null];
    }
    shouldIncludeOneOf(form, selectors, opts) {
        const values = this.getValues(form, opts);
        if (values.length === 0) {
            return this.getInvalidOptionAnswer();
        }
        for (const selector of selectors) {
            const [isValidSelector, selectorVal] = form.dataSelector.tryGet(selector);
            if (!isValidSelector || (!Array.isArray(selectorVal) && !values.includes(selectorVal))) {
                return this.getErrorAnswer(opts);
            }
            else if (Array.isArray(selectorVal)) {
                let error = true;
                for (const value of values) {
                    if (selectorVal.includes(value)) {
                        error = false;
                        break;
                    }
                }
                if (error) {
                    return this.getErrorAnswer(opts);
                }
            }
        }
        return [true, null];
    }
    shouldPartiallyInclude(form, selectors, opts) {
        const value = this.getValue(form, opts);
        if (value === undefined) {
            return this.getInvalidOptionAnswer();
        }
        for (const selector of selectors) {
            const [isValidSelector, selectorVal] = form.dataSelector.tryGet(selector);
            if (isValidSelector) {
                if ((!Array.isArray(selectorVal) && selectorVal === value) ||
                    (Array.isArray(selectorVal) && selectorVal.includes(value))) {
                    return [true, null];
                }
            }
        }
        return this.getErrorAnswer(opts);
    }
    shouldPartiallyIncludeOneOf(form, selectors, opts) {
        const values = this.getValues(form, opts);
        if (values.length === 0) {
            return this.getInvalidOptionAnswer();
        }
        for (const selector of selectors) {
            const [isValidSelector, selectorVal] = form.dataSelector.tryGet(selector);
            if (!isValidSelector) {
                continue;
            }
            if (!Array.isArray(selectorVal) && values.includes(selectorVal)) {
                return [true, null];
            }
            else if (Array.isArray(selectorVal)) {
                for (const value of values) {
                    if (selectorVal.includes(value)) {
                        return [true, null];
                    }
                }
            }
        }
        return this.getErrorAnswer(opts);
    }
    getValue(form, opts) {
        if (opts.value !== undefined) {
            return opts.value;
        }
        else if (typeof opts.valueSelectors === "string") {
            const [isValidSelector, selectorVal] = form.dataSelector.tryGet(opts.valueSelectors);
            if (isValidSelector) {
                return selectorVal;
            }
        }
        return undefined;
    }
    getValues(form, opts) {
        if (opts.value) {
            return opts.value;
        }
        else if (opts.valueSelectors) {
            return opts.valueSelectors.map((selector) => {
                const [isValidSelector, selectorVal] = form.dataSelector.tryGet(selector);
                if (isValidSelector) {
                    return selectorVal;
                }
            });
        }
        return [];
    }
    getErrorAnswer(opts) {
        return this.formatResult([false, { code: "INVALID_SELECTOR_VALUE" }], opts);
    }
}
exports.ValueCompareValidator = ValueCompareValidator;
