import React from "react"
import AntdSpin from "antd/lib/spin"
import { Message, MessagePropsInterface } from ".."
import styles from "./LoadingMessage.module.css"

export interface LoadingMessagePropsInterface extends MessagePropsInterface {
  className?: string
  tip?: React.ReactNode
}

const LoadingMessage = ({ className, tip, ...message }: LoadingMessagePropsInterface): JSX.Element => {
  const classNames: string[] = [styles.LoadingMessage]
  if (className) {
    classNames.push(className)
  }

  return (
    <div className={classNames.join(" ")}>
      <AntdSpin tip={tip}>
        <Message {...message} />
      </AntdSpin>
    </div>
  )
}

export default LoadingMessage
