export default {
  INIT_DEV_PANEL: "INIT_DEV_PANEL",
  SET_CUSTOM_EVENTS: "SET_CUSTOM_EVENTS",
  SET_DISPLAY_DEV_PANEL: "SET_DISPLAY_DEV_PANEL",
  UPDATE_AUTO_UPDATE: "UPDATE_AUTO_UPDATE",
  UPDATE_EDITOR_THEME: "UPDATE_EDITOR_THEME",
  UPDATE_IS_EDITABLE: "UPDATE_IS_EDITABLE",
  UPDATE_TAB_ACTIVE_KEY: "UPDATE_TAB_ACTIVE_KEY",
  UPDATE_SCHEMA: "UPDATE_SCHEMA",
  UPDATE_Q_V_FILTERS: "UPDATE_Q_V_FILTERS",
  UPDATE_Q_V_TABLE: "UPDATE_Q_V_TABLE",
}
