"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MetaFormQualificationsPlugin = void 0;
const PluginBase_1 = require("../../Form/Plugin/PluginBase");
class MetaFormQualificationsPlugin extends PluginBase_1.PluginBase {
    constructor() {
        super(...arguments);
        this.name = "metaFormQualifications";
        this.metaKey = "qualifications";
    }
    formCreated({ form }) {
        form.meta[this.metaKey] = form.qualifications;
    }
    dataUpdated({ form }) {
        form.meta[this.metaKey] = form.qualifications;
    }
    formPass({ form }) {
        form.meta[this.metaKey] = form.qualifications;
    }
}
exports.MetaFormQualificationsPlugin = MetaFormQualificationsPlugin;
