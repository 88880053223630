"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.partialFormValidationHandler = void 0;
const partialFormValidationHandler = (form) => {
    const partialFormValidation = form.plugins.get("partialFormValidation");
    const processedSelectors = [];
    for (const [partialSelector, threshold] of Object.entries(partialFormValidation.selectors)) {
        const [isValid, selectorProcessedChildren] = partialFormValidation.isValid(form, partialSelector, threshold);
        processedSelectors.push(...selectorProcessedChildren);
        if (!isValid) {
            return false;
        }
    }
    for (const selInfo of form.selectors.values) {
        if (selInfo.isAnswerable &&
            !processedSelectors.includes(selInfo.UID) &&
            form.qualifications[selInfo.UID] &&
            !form.validations[selInfo.UID]) {
            return false;
        }
    }
    return true;
};
exports.partialFormValidationHandler = partialFormValidationHandler;
