import React, { useContext } from "react"
import AceEditor from "react-ace"
import "ace-builds/src-noconflict/mode-json5"
import "ace-builds/src-noconflict/theme-ambiance"
import "ace-builds/src-noconflict/theme-kr_theme"
import "ace-builds/src-noconflict/theme-github"
import "ace-builds/src-noconflict/theme-monokai"
import Alert from "antd/lib/alert"
import { DevPanelContext } from "../../../context/devPanel/devPanelState"

export const Editor = ({ errorMessage, isValid = true, onChange, readOnly, value }) => {
  const { editorTheme } = useContext(DevPanelContext)

  return (
    <>
      {!isValid && <Alert message={errorMessage} type="error" />}
      <AceEditor
        theme={editorTheme}
        value={value}
        mode="json5"
        fontSize={14}
        tabSize={2}
        onChange={onChange}
        showLineNumbers={true}
        showPrintMargin={true}
        showGutter={true}
        width="100%"
        height={`calc(100vh - ${isValid ? 135 : 175}px)`}
        enableBasicAutocompletion={false}
        enableLiveAutocompletion={false}
        enableSnippets={false}
        readOnly={readOnly}
      />
    </>
  )
}
