"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SchemaDataMapper = void 0;
const Property_1 = require("../../Schema/Property/Property");
class SchemaDataMapper {
    constructor(form, defaultValue = null) {
        form.data = this.mapData({}, form.schema.childProps(), defaultValue);
    }
    mapData(data, props, defaultValue = null) {
        props.forEach((prop, id) => {
            if (prop.type !== Property_1.Property.TYPE_OBJECT && prop.type !== Property_1.Property.TYPE_ARRAY) {
                if (prop.type !== Property_1.Property.TYPE_NULL) {
                    data[id] = defaultValue;
                }
            }
            else if (prop.type === Property_1.Property.TYPE_ARRAY) {
                data[id] = [];
            }
            else if (prop.hasChildProps()) {
                data[id] = Object.assign({}, this.mapData({}, prop.childProps()));
                if (Object.entries(data[id]).length == 0) {
                    delete data[id];
                }
            }
        });
        return data;
    }
}
exports.SchemaDataMapper = SchemaDataMapper;
