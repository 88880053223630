import { FileWithPath } from "@akinoxsolutions/gerudo-ui/dist/Dropzone"
import { Attachment } from "./Attachment"
import { Dispatch, SetStateAction } from "react"
import { SignalRAttachmentEvents } from "./types"

export const isAttachment = (file?: FileWithPath): file is Attachment => {
  // response array is for used to identify legacyAttachments
  return (file as Attachment)?.response !== undefined && !Array.isArray((file as Attachment).response)
}

// Function copied from brigid
export const uploadFileByChunk = (
  file: Attachment,
  setFiles: Dispatch<SetStateAction<FileWithPath[]>>,
  apiUrl: string,
) => {
  const CHUNK_SIZE = 1024 * 1024 * 5 // 5mb
  let currentChunk = 0
  let bytesUploaded = 0
  const chunks = Math.ceil(file.size / CHUNK_SIZE)
  const removeStopChunk = `RemoveStopChunk-${file.response.id}`
  const stopChunk = `StopChunk-${file.response.id}`

  function sendChunk(chunkIndex: number, chunkCount: number) {
    const start = chunkIndex * CHUNK_SIZE
    const end = Math.min(file.size, start + CHUNK_SIZE)
    const chunk = file.slice(start, end)

    const data = new FormData()
    data.append("index", chunkIndex.toString())
    data.append("chunk", chunk)
    data.append("chunk_count", chunkCount.toString())

    const url = `${apiUrl}upload/${file.response.id}`
    xhr.open("POST", url)
    xhr.setRequestHeader("Content-Range", `bytes ${start}-${end - 1}/${file.size}`)
    xhr.send(data)
  }

  const xhr = new XMLHttpRequest()

  xhr.upload.addEventListener("progress", (event) => {
    const percentageProgress = calculatePercentageProgress(bytesUploaded, event.loaded, file.size)
    onUploadFileProgress(file, percentageProgress, setFiles)
  })

  xhr.addEventListener("load", () => {
    currentChunk++
    bytesUploaded = Math.min(file.size, bytesUploaded + CHUNK_SIZE)
    if (currentChunk < chunks) {
      sendChunk(currentChunk, chunks)
    } else {
      onUploadFileSuccess(file, setFiles)
    }
  })

  const abort = () => {
    document.removeEventListener(removeStopChunk, RemoveStopChunk)
    xhr.abort()
    onUploadFileProgress(file, 0, setFiles)
  }

  const RemoveStopChunk = () => {
    document.removeEventListener(stopChunk, abort)
    document.removeEventListener(removeStopChunk, RemoveStopChunk)
  }

  document.addEventListener(stopChunk, abort)
  document.addEventListener(removeStopChunk, RemoveStopChunk)

  xhr.addEventListener("error", (e) => onUploadFileError(file, e))

  sendChunk(currentChunk, chunks)
}

const onUploadFileProgress = (
  file: Attachment,
  progress: number,
  setFiles: Dispatch<SetStateAction<FileWithPath[]>>,
) => {
  setFiles((prev) => {
    file.setProgress(progress)
    return [...prev] // Change state value ref to trigger rerender because shallow compare
  })
}

const onUploadFileSuccess = (file: Attachment, setFiles: Dispatch<SetStateAction<FileWithPath[]>>) => {
  onUploadFileProgress(file, 99, setFiles)
}

const onUploadFileError = (file: Attachment, error: unknown) => {
  console.error("File upload failed", error) // eslint-disable-line no-console
}

export const onSignalRFileStatusUpdated = (
  event,
  files: Attachment[],
  setFiles: Dispatch<SetStateAction<FileWithPath[]>>,
) => {
  const { payload } = event ?? {}
  const { id, status } = payload ?? {}

  if (id && status) {
    if (status === SignalRAttachmentEvents.UploadComplete) {
      const eventToRemove = new CustomEvent(`RemoveStopChunk-${id}`)
      document.dispatchEvent(eventToRemove)
    }

    const file = files.find((f) => f.response.id === id)
    file?.setUploadStatus(status)
    setFiles((prev) => [...prev])
  }
}

export const calculatePercentageProgress = (bytesUploaded: number, loaded: number, fileSize: number): number => {
  if (!fileSize || fileSize === 0) {
    return 0
  }
  return Math.round(((bytesUploaded + loaded) / fileSize) * 100)
}
