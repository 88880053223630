import React from "react"
import { PanelContent } from "./PanelContent/PanelContent"
import { PanelMenu } from "./PanelMenu/PanelMenu"
import styles from "./DevPanel.module.css"

export const DevPanel = () => (
  <div className={styles.DevPanel}>
    <PanelMenu />
    <PanelContent />
  </div>
)
