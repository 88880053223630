"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AbstractBuilderResolver = void 0;
const tslib_1 = require("tslib");
const StringLoader_1 = require("../Loader/StringLoader");
const utils_1 = require("../../utils");
class AbstractBuilderResolver {
    loadResources(resources) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let def = {};
            for (const res of resources) {
                if (typeof res === "string") {
                    def = (0, utils_1.deepMerge)(def, yield new StringLoader_1.StringLoader().load(res));
                }
                else if (typeof res === "object") {
                    def = (0, utils_1.deepMerge)(def, res);
                }
                else {
                    throw new Error(`Unable to resolve resource ${res}`);
                }
            }
            return def;
        });
    }
}
exports.AbstractBuilderResolver = AbstractBuilderResolver;
