"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FnValidator = void 0;
const tslib_1 = require("tslib");
const AbstractValidator_1 = require("./AbstractValidator");
const ExpressionCompiler_1 = require("./ExpressionCompiler");
class FnValidator extends AbstractValidator_1.AbstractValidator {
    constructor(options, type) {
        super(type);
        this.options = options;
        this.hasBeenChecked = false;
        this.evalError = null;
        this.expression = this.options.expression;
        this.expressionCompiler = new ExpressionCompiler_1.ExpressionCompiler(this.expression);
    }
    validate(ctx) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!this.hasBeenChecked) {
                const [canBeEvaluated, error] = this.expressionCompiler.canBeEvaluated(ctx);
                this.hasBeenChecked = true;
                this.evalError = error;
                if (!canBeEvaluated) {
                    return this.cannotBeEvaluated();
                }
            }
            if (this.evalError) {
                return this.cannotBeEvaluated();
            }
            const result = eval(this.expressionCompiler.resolveExpressionPlaceHolders(ctx));
            if (!result) {
                return this.formatResult([false, { code: "INVALID_VALUE", context: this.options }], this.options);
            }
            return [true, null];
        });
    }
    cannotBeEvaluated() {
        return this.formatResult([
            false,
            {
                code: "FUNC_EXPRESSION_CANNOT_BE_EVALUATED",
                context: this.evalError,
            },
        ], this.options);
    }
}
exports.FnValidator = FnValidator;
