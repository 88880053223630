"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DefinitionSelectorsReferenceMapper = void 0;
class DefinitionSelectorsReferenceMapper {
    map(def) {
        if (!def) {
            return [];
        }
        const selectors = [];
        for (const [k, v] of Object.entries(def)) {
            if (k === "selectors") {
                Array.isArray(v) ? selectors.push(...v) : selectors.push(v);
            }
            else if (typeof v === "object") {
                selectors.push(...this.map(v));
            }
        }
        return selectors;
    }
}
exports.DefinitionSelectorsReferenceMapper = DefinitionSelectorsReferenceMapper;
