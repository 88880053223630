"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DefinitionProcessors = void 0;
class DefinitionProcessors {
    constructor(processors) {
        this.processors = processors;
    }
    process(def) {
        for (const processor of this.processors) {
            def = processor.process(def);
        }
        return def;
    }
}
exports.DefinitionProcessors = DefinitionProcessors;
