"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Events = void 0;
var Events;
(function (Events) {
    Events["DataUpdated"] = "data:updated";
    Events["DataQualification"] = "data:qualification";
    Events["DataValidation"] = "data:validation";
    Events["FormPass"] = "form:pass";
    Events["FormCreated"] = "form:created";
})(Events || (exports.Events = Events = {}));
