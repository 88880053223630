"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MetaFormStatsPlugin = void 0;
const PluginBase_1 = require("../../Form/Plugin/PluginBase");
class MetaFormStatsPlugin extends PluginBase_1.PluginBase {
    constructor({ metaKey = "stats", injectOnUpdate = true }) {
        super();
        this.name = "metaFormStats";
        this.injectOnUpdate = injectOnUpdate;
        this.metaKey = metaKey;
    }
    dataUpdated({ form }) {
        if (this.injectOnUpdate) {
            form.meta[this.metaKey] = form.stats;
        }
    }
    formPass({ form }) {
        if (!this.injectOnUpdate) {
            form.meta[this.metaKey] = form.stats;
        }
    }
}
exports.MetaFormStatsPlugin = MetaFormStatsPlugin;
