import { PropertyInterface } from "../../../lib"
import { getComponentName } from "./uiUtils"

const getPropertyUiOptions = <T>(property: PropertyInterface): T => {
  const options = {}
  if (property.def.ui) {
    Object.assign(options, property.def.ui.options)
    const componentName = getComponentName(property.def.ui.type)
    if (componentName) {
      Object.assign(options, property.def.ui[`${componentName}Options`])
    }
  }
  return options as T
}

export { getPropertyUiOptions }
