export type SectionId = string

// just no!
export type FormSelector = string

enum ActionTypes {
  ADD_ITEM_FAILED,
  ADD_ITEM_SUCCEEDED,
  ADD_ITEM_TO,
  CLEAR_AUTOFOCUS_UID,
  SET_AUTOFOCUS_UID,
  DELETE_ITEM,
  DELETE_ITEM_FAILED,
  DELETE_ITEM_SUCCEEDED,
  FETCH_FORM,
  FETCH_FORM_EMPTY,
  FETCH_FORM_ERROR,
  FETCH_FORM_SUCCESS,
  FORM_INIT,
  FORM_RESET,
  FORM_SUBMITTED,
  RESET_DATA_FINISHED,
  RESET_DATA_IN_PROGRESS,
  SET_FORM_BUILD_OPTIONS,
  SET_FORM_STYLE,
  SET_STEP,
  SUBMIT_FORM,
  UPDATE_DATA_FINISHED,
  UPDATE_DATA_IN_PROGRESS,
  REFRESH_FINISHED,
  REFRESH_IN_PROGRESS,
}

export default ActionTypes
