import { useEffect } from "react"

export const useSetOnKeyChangeDateFieldEventListeners = ({ name, valueRef, format, handleChangeOnBlur }) => {
  useEffect(() => {
    document.getElementById(name)?.addEventListener("keyup", (e) => {
      valueRef.current = (e?.target as HTMLInputElement)?.value
    })

    document.getElementById(name)?.addEventListener("keydown", (e) => {
      if (e.key === "Enter") {
        // slightly hacky way to go around the antd default "enter" behavior + hardcoded bullshit for year+month fields because antd treats they differently somehow
        if (format === "YYYY-MM") {
          setTimeout(() => handleChangeOnBlur())
        } else {
          handleChangeOnBlur()
        }
      }
    })

    return () => {
      document.getElementById(name)?.removeEventListener("keyup", (e) => {
        valueRef.current = (e?.target as HTMLInputElement)?.value
      })

      document.getElementById(name)?.removeEventListener("keydown", (e) => {
        if (e.key === "Enter") {
          if (format === "YYYY-MM") {
            setTimeout(() => handleChangeOnBlur())
          } else {
            handleChangeOnBlur()
          }
        }
      })
    }
  }, [])
}
