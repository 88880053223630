import React from "react"

const dateRegex = new RegExp("(\\d{4}-\\d{2}-\\d{2})")
const shortDateRegex = new RegExp("(\\d{4}-\\d{2})")

type DateRegexManipulationProp = { ref: React.MutableRefObject<string>; format: string }

// formats a date like "19990101" to "1999-01-01" (according to the format)
const formatValueRef = ({ ref, format }: DateRegexManipulationProp): string => {
  // Remove special characters from the format
  const formatWithoutSpecialChars = format.replace(/[-:]/g, "")

  // Check if the length of the ref matches the format without special characters
  if (ref.current.length !== formatWithoutSpecialChars.length) {
    return ref.current // Invalid input, return null
  }

  // Perform the formatting based on the format provided
  let formattedValue = ""
  let valueIndex = 0

  for (let formatIndex = 0; formatIndex < format.length; formatIndex++) {
    if (!["-", ":"].includes(format[formatIndex])) {
      formattedValue += ref?.current[valueIndex]
      valueIndex++
    } else {
      // Keep the special character from the format
      formattedValue += format[formatIndex]
    }
  }

  return formattedValue
}

export const regexTestByDateFormat = ({
  ref,
  format,
}: DateRegexManipulationProp): { isValid: boolean; value: string } => {
  const formattedValue = formatValueRef({ format, ref })

  if (format === "YYYY-MM-DD") {
    return { isValid: dateRegex.test(formattedValue), value: formattedValue }
  } else if (format === "YYYY-MM") {
    return { isValid: shortDateRegex.test(formattedValue), value: formattedValue }
  }

  return { isValid: false, value: ref.current }
}
