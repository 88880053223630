import React, { useRef } from "react"
import { DatePicker as GerudoDatePicker } from "@akinoxsolutions/gerudo-ui/dist/DatePicker"
import useTranslation from "../../hooks/useTranslation"
import { getSubType } from "../../utils/uiUtils"
import { getBeginningOfToday } from "../../utils/dateUtils"
import { convertMomentToString, convertStringToMoment } from "../../utils/dateUtils"
import { regexTestByDateFormat } from "./DatePicker.helpers"
import moment from "moment"
import { useSetOnKeyChangeDateFieldEventListeners } from "./DatePicker.hooks"
import Button from "../Button/Button"

export const DatePicker = ({
  autofocus,
  id,
  isDisabled,
  isReadOnly,
  name,
  onChange,
  property,
  value,
  onBlur,
  ...otherProps
}) => {
  const valueRef = useRef("")
  const { translate } = useTranslation()

  const {
    def: { ui = {} },
  } = property
  const {
    disabledDate,
    format,
    inputReadOnly,
    measureOfTime,
    placeholder,
    showClear,
    showTime,
    showToday,
    presetOptions,
    type,
    disableFutureDates,
    disablePastDates,
    placement,
  } = ui
  const subType = getSubType(type)
  const trPlaceholder = translate(placeholder)
  const pickerPlaceholder = subType === "range" ? [trPlaceholder, trPlaceholder] : trPlaceholder
  const pickerFormat = format || (showTime ? "YYYY-MM-DD HH:mm" : "YYYY-MM-DD")

  function disableDateCalculations(current) {
    const disabledDateCalculation = disabledDate && disabledDate(current)
    const disabledFutureDatesCalculation = disableFutureDates && current && current.valueOf() > Date.now()
    const disabledPastDatesCalculation = disablePastDates && current && current.valueOf() < getBeginningOfToday()

    return disabledDateCalculation || disabledFutureDatesCalculation || disabledPastDatesCalculation
  }

  const onChangeHandler = (e) => {
    onChange(convertMomentToString(e))

    // if date is reset with the "x" button, empty the valueRef
    if (!e) {
      valueRef.current = ""
    }
  }

  const handleChangeOnBlur = () => {
    const { isValid, value: formattedValue } = regexTestByDateFormat({ format: pickerFormat, ref: valueRef })

    // if you need to add (value !== formattedValue) check
    // make sure to update the useSetOnKeyChangeDateFieldEventListeners dependency
    if (isValid) {
      const momentValue = moment(formattedValue)
      const isDisabledDate = disableDateCalculations(momentValue)

      if (!isDisabledDate && momentValue.isValid()) {
        onChangeHandler(momentValue)
      }
    }
  }

  const getPresetButtons = (options, closePopper) => {
    return options.map((option) => {
      return (
        <Button
          type="text"
          label={translate(option.label)}
          onClick={() => {
            onChange(moment().add(option.value, option.unit))
            closePopper()
          }}
          id={`preset-button-${option.value}-${option.unit}`}
          key={`preset-button-${option.value}-${option.unit}`}
        ></Button>
      )
    })
  }

  // add a "keyup" + "keydown" functionality (antd doesn't expose one) to control valid input better;
  // ex: if entering 19990101 and pushing enter or clicking out of the input
  // it will format the value to 1999-01-01 and set it
  useSetOnKeyChangeDateFieldEventListeners({
    name,
    valueRef,
    format: pickerFormat,
    handleChangeOnBlur,
  })

  const onBlurHandler = () => {
    handleChangeOnBlur()
    if (onBlur) {
      onBlur()
    }
  }

  return (
    <GerudoDatePicker
      allowClear={showClear !== false && !isReadOnly && !isDisabled}
      autoFocus={autofocus}
      disabled={isDisabled || isReadOnly || inputReadOnly}
      disabledDate={(current) => isReadOnly || isDisabled || disableDateCalculations(current)}
      format={pickerFormat}
      id={id}
      inputReadOnly={isReadOnly || inputReadOnly}
      name={name}
      onChange={onChangeHandler}
      picker={measureOfTime}
      placeholder={pickerPlaceholder}
      showTime={showTime}
      showToday={showToday}
      type={subType}
      placement={placement}
      value={convertStringToMoment(value)}
      onBlur={onBlurHandler}
      renderExtraFooter={presetOptions ? ({ closePopper }) => getPresetButtons(presetOptions, closePopper) : null}
      {...otherProps}
    />
  )
}
