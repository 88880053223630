import React from "react"
import useTranslation from "../../hooks/useTranslation"
import { getComponentName } from "../../utils/uiUtils"
import { TextArea } from "./TextArea/TextArea"
import { TextField } from "./TextField/TextField"

export const Input = ({ isDisabled, isReadOnly, onChange, property, ...otherProps }) => {
  const { translate } = useTranslation()
  const { maxLength, ui = {} } = property.def
  const { hideCount, placeholder: uiPlaceholder, type: uiType } = ui
  const placeholder = uiPlaceholder ? translate(uiPlaceholder) : undefined
  const onChangeHandler = (e) => onChange(e.target.value)

  if (getComponentName(uiType) === "textarea") {
    const { textAreaOptions = {} } = ui
    return (
      <TextArea
        disabled={isDisabled || isReadOnly}
        maxLength={maxLength ? parseInt(maxLength) : undefined}
        onChange={onChangeHandler}
        placeholder={placeholder}
        showCount={!hideCount}
        {...otherProps}
        {...textAreaOptions}
      />
    )
  }
  return (
    <TextField
      disabled={isDisabled || isReadOnly}
      maxLength={maxLength ? parseInt(maxLength) : undefined}
      onChange={onChangeHandler}
      placeholder={placeholder}
      type={uiType}
      {...otherProps}
    />
  )
}
