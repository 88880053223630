import React, { useContext } from "react"
import useTranslation from "../../hooks/useTranslation"
import { arrayModes, supportedModes } from "./modes"
import { FilterOption } from "./FilterOption"
import { FormContext } from "../../context/form/formState"
import { getSortedEnum } from "../../utils/translationUtils"
import {
  Select as GerudoSelect,
  SelectOption,
  SelectOptGroup,
  SelectProps,
} from "@akinoxsolutions/gerudo-ui/dist/Select"
import { Textarea as GerudoTextArea } from "@akinoxsolutions/gerudo-ui/dist/Input"
import style from "./Select.module.css"
import { AnswerableComponentPropsInterface } from "../interfaces"
import classnames from "classnames"

export type SelectValue = string | number

export interface SelectPropsInterface extends AnswerableComponentPropsInterface<SelectValue | SelectValue[]> {
  open?: boolean
}

type SelectLink = string | { value: SelectValue; group?: string }

type SelectEnumMember = {
  value: SelectValue
  label?: string
  link?: SelectLink | SelectLink[]
  group?: string
  score?: number
  description?: string
}

export const Select = ({
  autofocus,
  isDisabled,
  isReadOnly,
  onChange,
  property,
  value,
  ...otherProps
}: SelectPropsInterface) => {
  const { translate } = useTranslation()
  const { form } = useContext(FormContext)

  if (!form) return null

  property = form.selectors.get(property.UID).property() // re-rendering of linked enum in array (hack)
  const { enum: enumData = [], linkedEnumParent, ui = {} } = property.def
  const { listHeight, mode: uiMode, placeholder, selectOptions = {}, showSearch: uiShowSearch, size } = ui
  const { filterOptions, mode, showSearch = false, sortDirection } = selectOptions

  const ungroupedKey = "__noGroup"
  const groupedData: Record<string, SelectOption[]> = { [ungroupedKey]: [] }
  const isLinkedToParent = Boolean(linkedEnumParent)
  let linkedParentValue
  if (isLinkedToParent) {
    const [isValidLink, parentValue] = form.dataSelector.tryGet(linkedEnumParent)
    if (isValidLink) {
      linkedParentValue = parentValue
    }
  }
  const sortedEnum = getSortedEnum<SelectEnumMember>(translate, enumData, sortDirection)

  for (const [index, opt] of sortedEnum.entries()) {
    const key = `${property.UID}-${index}`
    if (typeof opt === "object") {
      let groupKey: string | undefined = undefined
      if (isLinkedToParent) {
        if (typeof opt.link === "string") {
          if (opt.link === linkedParentValue) {
            groupKey = opt.group ?? ungroupedKey
          }
        } else if (Array.isArray(opt.link)) {
          const link = opt.link.find(
            (x) =>
              (typeof x === "string" && x === linkedParentValue) ||
              (typeof x === "object" && x.value === linkedParentValue),
          )
          if (typeof link === "string") {
            groupKey = opt.group ?? ungroupedKey
          } else if (typeof link === "object") {
            groupKey = link.group ?? opt.group ?? ungroupedKey
          }
        }
      } else {
        groupKey = opt.group ?? ungroupedKey
      }

      if (groupKey) {
        if (!groupedData[groupKey]) {
          groupedData[groupKey] = []
        }
        groupedData[groupKey].push({
          ...opt,
          key,
          label: <OptionLabel label={opt.label ?? opt.value.toString()} description={opt.description} />,
        })
      }
    } else if (typeof opt === "string") {
      groupedData[ungroupedKey].push({ key, value: opt, label: <OptionLabel label={opt} /> })
    }
  }

  const options: (SelectOption | SelectOptGroup)[] = []

  for (const [group, groupOpts] of Object.entries(groupedData)) {
    if (group === ungroupedKey) {
      options.push(...groupOpts)
    } else {
      options.push({ label: translate(group), options: groupOpts })
    }
  }

  const props: typeof otherProps & Partial<Pick<SelectProps, "value" | "mode">> = { ...otherProps }
  if (typeof mode === "string" || typeof uiMode === "string") {
    const currentMode = mode ? mode.toLowerCase() : uiMode.toLowerCase()
    if (supportedModes.includes(currentMode)) {
      props.mode = currentMode
      if (arrayModes.includes(currentMode) && value === null) {
        props.value = []
      }
    }
  }

  const showTextAreaWhenDisabled = (isDisabled || isReadOnly) && selectOptions?.mode !== "multiple"

  const selectedEnumOption = sortedEnum.find((x) => x.value === value)

  const disabledValue = () => {
    return selectedEnumOption?.label ?? ""
  }

  return (
    <>
      {!showTextAreaWhenDisabled ? (
        <GerudoSelect
          autoFocus={autofocus}
          defaultValue={form.selectors.getDefaultValue(property.UID) as SelectValue | undefined}
          disabled={isDisabled || isReadOnly}
          filterOption={filterOptions ? new FilterOption(filterOptions).buildFilterOptionFunction() : undefined}
          listHeight={listHeight}
          onChange={(newValue) => onChange(newValue)}
          options={options}
          placeholder={placeholder ? translate(placeholder) : undefined}
          showSearch={showSearch || uiShowSearch}
          size={size}
          value={value}
          {...props}
        />
      ) : (
        <>
          <GerudoTextArea
            autoFocus={autofocus}
            disabled={true}
            placeholder={placeholder ? translate(placeholder) : undefined}
            showCount={false}
            value={disabledValue()}
            autoSize={{
              minRows: 1,
              maxRows: 12,
            }}
            {...otherProps}
          />
          {/*
            When in readonly mode, if the selected enum option contains a description,
            we display the description under the disabled field (See stories ^^)
          */}
          {isReadOnly && selectedEnumOption?.description && (
            <p className={classnames(style.Description, style.Readonly)}>{translate(selectedEnumOption.description)}</p>
          )}
        </>
      )}
    </>
  )
}

const OptionLabel = ({ label, description }: { label?: string; description?: string }) => {
  const { translate } = useTranslation()
  return (
    <div>
      {translate(label)}
      {description && <div className={style.Description}>{translate(description)}</div>}
    </div>
  )
}
