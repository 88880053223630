"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SchemaEventsSelectorsReferenceMapper = void 0;
const DefinitionSelectorsReferenceMapper_1 = require("./DefinitionSelectorsReferenceMapper");
class SchemaEventsSelectorsReferenceMapper {
    constructor() {
        this.defSelectorsReferenceMapper = new DefinitionSelectorsReferenceMapper_1.DefinitionSelectorsReferenceMapper();
    }
    map(schema) {
        return this.parse(schema.childProps(), []);
    }
    parse(props, selectors) {
        props.forEach((prop) => {
            if (prop.def.validations && prop.isAnswerable()) {
                selectors.push(prop.UID, ...this.defSelectorsReferenceMapper.map(prop.def.validations));
            }
            if (prop.def.qualifications && !prop.isSection() && !prop.isGroup()) {
                selectors.push(prop.UID, ...this.defSelectorsReferenceMapper.map(prop.def.qualifications));
            }
            if (prop.hasChildProps()) {
                selectors = this.parse(prop.childProps(), selectors);
            }
        });
        return [...new Set(selectors)];
    }
}
exports.SchemaEventsSelectorsReferenceMapper = SchemaEventsSelectorsReferenceMapper;
