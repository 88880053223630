"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PropertySelectorsMapper = void 0;
const Property_1 = require("../Property/Property");
class PropertySelectorsMapper {
    mapSelectors(property, typesRestriction = null) {
        return this._mapSelectors([], property.childProps(), typesRestriction);
    }
    mapAnswerablesSelectors(property) {
        return this.mapSelectors(property, Property_1.Property.ANSWERABLE_TYPES);
    }
    _mapSelectors(data, props, typesRestriction = null) {
        props.forEach((prop) => {
            if (!typesRestriction || typesRestriction.includes(prop.type)) {
                data.push(prop.UID);
            }
            if (prop.hasChildProps()) {
                data.concat(this._mapSelectors(data, prop.childProps(), typesRestriction));
            }
        });
        return data;
    }
}
exports.PropertySelectorsMapper = PropertySelectorsMapper;
