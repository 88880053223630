"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ArrayReducer = void 0;
const tslib_1 = require("tslib");
const PropetySelectorsMapper_1 = require("../../../Schema/Mapper/PropetySelectorsMapper");
const Logger_1 = require("../../../Logger");
const AbstractArrayOperation_1 = require("./AbstractArrayOperation");
const Selector_1 = require("../Selector");
const PluginEvents_1 = require("../../Plugin/PluginEvents");
class ArrayReducer extends AbstractArrayOperation_1.AbstractArrayOperation {
    reduce(selector, form) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const arrayProp = form.schema.search(selector);
            if (!arrayProp) {
                Logger_1.Logger.log(`Cannot reduce array property ${selector}`);
                return false;
            }
            const deletedProp = arrayProp.arrayReduce();
            if (!deletedProp) {
                Logger_1.Logger.log(`Cannot reduce array property ${selector}`);
                return false;
            }
            const deletedSelectors = [deletedProp.UID];
            deletedSelectors.push(...new PropetySelectorsMapper_1.PropertySelectorsMapper().mapSelectors(deletedProp));
            this.handleDeletedSelectors(deletedSelectors, form);
            return true;
        });
    }
    splice(selector, index, form) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const arrayProp = form.schema.search(selector);
            if (!arrayProp) {
                Logger_1.Logger.log(`Cannot reduce array property ${selector}`);
                return false;
            }
            if (parseInt(index) === arrayProp.childProps().size - 1) {
                return yield this.reduce(selector, form);
            }
            const arraySliceResult = arrayProp.arraySplice(index);
            if (!arraySliceResult) {
                Logger_1.Logger.log(`Cannot splice id ${index} of array property ${selector}`);
                return false;
            }
            this.deleteSelectorReferences(arraySliceResult.deletedProperty.UID, form);
            form.dataSelector.tryDelete(arraySliceResult.deletedProperty.UID);
            for (const droppedSelector of arraySliceResult.droppedSelectors) {
                this.deleteSelectorReferences(droppedSelector, form);
                form.dataSelector.tryDelete(droppedSelector);
            }
            if (arrayProp.hasChildProp(`${index}`)) {
                form.selectors.addProperty(arrayProp.childProp(`${index}`));
                this.addSelectorReferences(`${arrayProp.UID}${Selector_1.Selector.separator}${index}`, form);
            }
            return true;
        });
    }
    deleteSelectorReferences(selector, form) {
        const formSelector = form.selectors.selectors[selector];
        delete form.selectors.selectors[selector];
        delete form.qualifications[selector];
        delete form.qualificationsErrors[selector];
        delete form.validations[selector];
        delete form.validationsErrors[selector];
        if (formSelector && formSelector.isGroup) {
            form.schema.nodes.groups.delete(selector);
        }
        if (formSelector.belongToSection) {
            form.nodes.sections.mapSectionChildrenAncestors(formSelector.sectionUID);
        }
        if (formSelector.belongToGroup) {
            form.nodes.groups.mapGroupChildrenAncestors(formSelector.groupUID);
        }
        form.events.selectorEventsManager.deleteSelectorEvents(selector);
        form.schema.clearCachedSelector(selector);
    }
    handleDeletedSelectors(deletedSelectors, form) {
        for (const deletedSelector of deletedSelectors) {
            form.dataSelector.tryDelete(deletedSelector);
            this.deleteSelectorReferences(deletedSelector, form);
            form.plugins.triggerEvent(PluginEvents_1.PluginEvents.SelectorRemoved, {
                form,
                selector: deletedSelector,
            });
        }
    }
}
exports.ArrayReducer = ArrayReducer;
