"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EventsResolver = void 0;
const tslib_1 = require("tslib");
const EventsResolverContext_1 = require("./EventsResolverContext");
class EventsResolver {
    constructor() {
        this.erc = new EventsResolverContext_1.EventsResolverContext();
    }
    resolve(resources, form) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            for (const res of resources) {
                if (typeof res === "string") {
                    const rawContent = res;
                    let evaluatedContent;
                    if (rawContent.startsWith("(() => ") || rawContent.startsWith(";(() => ")) {
                        evaluatedContent = this.evalContent(rawContent, res);
                        if (evaluatedContent["onLoad"] && typeof evaluatedContent["onLoad"] === "function") {
                            yield evaluatedContent["onLoad"](yield this.erc.getContext(form));
                        }
                    }
                    else {
                        throw new Error(`Something went wrong while loading resource ${res}`);
                    }
                }
                else if (typeof res === "object" && res["onLoad"]) {
                    yield res.onLoad(yield this.erc.getContext(form));
                }
                else {
                    throw new Error(`Unable to resolve event resource ${res}`);
                }
            }
            yield form.events.refresh([]);
        });
    }
    evalContent(rawContent, res) {
        const evalFn = eval;
        let evaluatedContent;
        try {
            evaluatedContent = evalFn(rawContent);
        }
        catch (e) {
            throw new Error(`Something went wrong while evaluating resource ${res}`);
        }
        return evaluatedContent;
    }
}
exports.EventsResolver = EventsResolver;
