import React, { ChangeEvent } from "react"
import {
  Radio as GerudoRadio,
  RadioGroup as GerudoRadioGroup,
  RadioButton as GerudoRadioButton,
} from "@akinoxsolutions/gerudo-ui/dist/Radio"
import useTranslation, { TranslationFn } from "../../hooks/useTranslation"
import { getStyle, getStyles, getSubType } from "../../utils/uiUtils"
import { AnswerableComponentPropsInterface, EnumerationValueInterface, SchemaUIInterface } from "../interfaces"
import { Html } from "../index"
import styles from "./RadioGroup.module.css"

export interface RadioGroupPropsInterface extends AnswerableComponentPropsInterface<string> {}

const RadioGroup = ({
  autofocus,
  isDisabled,
  isReadOnly,
  onChange,
  property,
  ...otherProps
}: RadioGroupPropsInterface) => {
  const { translate } = useTranslation()
  const { enum: enumeration, ui = {} } = property.def
  const { radio: radioUi = {}, size, type: uiType } = ui

  const autofocusKeyValue = otherProps.value ?? enumeration[0].value
  return (
    <GerudoRadioGroup
      className={styles.RadioGroup}
      disabled={isDisabled || isReadOnly}
      onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
      size={size}
      style={getStyle(ui)}
      {...otherProps}
    >
      {getRadioFromEnumeration({
        autofocusKey: autofocus ? autofocusKeyValue : null,
        Component: getSubType(uiType) === "buttons" ? GerudoRadioButton : GerudoRadio,
        enumeration,
        translate,
        radioUi,
      })}
    </GerudoRadioGroup>
  )
}

const getRadioFromEnumeration = ({
  autofocusKey,
  Component,
  enumeration,
  translate,
  radioUi,
}: {
  autofocusKey?: string | null
  Component: typeof GerudoRadioButton | typeof GerudoRadio
  enumeration: EnumerationValueInterface[]
  translate: TranslationFn
  radioUi: SchemaUIInterface
}) => {
  return enumeration.map(({ disabled, label, readOnly, ui, value, tooltip }) => {
    const uiDefs: Partial<SchemaUIInterface>[] = [{ flex: { wrap: "nowrap" } }]
    if (radioUi) uiDefs.push(radioUi)
    if (ui) uiDefs.push(ui)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const className = `Gerudo${(Component as any).name ?? "Radio"}`
    const valueToDisplay = translate(label ?? value)

    return (
      <div key={value} style={getStyles(uiDefs)}>
        <Component
          autoFocus={autofocusKey === value}
          className={`${styles.Radio} ${styles[className]}`}
          disabled={disabled || readOnly}
          value={value}
        >
          {(valueToDisplay || tooltip) && <Html content={valueToDisplay} tooltip={tooltip} />}
        </Component>
      </div>
    )
  })
}

export default RadioGroup
