import { FileWithPath } from "@akinoxsolutions/gerudo-ui/dist/Dropzone"
import { AttachmentSerializedDataInterface, AttachmentUploadedResponseInterface, AttachmentInterface } from "./types"
import { AttachmentBaseInterface, AttachmentExtraDataInterface } from "../shared/types"

// Gerudo Dropzone uses native File objects which cannot be serialized.
// Dynamic Form needs to save the uploaded file in serialized format in the form's data
// Therefore we need to handle both data types and be able to convert between them
export class Attachment extends File implements AttachmentBaseInterface, AttachmentInterface {
  public path?: string
  public response: AttachmentUploadedResponseInterface[]
  public extraData?: AttachmentExtraDataInterface

  // In TestCafé, for some weird reason testcafe-hammerhead overrides the prototype of the Attachment class
  // by the native File prototype. We need to define these class methods as object properties to prevent them
  // being erased from the prototype
  public toJSON: () => AttachmentSerializedDataInterface

  constructor(
    file: FileWithPath,
    response: AttachmentUploadedResponseInterface,
    extraData?: AttachmentExtraDataInterface,
  ) {
    super([file], file.name, { type: file.type, lastModified: file.lastModified })
    this.path = file.path
    this.response = [response]
    this.extraData = extraData
    this.toJSON = toJSON.bind(null, this)
  }

  static fromJSON(file: AttachmentSerializedDataInterface, blobParts: BlobPart[] = []): Attachment {
    const f = new File(blobParts, file.name, { ...file })
    const a = new Attachment(f, file.response[0], file.extraData)
    // We have to explicitly assign the "size" attribute here because blobParts may be empty which
    // is the case when we create a File object from the json without downloading the file content
    // from the server. If we were not to assign the size here it would show as 0
    return Object.defineProperty(a, "size", { value: file.size })
  }
}

const toJSON = (a: Attachment): AttachmentSerializedDataInterface => {
  return {
    lastModified: a.lastModified,
    name: a.name,
    size: a.size,
    type: a.type,
    response: a.response,
    extraData: a.extraData,
  }
}
