import React, { useContext, useEffect, Dispatch, SetStateAction, useRef } from "react"
import useTranslation from "../../../hooks/useTranslation"

import {
  DropzoneErrorCode,
  DropzoneValidations,
  FileWithPath,
  useDropzone,
  UseDropzonePreviewActionsProps,
  UseDropzoneProps,
} from "@akinoxsolutions/gerudo-ui/dist/Dropzone"
import { AnswerableComponentPropsInterface } from "../../interfaces"
import { AttachmentCreatedResponseInterface, AttachmentSerializedDataInterface, SignalRAttachmentEvents } from "./types"
import { MessagingContext } from "../../../context/messaging/messagingState"
import { getPropertyUiOptions } from "../../../utils/propertyUtils"
import { UploadOptionsInterface } from "../UploadOptionsInterface"
import { UseFormActionInterface } from "../../../context/form/useFormAction"
import { FormContext } from "../../../context/form/formState"
import { useAttachmentUpdatedSignalR } from "./hooks"
import { downloadFile, getFileActions, getUseDropzoneCommonProps, initFilesState, isImageFile } from "../shared/helpers"
import { Attachment } from "./Attachment"
import { isAttachment, onSignalRFileStatusUpdated, uploadFileByChunk } from "./helpers"
import AttachmentList from "./AttachmentList"

export interface UploadPropsInterface extends AnswerableComponentPropsInterface<AttachmentSerializedDataInterface[]> {}

const Upload = (props: UploadPropsInterface) => {
  const { onChange, property, value, isDisabled, isReadOnly } = props

  const {
    def: { validations: { upload: { required = false, minItems = required ? 1 : 0, maxItems = 10 } = {} } = {} },
  } = property

  const uiOptions = getPropertyUiOptions<UploadOptionsInterface>(property)

  const {
    action: apiUrl = "",
    method = "POST",
    acceptedFiles = undefined,
    maxSizeBytes = undefined,
    minSizeBytes = undefined,
    showUploadList = true,
    uploadedBy = undefined,
    headers = {},
    data = {},
  } = uiOptions

  const { form }: UseFormActionInterface = useContext(FormContext)

  const { vars: { signalrUrl = "", ReferenceType = "", ReferenceId = "" } = {} } = form ?? {}

  const { postPreviewFileMessage } = useContext(MessagingContext)

  const { translate } = useTranslation()

  useEffect(() => {
    if (!Array.isArray(value) || value.length === 0) return
    initFilesState<AttachmentSerializedDataInterface, Attachment>({
      headers,
      jsonFiles: value,
      getDownloadUrl: (f) => f.response.downloadUrl,
      deserialize: Attachment.fromJSON,
      setFiles: setNewFilesState,
    })
  }, [])

  const validations: DropzoneValidations = {
    accept: acceptedFiles,
    maxSizeBytes,
    minSizeBytes,
    minFiles: minItems,
    maxFiles: maxItems,
  }

  const fileActions: UseDropzonePreviewActionsProps[] = getFileActions({
    showUploadList,
    translate,
    onPreview: postPreviewFileMessage,
    onDownload: onDownloadFile,
    showPreview: (f) => isAttachment(f) && isImageFile(f) && f.status === SignalRAttachmentEvents.Available,
    showDownload: (f) => isAttachment(f) && f.status === SignalRAttachmentEvents.Available,
  })

  const dropzoneProps: UseDropzoneProps = {
    ...getUseDropzoneCommonProps({
      uploadProps: props,
      options: uiOptions,
      validations,
      translate,
    }),
    onRemoveFile: (file) => onDeleteFile(file),
    onAddFiles: (files) => onAddFiles(files),
    hidePreviewInfos: true,
    "data-testid": "attachments-dropzone",
  }

  const { Dropzone, files: dropzoneFiles, setFiles: setDropzoneFiles, setErrors } = useDropzone(dropzoneProps)

  const files: Attachment[] = dropzoneFiles.filter(isAttachment)
  const setFiles = setDropzoneFiles as Dispatch<SetStateAction<Attachment[]>>

  // Since Gerudo Dropzone's inner react-dropzone dependency expects File objects and
  // Dynamic Form's data expects something serializable (which a File is not) we have to
  // handle both types of data
  const setNewFilesState: Dispatch<SetStateAction<FileWithPath[]>> = (setStateAction) => {
    let newFilesCount = 0
    // setStateAction is of type: FileWithPath[]
    if (typeof setStateAction === "object") {
      const newFiles = setStateAction.filter(isAttachment)
      newFilesCount = newFiles.length
      setFiles(newFiles)
      onChange(newFiles.map((f) => f.toJSON()))
    }

    // setStateAction is of type: (prevState: FileWithPath[]) => FileWithPath[]
    if (typeof setStateAction === "function") {
      setFiles((prevState) => {
        const newFiles = setStateAction(prevState).filter(isAttachment)
        newFilesCount = newFiles.length
        onChange(newFiles.map((f) => f.toJSON()))
        return newFiles
      })
    }

    if (newFilesCount < validations.minFiles) {
      setErrors([{ code: DropzoneErrorCode.TooFewFiles, isStateError: true }])
    }
  }

  // Weird but otherwise the callback in useAttachmentUpdatedSignalR hook gets stale files state
  // because it is created once at mount and the files it gets come from its closure
  const filesRef = useRef<Attachment[]>([])
  filesRef.current = files

  useAttachmentUpdatedSignalR({
    updateHubUrl: signalrUrl,
    callback: (e) => onSignalRFileStatusUpdated(e, filesRef.current, setNewFilesState),
  })

  function renderPreviewInfo(file: FileWithPath) {
    if (isAttachment(file) && file.extraData?.uploadedBy) {
      return translate("dropzone.uploadedBy", {
        name: file.extraData?.uploadedBy,
        date: new Date(file.extraData?.uploadedAt).toLocaleString(),
      })
    }
    return null
  }

  async function onDownloadFile(file: FileWithPath) {
    if (!isAttachment(file)) return
    const fileName = file.response.displayName
    const downloadUrl = file.response.downloadUrl
    downloadFile(fileName, downloadUrl, headers)
  }

  async function onAddFiles(addedFiles: FileWithPath[]) {
    const newFiles = [...files]
    const requestBody = {
      ReferenceId,
      ReferenceType,
      SectionId: "Request-DynamicForm",
      FileNames: addedFiles.map((f) => f.name),
      ...data,
    }
    try {
      const url = `${apiUrl}createAttachments/`
      const response = await fetch(url, {
        method,
        body: JSON.stringify(requestBody),
        headers: new Headers({ "Content-Type": "application/json", ...headers }),
      })
      const fileResponses = (await response.json()) as AttachmentCreatedResponseInterface[]
      // Map each file with its server response
      fileResponses.forEach((r) => {
        const f = addedFiles.find((x) => x.name === r.displayName)
        if (!f) return
        const a = new Attachment(f, r, { uploadedBy, uploadedAt: new Date().toISOString() })
        uploadFileByChunk(a, setNewFilesState, apiUrl)
        newFiles.push(a)
      })
    } catch (e) {
      console.error("File upload failed", e) // eslint-disable-line no-console
    } finally {
      setNewFilesState(newFiles)
    }
  }

  async function onDeleteFile(fileToDelete?: FileWithPath) {
    if (!fileToDelete || !isAttachment(fileToDelete)) return

    // if the file is being uploaded, we need to abort the upload and remove it from the list
    if (fileToDelete.response.id && fileToDelete.status === undefined) {
      const killUploadChunkEvent = new CustomEvent(`StopChunk-${fileToDelete.response.id}`, {
        detail: {
          fileToDelete,
        },
      })
      document.dispatchEvent(killUploadChunkEvent)
    }
    await deleteFile(fileToDelete)
  }

  async function deleteFile(fileToDelete: Attachment) {
    const newFiles = [...files]
    const index = newFiles.indexOf(fileToDelete as Attachment)
    if (index >= 0) {
      newFiles.splice(index, 1)
    }
    setNewFilesState(newFiles)
    try {
      if (!isAttachment(fileToDelete)) return
      const url = `${apiUrl}${fileToDelete.response.id}`
      await fetch(url, {
        method: "DELETE",
        headers: new Headers({ ...headers }),
      })
    } catch (e) {
      console.error("File deletion failed", e) // eslint-disable-line no-console
    }
  }

  return (
    <>
      <Dropzone />
      {showUploadList !== false && (
        <AttachmentList
          files={files}
          isDeletable={!(isDisabled || isReadOnly)}
          onRemovePreview={onDeleteFile}
          renderPreviewInfo={renderPreviewInfo}
          actions={fileActions}
        />
      )}
    </>
  )
}

export default Upload
