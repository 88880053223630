import React, { useContext } from "react"
import useTranslation from "../../hooks/useTranslation"
import { FormContext } from "../../context/form/formState"
import { ConfigContext } from "../../context/config/configState"
import { UseFormActionInterface } from "../../context/form/useFormAction"
import { Button, ButtonPropsInterface, Html } from "../index"

export interface SubmitButtonPropsInterface
  extends Omit<ButtonPropsInterface, "disabled" | "id" | "label" | "onClick"> {}

const SubmitButton = (props: SubmitButtonPropsInterface): JSX.Element => {
  const { translate } = useTranslation()
  const { isPartialDisplay, readOnlyMode } = useContext(ConfigContext)
  const { form, formOptions, submit }: UseFormActionInterface = useContext(FormContext)
  const formPass = form?.pass
  const isPartial = isPartialDisplay || (formOptions.isPartialDisplay && isPartialDisplay !== false)
  const isReadOnly = readOnlyMode || (formOptions.readOnlyMode && readOnlyMode !== false)

  if (isPartial || isReadOnly) {
    return <></>
  }

  return (
    <Button
      {...props}
      disabled={!formPass}
      id="submit"
      label={<Html content={translate(formPass ? "submit.enabled" : "submit.disabled")} />}
      onClick={formPass ? submit : undefined}
    />
  )
}
export default SubmitButton
