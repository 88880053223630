import { CSSProperties, Dispatch, useContext, useEffect, useReducer } from "react"
import { FormInterface } from "@akinoxsolutions/formol"
import { addTranslations, changeLanguage, removeCustomNamespace } from "../../i18n"
import { ConfigContext } from "../config/configState"
import { DisplayModeType, ThemeNameType } from "../config/configTypes"
import { DevPanelContext } from "../devPanel/devPanelState"
import { FormContext } from "../form/formState"
import { ContextInterface, UseMessagingActionInterface } from "./messagingInterfaces"
import { MessagingReducerActionType } from "./messagingTypes"
import { initialState } from "./messagingState"
import messagingReducer from "./messagingReducer"
import { isHostReactNative, isIOS, postMessageToReactNativeWebView } from "../../utils/reactNativeUtils"

interface UseMessagingActionPropsInterface extends ContextInterface {}

export default (
  context: UseMessagingActionPropsInterface,
): [UseMessagingActionInterface, Dispatch<MessagingReducerActionType>] => {
  const [state, dispatch] = useReducer(messagingReducer, initialState, () => initialState)

  const {
    devMode,
    increaseNumberOfErrors,
    isDevEnv,
    resetConfig,
    setCustomStyle,
    setDevMode,
    setDisplayMode,
    setIsPartialDisplay,
    setLocale,
    setReadOnlyMode,
    setTheme,
    toggleDisplayDevPanel,
  } = useContext(ConfigContext)

  if (devMode && window) {
    window["toggleDevPanel"] = toggleDisplayDevPanel
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { setCustomEvents } = useContext(DevPanelContext) as any

  const {
    error,
    form,
    formStatus,
    initForm,
    isSubmitting,
    isUpdating,
    lastUpdatedSelector,
    resetForm,
    setFormBuildOptions,
    setFormStyle,
    submitted,
  } = useContext(FormContext)

  const addMessagesListener = () => {
    const unloadForm = (): void => {
      initForm()
      removeCustomNamespace()
      delete (window as any).form // eslint-disable-line @typescript-eslint/no-explicit-any
    }

    const handleLanguageChange = (newLanguage: string): void => {
      if (changeLanguage(newLanguage)) {
        setLocale(newLanguage)
      } else {
        increaseNumberOfErrors()
      }
      document.getElementsByTagName("html")[0].setAttribute("lang", newLanguage)
    }

    const handleNewPayload = (payloadData): void => {
      postPayloadReceivedMessage()
      unloadForm()

      try {
        const {
          customStyle,
          devMode,
          displayMode,
          isPartialDisplay,
          language,
          payload: {
            events,
            settings,
            lastUpdatedUid,
            schema,
            schemas,
            state,
            style: formStyle,
            translations,
            vars: payloadVars = {},
          } = {} as any, // eslint-disable-line @typescript-eslint/no-explicit-any
          readOnlyMode,
          themeName,
          vars: extraVars = {},
        } = payloadData

        if (!schema) {
          postPayloadLoadingFailedMessage({ errorCode: 204, errorMsg: "No content" })
          return
        }

        const vars = { ...payloadVars, ...extraVars }

        const formBuildOptions = {
          events,
          lastUpdatedSelector: lastUpdatedUid,
          readOnlyMode,
          schema: schema ? [schema] : schemas,
          settings: { ...settings, devMode },
          state,
          vars,
          language,
        }

        if (!addTranslations(translations, { vars })) {
          increaseNumberOfErrors()
        }
        if (language) {
          handleLanguageChange(language)
        }

        setCustomEvents(events)
        setCustomStyle(customStyle)
        setDevMode(devMode)
        setDisplayMode(displayMode)
        setIsPartialDisplay(isPartialDisplay)
        setReadOnlyMode(readOnlyMode)
        setTheme(themeName)
        setFormStyle(formStyle)
        setFormBuildOptions(formBuildOptions)
      } catch (err) {
        postPayloadLoadingFailedMessage({ errorCode: 500, errorMsg: (err as Error).message })
      }
    }

    const handleMessage = (event: { data: MessagingReducerActionType }): void => {
      const { type, ...payloadData } = event.data
      innerHandleMessage(type, payloadData)
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleMessageReactNative = (event: any) => {
      const { type, ...payloadData } = JSON.parse(event.data)
      innerHandleMessage(type, payloadData)
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const innerHandleMessage = (type: string, payloadData: any) => {
      switch (type) {
        case "DYNAMIC_FORM_CHANGE_CUSTOM_STYLE":
          setCustomStyle((payloadData as { customStyle: CSSProperties }).customStyle)
          break
        case "DYNAMIC_FORM_CHANGE_DISPLAY_MODE":
          setDisplayMode((payloadData as { displayMode: DisplayModeType }).displayMode)
          break
        case "DYNAMIC_FORM_CHANGE_LANGUAGE":
          handleLanguageChange((payloadData as { language: string }).language)
          break
        case "DYNAMIC_FORM_CHANGE_THEME":
          setTheme((payloadData as { themeName: ThemeNameType }).themeName)
          break
        case "DYNAMIC_FORM_INFOS":
          if (form) {
            postFormInfosMessages(form)
          }
          break
        case "DYNAMIC_FORM_PAYLOAD":
          handleNewPayload(payloadData)
          break
        case "DYNAMIC_FORM_RESET":
          resetForm()
          break
        case "DYNAMIC_FORM_UNLOAD":
          unloadForm()
          break
        case "DYNAMIC_FORM_REINIT":
          unloadForm()
          resetConfig()
          break
        default:
          break
      }
    }

    if (isHostReactNative()) {
      if (isIOS()) {
        window.addEventListener("message", handleMessageReactNative)
      } else {
        document.addEventListener("message", handleMessageReactNative)
      }
    } else {
      window.addEventListener("message", handleMessage)
    }

    postReadyMessage()
    return handleMessage
  }

  const devMessagesListener = (event: MessageEvent): void => {
    if (event.data.type?.startsWith("DYNAMIC_FORM")) {
      console.log(event.data) // eslint-disable-line no-console
    }
  }

  const devModeListener = (event: KeyboardEvent): void => {
    const isMac = navigator.platform.toUpperCase().indexOf("MAC") >= 0
    const isLoaded = formStatus === "SUCCESSFULLY_LOADED"
    const shortcut = event.ctrlKey && (isMac ? true : event.altKey) && event.code === "KeyE"
    if (shortcut && (!isLoaded || (isLoaded && devMode))) {
      toggleDisplayDevPanel()
    }
  }

  useEffect((): (() => void) => {
    dispatch({ type: "DYNAMIC_FORM_CONTEXT", context })
    post({ type: "DYNAMIC_FORM_CONTEXT", context })

    // @ts-ignore
    const handleMessage = addMessagesListener()
    return () => {
      window.removeEventListener("message", handleMessage)
    }
  }, [])

  useEffect((): (() => void) => {
    const removeListeners = () => {
      window.removeEventListener("message", devMessagesListener)
      window.removeEventListener("keydown", devModeListener)
    }

    removeListeners()
    if (isDevEnv) {
      window.addEventListener("message", devMessagesListener)
      window.addEventListener("keydown", devModeListener)
    }
    return () => {
      removeListeners()
    }
  }, [isDevEnv])

  useEffect((): void => {
    switch (formStatus) {
      case "WAITING_FOR_PAYLOAD":
        postWaitingForPayload()
        break
      case "EMPTY":
        postPayloadLoadingFailedMessage({ errorCode: 400, errorMsg: "Form schema empty" })
        break
      case "LOADING_ERROR":
        if (error) {
          postPayloadLoadingFailedMessage({ errorCode: error.code, errorMsg: error.msg })
        }
        break
      case "SUCCESSFULLY_LOADED":
        postPayloadLoadedSuccessfullyMessage(form as FormInterface)
        break
      default:
        break
    }
  }, [formStatus])

  useEffect((): void => {
    if (!isUpdating) {
      const selector = lastUpdatedSelector as string
      if (form && selector) {
        postFormUpdateMessages(form, selector)
      }
    }
  }, [lastUpdatedSelector, isUpdating])

  useEffect((): void => {
    if (form) {
      postStatusChanged(form)
    }
  }, [form?.pass])

  useEffect((): void => {
    if (form && isSubmitting) {
      postSubmissionMessage(form)
      submitted()
    }
  }, [isSubmitting])

  const postCustomEvent = (eventType: string, opts: { context?: unknown; miscHandlerName?: string }): void => {
    post({ type: "DYNAMIC_FORM_CUSTOM_EVENT", eventType, ...opts })
  }

  const postFormInfosMessages = (form: FormInterface): void => {
    postFormDataMessage(form)
    postFormMetaMessage(form)
    postFormStatsMessage(form)
  }

  const postFormDataMessage = ({ data }: FormInterface): void => {
    post({ type: "DYNAMIC_FORM_DATA", payload: { data } })
  }

  const postFormMetaMessage = ({ meta }: FormInterface): void => {
    post({ type: "DYNAMIC_FORM_META", payload: { meta } })
  }

  const postFormStatsMessage = ({ stats }: FormInterface): void => {
    post({ type: "DYNAMIC_FORM_STATS", payload: { stats } })
  }

  const postFormUpdateMessages = (form: FormInterface, uid: string): void => {
    postUpdateDataMessage(form, uid)
    postUpdateMetaMessage(form, uid)
    postUpdateStatsMessage(form, uid)
  }

  const postNavigateToMessage = (uid: string): void => {
    post({ type: "DYNAMIC_FORM_NAVIGATE_TO", uid, viewedSections: form?.meta?.viewedSections ?? [] })
  }

  const postPayloadLoadedSuccessfullyMessage = ({ data, meta }: FormInterface): void => {
    post({ type: "DYNAMIC_FORM_PAYLOAD_LOADED_SUCCESSFULLY", data, meta })
  }

  const postPayloadLoadingFailedMessage = ({ errorCode, errorMsg }: { errorCode: number; errorMsg: string }): void => {
    post({ type: "DYNAMIC_FORM_PAYLOAD_LOADING_FAILED", errorCode, errorMsg })
  }

  const postPayloadReceivedMessage = (): void => {
    post({ type: "DYNAMIC_FORM_PAYLOAD_RECEIVED" })
  }

  const postReadyMessage = (): void => {
    post({ type: "DYNAMIC_FORM_READY" })
  }

  const postStatusChanged = ({ pass }: FormInterface): void => {
    post({ type: "DYNAMIC_FORM_STATUS_CHANGED", formPass: pass })
  }

  const postSubmissionMessage = ({ data, meta }: FormInterface): void => {
    post({ type: "DYNAMIC_FORM_SUBMISSION", data, meta })
  }

  const postUpdateDataMessage = ({ data, meta }: FormInterface, uid: string): void => {
    post({ type: "DYNAMIC_FORM_UPDATE_DATA", data, meta, uid })
  }

  const postUpdateMetaMessage = ({ meta }: FormInterface, uid: string): void => {
    post({ type: "DYNAMIC_FORM_UPDATE_META", meta, uid })
  }

  const postUpdateStatsMessage = ({ stats }: FormInterface, uid: string): void => {
    post({ type: "DYNAMIC_FORM_UPDATE_STATS", stats, uid })
  }

  const postPreviewFileMessage = (data: unknown): void => {
    post({ type: "DYNAMIC_FORM_PREVIEW_FILE", data })
  }

  const postWaitingForPayload = (): void => {
    post({ type: "DYNAMIC_FORM_WAITING_FOR_PAYLOAD" })
  }

  const post = ({ type, ...payload }: MessagingReducerActionType): void => {
    window.setTimeout(() => {
      if (isHostReactNative()) {
        postMessageToReactNativeWebView({ type, context, payload })
      } else if (window && window.parent) {
        window.parent.postMessage(JSON.parse(JSON.stringify({ type, context, payload })), "*")
      }
    })
  }

  return [
    {
      ...state,
      postCustomEvent,
      postNavigateToMessage,
      postPreviewFileMessage,
    },
    dispatch,
  ]
}
