"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SelectorResolver = void 0;
const Selector_1 = require("./Selector");
class SelectorResolver {
    resolve(selectorsToResolve, selectorsList) {
        if (typeof selectorsToResolve === "string") {
            selectorsToResolve = [selectorsToResolve];
        }
        let realSelectors = [];
        for (const selector of selectorsToResolve) {
            if (!selector.startsWith(Selector_1.Selector.separator) && selector.endsWith(Selector_1.Selector.wildcard)) {
                let removeCount = 1;
                if (selector.endsWith(`${Selector_1.Selector.separator}${Selector_1.Selector.wildcard}`)) {
                    removeCount = 2;
                }
                const search = selector.substr(0, selector.length - removeCount);
                const matchingSelectors = selectorsList.filter((e) => {
                    return e.startsWith(search) && (removeCount == 1 || (removeCount == 2 && e !== search));
                });
                realSelectors = realSelectors.concat(matchingSelectors);
            }
            else if (selectorsList.includes(selector) || selector.startsWith(Selector_1.Selector.separator)) {
                realSelectors.push(selector);
            }
        }
        return [...new Set(realSelectors)];
    }
}
exports.SelectorResolver = SelectorResolver;
