"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SchemaWildcardSelectorsResolver = void 0;
class SchemaWildcardSelectorsResolver {
    constructor(form) {
        this.form = form;
        this.resolve(form.schema.childProps());
    }
    resolve(props) {
        for (const [, prop] of props.entries()) {
            this.updateDefinitionSelectors(prop.def);
            if (prop.hasChildProps()) {
                this.resolve(prop.childProps());
            }
        }
    }
    updateDefinitionSelectors(def) {
        if (!def) {
            return;
        }
        for (const [k, v] of Object.entries(def)) {
            if (k === "selectors") {
                if (Array.isArray(v)) {
                    def[k] = this.form.selectors.resolveWildcard(v);
                }
                else if (typeof v === "string") {
                    def[k] = this.form.selectors.resolveWildcard(v);
                }
            }
            else if (typeof v === "object") {
                this.updateDefinitionSelectors(v);
            }
        }
    }
}
exports.SchemaWildcardSelectorsResolver = SchemaWildcardSelectorsResolver;
