"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SchemaSectionsMapper = void 0;
const PropertySection_1 = require("../Property/PropertySection");
class SchemaSectionsMapper {
    map(schema) {
        return this._map(new Map(), schema.childProps());
    }
    _map(sections, props) {
        props.forEach((prop) => {
            if (prop.get("section", false)) {
                sections.set(prop.UID, new PropertySection_1.PropertySection(prop));
            }
            if (prop.hasChildProps()) {
                sections = new Map([
                    ...sections,
                    ...this._map(new Map(), prop.childProps()),
                ]);
            }
        });
        return sections;
    }
}
exports.SchemaSectionsMapper = SchemaSectionsMapper;
