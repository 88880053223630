"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VarsProcessor = void 0;
const DataSelector_1 = require("../../Form/Selector/DataSelector");
class VarsProcessor {
    constructor(vars) {
        this.vars = vars;
        this.regexInline = /%%([A-Za-z_0-9.]+)%%/gi;
        this.regexRef = /^#\/vars\/([A-Za-z_0-9.]+)$/i;
        this.dataSelector = new DataSelector_1.DataSelector(vars);
    }
    process(def) {
        return this.hasVarsToProcess() ? this.parse(def) : def;
    }
    hasVarsToProcess() {
        return typeof this.vars === "object" && Object.keys(this.vars).length > 0;
    }
    parse(def) {
        for (const [key, val] of Object.entries(def)) {
            const valType = typeof val;
            if (valType === "string") {
                if (this.isRef(val)) {
                    def[key] = this.resolveReference(val);
                    if (key === "enum") {
                        def.enumSourceVars = val;
                    }
                }
                else {
                    def[key] = this.resolveInline(val);
                }
            }
            else if (val && valType === "object") {
                def[key] = this.parse(val);
            }
        }
        return def;
    }
    resolveInline(expression) {
        const matches = expression.match(this.regexInline) || [];
        for (const match of matches) {
            const varKey = match.replace(this.regexInline, "$1");
            if (this.vars[varKey]) {
                expression = expression.replace(match, this.vars[varKey]);
            }
            else if (varKey.includes(".")) {
                const [success, data] = this.dataSelector.tryGet(varKey);
                if (success) {
                    expression = expression.replace(match, data);
                }
            }
        }
        return expression;
    }
    resolveReference(expression) {
        const result = this.regexRef.exec(expression);
        if (!result) {
            return null;
        }
        const varKey = result[1];
        if (this.vars[varKey]) {
            return this.vars[varKey];
        }
        else {
            const [success, data] = this.dataSelector.tryGet(varKey);
            if (success) {
                return data;
            }
        }
        return null;
    }
    isRef(expression) {
        return this.regexRef.test(expression);
    }
}
exports.VarsProcessor = VarsProcessor;
