"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BatchSelectorEvents = void 0;
const tslib_1 = require("tslib");
const SelectorEvents_1 = require("./SelectorEvents");
const Events_1 = require("./Events");
class BatchSelectorEvents {
    executeBatch(selectorEvents, eventType, eventCtx) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const events = selectorEvents[SelectorEvents_1.SelectorEvents.typeNameToVarName(eventType)];
            for (const event of events) {
                const answer = yield SelectorEvents_1.SelectorEvents.executeEvent(selectorEvents.selector, event, eventCtx);
                if (eventType !== Events_1.Events.DataUpdated) {
                    const answerFlag = Array.isArray(answer) ? answer[0] : answer;
                    if (answerFlag === false) {
                        break;
                    }
                }
            }
        });
    }
}
exports.BatchSelectorEvents = BatchSelectorEvents;
