"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ValidatorFactory = void 0;
const ValidatorsList_1 = require("./ValidatorsList");
class ValidatorFactory {
    create(validator, options, type) {
        if (!ValidatorsList_1.validators[validator]) {
            throw new Error(`Cannot find validator ${validator}`);
        }
        return ValidatorsList_1.validators[validator](options, type);
    }
}
exports.ValidatorFactory = ValidatorFactory;
