"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormBuilder = void 0;
const tslib_1 = require("tslib");
const SchemaBuilder_1 = require("../Schema/SchemaBuilder");
const Form_1 = require("./Form");
const EventsResolver_1 = require("./Builder/EventsResolver");
const FormStateHydrator_1 = require("./Builder/FormStateHydrator");
const Logger_1 = require("../Logger");
const PluginEvents_1 = require("./Plugin/PluginEvents");
const defaultSettings_1 = require("./Builder/defaultSettings");
class FormBuilder {
    static build(options) {
        var _a;
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const schema = yield new SchemaBuilder_1.SchemaBuilder().build(options);
            const settings = Object.assign(Object.assign({}, defaultSettings_1.defaultSettings), (options.settings || {}));
            const vars = options.vars || {};
            const form = new Form_1.Form(schema, settings, vars);
            Logger_1.Logger.outputToConsole = form.settings.devMode;
            if (options.language) {
                form.language = options.language;
            }
            if (options.state) {
                yield new FormStateHydrator_1.FormStateHydrator().hydrate(options.state, form);
            }
            if (options.events) {
                yield new EventsResolver_1.EventsResolver().resolve(options.events, form);
            }
            const markFormAsReady = yield form.init();
            if (options.state) {
                yield form.events.refresh([]);
                yield form.plugins.triggerEvent(PluginEvents_1.PluginEvents.DataUpdated, form.events.buildBasicCtx());
            }
            if ((_a = options.state) === null || _a === void 0 ? void 0 : _a.currentSelector) {
                form.navigation.setCurrentSelector(options.state.currentSelector);
            }
            yield markFormAsReady();
            return form;
        });
    }
    static buildFromSchema(source, otherOptions) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const def = { schema: [source] };
            const opts = otherOptions ? Object.assign({}, def, otherOptions) : def;
            return yield FormBuilder.build(opts);
        });
    }
}
exports.FormBuilder = FormBuilder;
