"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.regExpPresets = void 0;
const regExpPresets = {
    email: "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$",
    ca_qc_hin: "^[A-Za-z]{4} \\d{4} \\d{2}[\\dA-Za-z]\\d$",
    ca_sin: "^[\\d]{3} [\\d]{3} [\\d]{3}$",
    ca_phoneNumber: "^\\([\\d]{3}\\) [\\d]{3}-[\\d]{4}$",
    ca_postalCode: "^[a-zA-Z][\\d][a-zA-Z] [\\d][a-zA-Z][\\d]$",
};
exports.regExpPresets = regExpPresets;
