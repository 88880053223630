import React from "react"
import { EyeOpenIcon as GerudoEyeOpenIcon } from "@akinoxsolutions/gerudo-ui/dist/Icons/EyeOpen"
import useTranslation from "../../hooks/useTranslation"

export const EyeOpenIcon = ({ onClick, titleKey = "password.show" }) => {
  const { translate } = useTranslation()

  return (
    <GerudoEyeOpenIcon
      color="#949494"
      key="EyeClosedIcon"
      onClick={onClick}
      size="medium"
      title={titleKey ? translate(titleKey) : undefined}
    />
  )
}
