import React from "react"
import { Tooltip as GerudoTooltip } from "@akinoxsolutions/gerudo-ui/dist/Tooltip"
import { InfoCircleIcon } from "@akinoxsolutions/gerudo-ui/dist/Icons/InfoCircle"
import { IconSizes } from "@akinoxsolutions/gerudo-ui/dist/Icons/PropTypes/IconSizes"

import useTranslation from "../../hooks/useTranslation"

import styles from "./Tooltip.module.css"
import { Html } from ".."

export interface TooltipPropsInterface {
  content: string
  size?: IconSizes
  title?: string
}

const Tooltip = ({ content, size = IconSizes.small, title, ...props }: TooltipPropsInterface) => {
  const { translate } = useTranslation()

  const translatedTitle = title ? translate(title) : undefined
  const translatedContent = content ? translate(content) : undefined

  return (
    <GerudoTooltip
      content={translatedContent && <Html content={translatedContent} />}
      title={translatedTitle && <Html content={translatedTitle} />}
      {...props}
    >
      <InfoCircleIcon className={styles.TooltipIcon} size={size} />
    </GerudoTooltip>
  )
}

export default Tooltip
