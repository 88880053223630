"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PropertyCategoryPlugin = void 0;
const PluginBase_1 = require("../../Form/Plugin/PluginBase");
class PropertyCategoryPlugin extends PluginBase_1.PluginBase {
    constructor({ defaultCategory = null, ids = {}, categoryKey = "category" }) {
        super();
        this.name = "propertyCategory";
        this.defaultCategory = defaultCategory;
        this.ids = ids;
        this.categoryKey = categoryKey;
    }
    formCreated({ form }) {
        const metaCats = {
            ids: this.ids,
            categories: {},
            selectors: {},
        };
        for (const selector of form.selectors.keys) {
            const prop = form.schema.search(selector);
            let propCats = [];
            if (prop.def[this.categoryKey]) {
                propCats = prop.def[this.categoryKey];
                if (!Array.isArray(propCats)) {
                    propCats = [propCats];
                }
            }
            else if (this.defaultCategory) {
                propCats.push(this.defaultCategory);
            }
            if (!Array.isArray(propCats)) {
                propCats = [propCats];
            }
            for (const propCat of propCats) {
                if (!metaCats.categories[propCat]) {
                    metaCats.categories[propCat] = [];
                }
                metaCats.categories[propCat].push(prop.UID);
            }
            metaCats.selectors[prop.UID] = propCats;
        }
        form.meta[this.name] = metaCats;
    }
}
exports.PropertyCategoryPlugin = PropertyCategoryPlugin;
