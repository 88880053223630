export const getInputMaskRegExpPattern = (validations) => {
  const { regExp, healthInsurance } = validations || {}

  if (regExp?.pattern) {
    return new RegExp(regExp?.pattern)
  }
  // add exception for nam validation, not to pass the regexp all the time
  if (healthInsurance?.type === "ca_qc_hin") {
    return new RegExp("^[A-Za-z]{4} \\d{4} \\d{2}[\\dA-Za-z]\\d$")
  }
}
