"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SchemaNodes = void 0;
const SchemaArraysMapper_1 = require("./Mapper/SchemaArraysMapper");
const SchemaSectionsMapper_1 = require("./Mapper/SchemaSectionsMapper");
const SchemaGroupsMapper_1 = require("./Mapper/SchemaGroupsMapper");
class SchemaNodes {
    constructor(schema) {
        this.schema = schema;
        this.sections = new Map();
        this.groups = new Map();
        this.arrays = new Map();
        this.groupsMapper = new SchemaGroupsMapper_1.SchemaGroupsMapper();
        this.sectionsMapper = new SchemaSectionsMapper_1.SchemaSectionsMapper();
        this.arraysMapper = new SchemaArraysMapper_1.SchemaArraysMapper();
        this.mapAll();
    }
    mapAll() {
        this.mapSections();
        this.mapGroups();
        this.mapArrays();
    }
    mapGroups() {
        this.groups = this.groupsMapper.map(this.schema);
    }
    mapSections() {
        this.sections = this.sectionsMapper.map(this.schema);
    }
    mapArrays() {
        this.arrays = this.arraysMapper.map(this.schema);
    }
}
exports.SchemaNodes = SchemaNodes;
