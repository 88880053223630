import { ConfigStateInterface } from "./configInterfaces"
import { ConfigReducerActionType } from "./configTypes"
import { configInitialState } from "./configState"

export default (state: ConfigStateInterface, action: ConfigReducerActionType): ConfigStateInterface => {
  switch (action.type) {
    case "INCREASE_NUMBER_OF_ERRORS": {
      return {
        ...state,
        numberOfErrors: state.numberOfErrors + 1,
      }
    }

    case "RESET_CONFIG": {
      return configInitialState
    }

    case "SET_CUSTOM_STYLE": {
      return {
        ...state,
        customStyle: action.customStyle,
      }
    }

    case "SET_DEV_MODE": {
      return {
        ...state,
        devMode: action.devMode,
      }
    }

    case "SET_DISPLAY_MODE": {
      return {
        ...state,
        displayMode: action.displayMode,
      }
    }

    case "SET_IS_PARTIAL_DISPLAY": {
      return {
        ...state,
        isPartialDisplay: action.isPartialDisplay,
      }
    }

    case "SET_LOCALE": {
      return {
        ...state,
        locale: action.locale,
        localeKey: action.localeKey,
      }
    }

    case "SET_READ_ONLY_MODE": {
      return {
        ...state,
        readOnlyMode: action.readOnlyMode,
      }
    }

    case "SET_THEME": {
      return {
        ...state,
        themeName: action.themeName,
      }
    }

    case "TOGGLE_DISPLAY_DEV_PANEL": {
      return {
        ...state,
        displayDevPanel: !state.displayDevPanel,
      }
    }

    default:
      return state
  }
}
