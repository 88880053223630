"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HealthInsuranceValidator = void 0;
const tslib_1 = require("tslib");
const AbstractValidator_1 = require("./AbstractValidator");
const RegExpPresets_1 = require("./RegExpPresets");
class HealthInsuranceValidator extends AbstractValidator_1.AbstractValidator {
    constructor(options, type) {
        super(type);
        this.options = options;
    }
    validate({ data }) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!data) {
                return this.formatResult([false, { code: "EMPTY_VALUE", context: this.options }], this.options);
            }
            switch (this.options.type) {
                case "ca_qc_hin":
                    return this.validateCaQcHin(data);
                default:
                    return this.formatResult([false, { code: "INVALID_HIN", context: this.options }], this.options);
            }
        });
    }
    validateCaQcHin(data) {
        const isCorrectFormat = new RegExp(RegExpPresets_1.regExpPresets.ca_qc_hin).exec(data);
        if (isCorrectFormat && isCorrectFormat[0]) {
            const dateAndSex = data.replace(/\s/g, "").substring(4, 10);
            const partialYear = Number(dateAndSex.substring(0, 2));
            const currentPartialYear = new Date().getFullYear() - 2000;
            const currentPartialYearPlusOne = currentPartialYear + 1;
            const year = partialYear < currentPartialYearPlusOne ? partialYear + 2000 : partialYear + 1900;
            const monthAndSex = Number(dateAndSex.substring(2, 4));
            const day = Number(dateAndSex.substring(4, 6));
            const month = monthAndSex > 50 ? monthAndSex - 51 : monthAndSex - 1;
            const date = new Date(year, month, day);
            if (date.getMonth() !== month || date.getDate() !== day || date.getFullYear() !== year) {
                return this.formatResult([false, { code: "INVALID_HIN", context: this.options }], this.options);
            }
            return [true, null];
        }
        return this.formatResult([false, { code: "INVALID_HIN", context: this.options }], this.options);
    }
}
exports.HealthInsuranceValidator = HealthInsuranceValidator;
