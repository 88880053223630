"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WordCountValidator = void 0;
const tslib_1 = require("tslib");
const AbstractValidator_1 = require("./AbstractValidator");
class WordCountValidator extends AbstractValidator_1.AbstractValidator {
    constructor(options, type) {
        super(type);
        this.options = options;
        this.wordCountRegex = /[\s.,’':]+/gim;
    }
    validate({ form, prop }) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const selectors = this.options.selectors ? this.options.selectors : [prop.UID];
            for (const selector of selectors) {
                const data = form.dataSelector.get(selector);
                if (typeof data !== "string" || !this.validateData(data)) {
                    return this.getError();
                }
            }
            return [true, null];
        });
    }
    validateData(data) {
        const parsedData = data.trim();
        const wordCount = parsedData.length > 0 ? parsedData.split(this.wordCountRegex).filter((val) => val.length > 0).length : 0;
        const { min, max, exactly } = this.options;
        if (exactly !== undefined) {
            if (wordCount === exactly) {
                return true;
            }
        }
        else if (min !== undefined) {
            if (wordCount >= min && (max === undefined || wordCount <= max)) {
                return true;
            }
        }
        else if (max !== undefined && wordCount <= max) {
            return true;
        }
        return false;
    }
    getError() {
        return this.formatResult([false, { code: "INVALID_WORD_COUNT", context: this.options }], this.options);
    }
}
exports.WordCountValidator = WordCountValidator;
