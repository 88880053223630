"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SchemaDefaultDataService = void 0;
const Property_1 = require("../../Schema/Property/Property");
class SchemaDefaultDataService {
    constructor(form) {
        this.form = form;
        this.mapData(form.schema.childProps());
    }
    mapData(props) {
        props.forEach((prop) => {
            if (prop.has("default") &&
                prop.type !== Property_1.Property.TYPE_OBJECT &&
                prop.type !== Property_1.Property.TYPE_ARRAY &&
                prop.type !== Property_1.Property.TYPE_NULL &&
                (this.form.selectors.is(prop.UID, { preserveDataOnDisqualification: true }) ||
                    (this.form.qualifications[prop.UID] && this.form.selectors.isParentQualified(prop.UID)))) {
                this.form.dataSelector.set(prop.UID, this.form.selectors.getDefaultValue(prop.UID));
            }
            else if (prop.hasChildProps()) {
                this.mapData(prop.childProps());
            }
        });
    }
}
exports.SchemaDefaultDataService = SchemaDefaultDataService;
