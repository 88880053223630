/**
 * THIS COMPONENT IS ONLY KEPT TEMPORARILY WHILE WE ARE WORKING
 * ON THE NEW UPLOAD COMPONENT. THIS WILL EVENTUALLY BE DELETED
 * BECAUSE WE DONT WANT TWO UPLOAD COMPONENTS
 */

import React, { useContext } from "react"
import { Upload as GerudoUpload } from "@akinoxsolutions/gerudo-ui/dist/Upload"
import useTranslation from "../../hooks/useTranslation"
import { MessagingContext } from "../../context/messaging/messagingState"
import { getPropertyUiOptions } from "../../utils/propertyUtils"
import { Button } from "../index"
import { UploadIcon } from "../Icons"
import { AnswerableComponentPropsInterface } from "../interfaces"
import { ShowUploadListType, UploadOptionsInterface } from "./UploadOptionsInterface"

export interface UploadPropsInterface extends AnswerableComponentPropsInterface<unknown> {}

const Upload = ({ isDisabled, isReadOnly, property, onChange, value }: UploadPropsInterface) => {
  const { postPreviewFileMessage } = useContext(MessagingContext)
  const { translate } = useTranslation()
  const { maxItems } = property.def.validations?.upload ?? {}
  const uiOptions = getPropertyUiOptions<UploadOptionsInterface>(property)
  const valueCount = Array.isArray(value) ? value.length : 0
  const totallyDisabled = isDisabled || isReadOnly
  const maxItemsReached = maxItems && maxItems <= valueCount

  const onChangeHandler = (info) => {
    if (info.file.status === "done") {
      if (info.file.response?.status === "error") {
        info.file.status = "error"
      }
    }

    // Do not call onChange until all files are uploaded and/or in error
    if (!info.fileList.some((x) => x.status === "uploading")) {
      onChange(info.fileList)
    }
  }

  const onRemoveHandler = (file) => {
    if (file.status !== "error" && file.response?.[0]?.deleteUrl) {
      const headers = new Headers({
        ...(uiOptions?.headers?.Authorization && { Authorization: uiOptions.headers.Authorization }),
      })
      const initObject = {
        method: "DELETE",
        headers: headers,
      }

      fetch(file.response[0].deleteUrl, initObject)
        .then(function (response) {
          return response.json()
        })
        .catch(function (err) {
          // eslint-disable-next-line no-console
          console.log(err)
          return false
        })
    }
  }

  const onDownloadHandler = (file) => {
    const headers = new Headers({
      ...(uiOptions?.headers?.Authorization && { Authorization: uiOptions.headers.Authorization }),
    })
    const initObject = {
      method: "GET",
      headers: headers,
    }

    fetch(file.response[0].downloadUrl, initObject).then(function (response) {
      response.blob().then(function (blob) {
        const a = document.createElement("a")
        document.body.appendChild(a)
        const url = window.URL.createObjectURL(blob)
        a.href = url
        a.download = file.name
        a.click()
        window.URL.revokeObjectURL(url)
      })
    })
  }

  const onPreviewHandler = (file) => {
    postPreviewFileMessage(file)
  }

  if (totallyDisabled) {
    if ((uiOptions.showUploadList as ShowUploadListType)?.showRemoveIcon) {
      ;(uiOptions.showUploadList as ShowUploadListType).showRemoveIcon = false
    }
  }

  return (
    <GerudoUpload
      defaultFileList={value}
      onChange={onChangeHandler}
      onRemove={onRemoveHandler}
      onDownload={onDownloadHandler}
      onPreview={onPreviewHandler}
      openFileDialogOnClick={!(totallyDisabled || maxItemsReached)}
      {...uiOptions}
    >
      {!totallyDisabled && (
        // @ts-ignore
        <Button
          disabled={maxItemsReached}
          icon={<UploadIcon />}
          id={`upload-${property.UID}`}
          label={translate(uiOptions.buttonText as string) ?? translate("upload.defaultButtonText")}
        />
      )}
    </GerudoUpload>
  )
}
export default Upload
