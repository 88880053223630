import { io, Socket } from "socket.io-client"
import { loadJson } from "./../utils/devUtils"
import { DefinitionInterface } from "@akinoxsolutions/formol"

interface ServerToClientEventsInterface {
  updateSchema: (json: string) => void
}

export class WsClient {
  static create(server: string): Socket<ServerToClientEventsInterface> {
    // eslint-disable-next-line no-console
    console.log("[WS] Creating client ...")
    const socket = io(server)

    let formState: DefinitionInterface = {}
    let lastUpdatedUid = ""

    window.addEventListener("message", ({ data }) => {
      if (data && data.type && data.type === "DYNAMIC_FORM_UPDATE_DATA" && data.payload) {
        formState = { data: data.payload.data, meta: data.payload.meta }
        lastUpdatedUid = data.payload.uid
      }
    })

    socket.on("connect_error", (err) => {
      // eslint-disable-next-line no-console
      console.log(`[WS] connect_error due to ${err.message}`)
    })

    socket.on("updateSchema", (json) => {
      // eslint-disable-next-line no-console
      console.log("[WS] JSON RECEIVED, thank you sir, loading it right now!")
      const jsonObj = JSON.parse(json)
      jsonObj.state = formState
      jsonObj.lastUpdatedUid = lastUpdatedUid
      loadJson(jsonObj)
    })
    return socket
  }
}
