"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExpressionCompiler = exports.expressionArgTypeArray = void 0;
const DataSelector_1 = require("../Selector/DataSelector");
exports.expressionArgTypeArray = ["data", "var", "qualification", "validation", "property"];
const defaultOpts = {
    quoteString: true,
};
class ExpressionCompiler {
    constructor(expression, opts) {
        this.expression = expression;
        this.placeHolders = [];
        this.opts = defaultOpts;
        if (opts) {
            this.opts = Object.assign(Object.assign({}, defaultOpts), opts);
        }
        this.compileExpression();
    }
    canBeEvaluated(ctx) {
        for (const placeHolder of this.placeHolders) {
            if (placeHolder.type === "data" && !ctx.form.dataSelector.has(placeHolder.path)) {
                return [false, `Cannot find data path: ${placeHolder.path}`];
            }
            if (placeHolder.type === "var") {
                const varDataSelector = new DataSelector_1.DataSelector(ctx.form.vars);
                if (!varDataSelector.has(placeHolder.path)) {
                    return [false, `Cannot find var path: ${placeHolder.path}`];
                }
            }
            if (placeHolder.type === "qualification" && ctx.form.qualifications[placeHolder.path] === undefined) {
                return [false, `Cannot find qualification path: ${placeHolder.path}`];
            }
            if (placeHolder.type === "validation" && ctx.form.validations[placeHolder.path] === undefined) {
                return [false, `Cannot find validation path: ${placeHolder.path}`];
            }
            if (placeHolder.type === "property") {
                const propDataSelector = new DataSelector_1.DataSelector(ctx.prop.def);
                if (!propDataSelector.has(placeHolder.path)) {
                    return [false, `Cannot find property def path: ${placeHolder.path}`];
                }
            }
        }
        const expression = this.resolveExpressionPlaceHolders(ctx);
        try {
            eval(expression);
            return [true, null];
        }
        catch (error) {
            return [false, `Cannot eval func expression: ${expression}`];
        }
    }
    getPlaceHolders() {
        return this.placeHolders;
    }
    resolveExpressionPlaceHolders(ctx) {
        let expression = this.expression;
        for (const placeHolder of this.placeHolders) {
            switch (placeHolder.type) {
                case "data":
                    expression = this.dataPlaceHolder(ctx.form, expression, placeHolder);
                    break;
                case "qualification":
                    expression = this.qualificationPlaceHolder(ctx.form, expression, placeHolder);
                    break;
                case "validation":
                    expression = this.validationPlaceHolder(ctx.form, expression, placeHolder);
                    break;
                case "var":
                    expression = this.varPlaceHolder(ctx.form, expression, placeHolder);
                    break;
                case "property":
                    expression = this.propertyDefPlaceHolder(ctx.prop, expression, placeHolder);
                    break;
                case "value":
                    expression = expression.replace(`{${placeHolder.type}}`, this.quote(ctx.data));
                    break;
                default:
                    break;
            }
        }
        return expression;
    }
    quote(value) {
        if (!this.opts.quoteString) {
            return value;
        }
        if (typeof value === "string") {
            return `"${value}"`;
        }
        return value;
    }
    compileExpression() {
        const regex = new RegExp(`\\{((${exports.expressionArgTypeArray.join("|")})\\.)?([^{}]+|value)}`, "g");
        const matches = [...this.expression.matchAll(regex)];
        this.placeHolders = matches.map((match) => {
            let type = match[2];
            let path = match[3];
            if (path === "value") {
                type = "value";
                path = "";
            }
            return { type, path: path.trim() };
        });
        this.placeHolders = this.placeHolders.filter((placeHolder) => placeHolder.type !== undefined);
    }
    dataPlaceHolder(form, expression, placeHolder) {
        return expression.replace(`{${placeHolder.type}.${placeHolder.path}}`, this.quote(form.dataSelector.get(placeHolder.path)));
    }
    qualificationPlaceHolder(form, expression, placeHolder) {
        return expression.replace(`{${placeHolder.type}.${placeHolder.path}}`, form.qualifications[placeHolder.path]);
    }
    validationPlaceHolder(form, expression, placeHolder) {
        return expression.replace(`{${placeHolder.type}.${placeHolder.path}}`, form.validations[placeHolder.path]);
    }
    varPlaceHolder(form, expression, placeHolder) {
        const dataSelector = new DataSelector_1.DataSelector(form.vars);
        return expression.replace(`{${placeHolder.type}.${placeHolder.path}}`, this.quote(dataSelector.get(placeHolder.path)));
    }
    propertyDefPlaceHolder(prop, expression, placeHolder) {
        const dataSelector = new DataSelector_1.DataSelector(prop.def);
        return expression.replace(`{${placeHolder.type}.${placeHolder.path}}`, this.quote(dataSelector.get(placeHolder.path)));
    }
}
exports.ExpressionCompiler = ExpressionCompiler;
