import React from "react"
import AntdImage from "antd/lib/image"
import useTranslation from "../../hooks/useTranslation"

export interface ImagePropsInterface {
  alt?: string
  height?: string
  id?: string
  preview?: boolean
  src?: string
  title?: string
  width?: string
}

export const Image = ({ alt, height, id, src, width, title, preview, ...props }: ImagePropsInterface) => {
  const { translate } = useTranslation()

  if (preview === undefined) {
    preview = true
  }

  return (
    <AntdImage
      alt={translate(alt)}
      title={title ? translate(title) : translate(alt)}
      height={height}
      id={id}
      src={translate(src)}
      width={width}
      preview={preview}
      {...props}
    />
  )
}
