import React, { createContext } from "react"
import useDevPanelAction from "./useDevPanelAction"
import { devPanelInitialState } from "./devPanelReducer"

export const DevPanelContext = createContext(devPanelInitialState)

export const DevPanelProvider = ({ children }) => {
  const [state] = useDevPanelAction()

  return <DevPanelContext.Provider value={state}>{children}</DevPanelContext.Provider>
}
