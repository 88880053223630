"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StringLoader = void 0;
const utils_1 = require("../../utils");
class StringLoader {
    load(schemeResource) {
        const resource = schemeResource;
        const [valid, content] = (0, utils_1.safeJSONParse)(resource);
        if (!valid) {
            throw new Error(`Unable to parse the JSON Schema string, it appears to be invalid: ${content.error}`);
        }
        return content;
    }
}
exports.StringLoader = StringLoader;
