import React, { useContext } from "react"
import useTranslation from "../../hooks/useTranslation"
import { ConfigContext } from "../../context/config/configState"
import { FormContext } from "../../context/form/formState"
import { LoadingMessage, Message } from "../../components"

const MessageLayout = (): JSX.Element => {
  const { translate } = useTranslation()
  const { devMode } = useContext(ConfigContext)
  const { error, formStatus } = useContext(FormContext)

  switch (formStatus) {
    case "WAITING_FOR_PAYLOAD":
      return (
        <Message
          description={translate("waiting.forEvent")}
          message={translate("waiting.message")}
          type="info"
          withDelay={true}
        />
      )
    case "LOADING_IN_PROGRESS":
      return (
        <LoadingMessage
          description={translate("loadingForm")}
          message={translate("loading.message")}
          tip={translate("loading.tip")}
        />
      )
    case "LOADING_ERROR":
      return (
        <Message
          description={devMode ? error?.stackTrace : translate("errors.loadingForm")}
          message={translate("errors.header")}
          type="error"
        />
      )
    case "EMPTY":
      return <Message description={translate("errors.emptyForm")} message={translate("errors.header")} type="warning" />
    case "SUBMITTED":
      return (
        <Message
          description={translate("submitted.description")}
          message={translate("submitted.message")}
          type="success"
        />
      )
    default:
      return <></>
  }
}

export default MessageLayout
