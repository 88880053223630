"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.plugins = void 0;
const UpdateTrackerPlugin_1 = require("./UpdateTracker/UpdateTrackerPlugin");
const PropertyScorePlugin_1 = require("./PropertyScore/PropertyScorePlugin");
const TimeTrackerPlugin_1 = require("./TimeTracker/TimeTrackerPlugin");
const PropertyCategoryPlugin_1 = require("./PropertyCategory/PropertyCategoryPlugin");
const MetaFormStatsPlugin_1 = require("./MetaFormStats/MetaFormStatsPlugin");
const MetaDataPlugin_1 = require("./MetaData/MetaDataPlugin");
const MetaFormQualificationsPlugin_1 = require("./MetaFormQualifications/MetaFormQualificationsPlugin");
const PartialFormValidationPlugin_1 = require("./PartialFormValidation/PartialFormValidationPlugin");
const plugins = {
    metaData: (opts) => new MetaDataPlugin_1.MetaDataPlugin(opts),
    metaFormQualifications: () => new MetaFormQualificationsPlugin_1.MetaFormQualificationsPlugin(),
    metaFormStats: (opts) => new MetaFormStatsPlugin_1.MetaFormStatsPlugin(opts),
    partialFormValidation: () => new PartialFormValidationPlugin_1.PartialFormValidationPlugin(),
    propertyCategory: (opts) => new PropertyCategoryPlugin_1.PropertyCategoryPlugin(opts),
    propertyScore: (opts) => new PropertyScorePlugin_1.PropertyScorePlugin(opts),
    timeTracker: () => new TimeTrackerPlugin_1.TimeTrackerPlugin(),
    updateTracker: () => new UpdateTrackerPlugin_1.UpdateTrackerPlugin(),
};
exports.plugins = plugins;
