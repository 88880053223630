"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConditionalValueCompareValidator = void 0;
const tslib_1 = require("tslib");
const ValidatorFactory_1 = require("./ValidatorFactory");
const ValueCompareValidator_1 = require("./ValueCompareValidator");
class ConditionalValueCompareValidator {
    constructor(options, type) {
        this.options = options;
        this.type = type;
        this.validatorFactory = new ValidatorFactory_1.ValidatorFactory();
        this.compiledOptions = [];
        if (!Array.isArray(this.options)) {
            this.options = [this.options];
        }
        this.compiledOptions = this.compileOptions();
    }
    validate({ form, data, prop }) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            for (const condition of Object.values(this.compiledOptions)) {
                const conditionValidator = new ValueCompareValidator_1.ValueCompareValidator(condition.mainCondition, this.type);
                const [conditionMatching] = yield conditionValidator.validate({ form, data, prop });
                if (conditionMatching) {
                    form.selectors.updateConditionId(prop.UID, condition.id);
                    return yield condition.then.validate({ form, data, prop });
                }
                else if (condition.otherwise) {
                    form.selectors.updateConditionId(prop.UID, condition.id);
                    return yield condition.otherwise.validate({ form, data, prop });
                }
                else {
                    form.selectors.updateConditionId(prop.UID, "_");
                }
            }
            return [true, null];
        });
    }
    isValueCompare(then) {
        return Object.prototype.hasOwnProperty.call(then, "should") && Object.prototype.hasOwnProperty.call(then, "value");
    }
    compileOptions() {
        const compiledOptions = [];
        for (const [, condition] of Object.entries(this.options)) {
            const id = condition.id || "_";
            const mainConditionOptions = {
                selectors: condition.selectors || [],
                should: condition.should,
                valueSelectors: condition.valueSelectors,
                value: condition.value,
            };
            const validatorName = Object.keys(condition.then)[0];
            const validator = this.isValueCompare(condition.then)
                ? this.validatorFactory.create("valueCompare", condition.then, this.type)
                : this.validatorFactory.create(validatorName, condition.then[validatorName], this.type);
            let elseValidator = null;
            if (condition.otherwise) {
                elseValidator = this.isValueCompare(condition.otherwise)
                    ? this.validatorFactory.create("valueCompare", condition.otherwise, this.type)
                    : this.validatorFactory.create(validatorName, condition.otherwise[validatorName], this.type);
            }
            compiledOptions.push({ id, mainCondition: mainConditionOptions, then: validator, otherwise: elseValidator });
        }
        return compiledOptions;
    }
}
exports.ConditionalValueCompareValidator = ConditionalValueCompareValidator;
