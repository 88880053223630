"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IsInEnumValidator = void 0;
const tslib_1 = require("tslib");
const AbstractValidator_1 = require("./AbstractValidator");
class IsInEnumValidator extends AbstractValidator_1.AbstractValidator {
    constructor(options, type) {
        var _a;
        super(type);
        this.options = options;
        this.options.caseSensitive = (_a = options.caseSensitive) !== null && _a !== void 0 ? _a : true;
    }
    validate({ data, prop }) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let propEnum = [];
            for (const el of prop.def["enum"]) {
                const elType = typeof el;
                if (["string", "number"].includes(elType)) {
                    propEnum.push(el);
                }
                else if (elType === "object" && el["value"] !== undefined) {
                    propEnum.push(el["value"]);
                }
            }
            let d = data;
            if (!Array.isArray(d)) {
                d = [d];
            }
            if (!this.options.caseSensitive) {
                propEnum = propEnum.map((a) => a.toLowerCase());
                d = d.map((a) => a.toLowerCase());
            }
            for (const item of d) {
                const [result, error] = this._validate(item, propEnum);
                if (!result) {
                    return [false, error];
                }
            }
            if (this.options.minItems && d.length < this.options.minItems) {
                return this.formatResult([false, { code: "ENUM_MIN_ITEMS", context: { minItems: this.options.minItems } }], this.options);
            }
            else if (this.options.maxItems && d.length > this.options.maxItems) {
                return this.formatResult([false, { code: "ENUM_MAX_ITEMS", context: { maxItems: this.options.maxItems } }], this.options);
            }
            return [true, null];
        });
    }
    _validate(data, propEnum) {
        if (!propEnum.includes(data)) {
            if (!this.options.nullable || data !== null) {
                return this.formatResult([false, { code: "VALUE_NOT_IN_ENUM" }], this.options);
            }
        }
        return [true, null];
    }
}
exports.IsInEnumValidator = IsInEnumValidator;
