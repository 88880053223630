"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdateQualification = void 0;
const tslib_1 = require("tslib");
const FormStatuses_1 = require("../FormStatuses");
class UpdateQualification {
    constructor(form) {
        this.form = form;
    }
    execute(selector, isQualified) {
        var _a;
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!this.form.selectors.has(selector)) {
                return {};
            }
            const updatedSelectors = {
                selector: isQualified,
            };
            const oldIsQualified = this.form.qualifications[selector];
            this.form.qualifications[selector] = isQualified;
            if (oldIsQualified === isQualified || this.form.status !== FormStatuses_1.FormStatuses.Ready) {
                return updatedSelectors;
            }
            const propInfo = this.form.selectors.get(selector);
            if (!isQualified && propInfo.isArray && !propInfo.preserveDataOnDisqualification) {
                yield this.form.selectors.resetArray(propInfo.UID);
            }
            const getValue = (selector) => {
                return isQualified ? this.getValue(selector) : null;
            };
            if (propInfo.isAnswerable &&
                (!propInfo.preserveDataOnDisqualification || ((_a = this.form.qualificationsErrors[selector]) === null || _a === void 0 ? void 0 : _a.behavior) === "reset")) {
                this.form.dataSelector.trySet(selector, getValue(selector));
            }
            else if (propInfo.isNode) {
                for (const childSelector of this.form.selectors.getChildrenSelectors(selector)) {
                    if (this.form.selectors.is(childSelector, { isAnswerable: true, preserveDataOnDisqualification: false })) {
                        updatedSelectors[childSelector] = isQualified;
                        this.form.dataSelector.trySet(childSelector, getValue(childSelector));
                    }
                }
            }
            return updatedSelectors;
        });
    }
    getValue(valueSelector) {
        const [, oldValue] = this.form.dataSelector.tryGet(valueSelector);
        if (oldValue !== undefined && oldValue !== null) {
            return oldValue;
        }
        return this.form.selectors.getDefaultValue(valueSelector);
    }
}
exports.UpdateQualification = UpdateQualification;
