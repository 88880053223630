import React, { useContext } from "react"
import { AvailableInfoInterface } from "../interfaces"
import { FormContext } from "../../../context/form/formState"
import { UseFormActionInterface } from "../../../context/form/useFormAction"
import { PreviousLabel } from "../../Label/PreviousLabel/PreviousLabel"
import { Button } from "../../index"

export interface PreviousStepButtonPropsInterface {
  availableInfo: AvailableInfoInterface
  id?: string
}

const PreviousStepButton = ({ availableInfo, id = "navigate-to-previous-step" }: PreviousStepButtonPropsInterface) => {
  const { setStep, setAutofocusUid, form }: UseFormActionInterface = useContext(FormContext)
  const decrementStepIndex = () => {
    if (!availableInfo.isFirstStep) {
      setStep({ stepUid: availableInfo.previousSectionId })
    }

    if (availableInfo.previousSectionId && form) {
      const previousUid = form.selectors.getChildrenAnswerableSelectors(availableInfo.previousSectionId)[0]
      if (previousUid) {
        setAutofocusUid(previousUid)
      }
    }
  }

  return <Button disabled={availableInfo.isFirstStep} id={id} label={<PreviousLabel />} onClick={decrementStepIndex} />
}

export default PreviousStepButton
