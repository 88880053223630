"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ArraysNodes = void 0;
class ArraysNodes {
    constructor(form) {
        this.form = form;
    }
    isValid(selector) {
        var _a;
        if (this.form.schema.nodes.arrays.has(selector) && this.form.qualifications[selector]) {
            const childrenSelectors = (_a = this.form.schema.nodes.arrays.get(selector)) === null || _a === void 0 ? void 0 : _a.answerableSelectors;
            for (const sel of childrenSelectors) {
                if (this.form.qualifications[sel] && !this.form.validations[sel]) {
                    return false;
                }
            }
            return true;
        }
        return false;
    }
    updateValidations() {
        for (const array of this.form.schema.nodes.arrays.values()) {
            this.form.validations[array.property.UID] = this.isValid(array.property.UID);
        }
    }
    mapArrayChildrenAncestors(selector) {
        const arrayProp = this.form.schema.nodes.arrays.get(selector);
        if (arrayProp) {
            arrayProp.mapChildrenAncestors();
        }
    }
    mapArrayChildrenAnswerableSelectors(selector) {
        const arrayProp = this.form.schema.nodes.arrays.get(selector);
        if (arrayProp) {
            arrayProp.mapChildrenAnswerableSelectors();
        }
    }
    updateArraySelectors(selector) {
        this.mapArrayChildrenAncestors(selector);
        this.mapArrayChildrenAnswerableSelectors(selector);
    }
}
exports.ArraysNodes = ArraysNodes;
