"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.arrayEqual = exports.safeJSONParse = exports.mapToObject = exports.getTimestamp = exports.colonCaseToCamelCase = exports.camelize = exports.capitalize = exports.prefixStr = exports.deepMerge = void 0;
const merge = require("deepmerge");
const overwriteMerge = (_destinationArray, sourceArray) => sourceArray;
const deepMerge = (x, y) => {
    return merge(x, y, { arrayMerge: overwriteMerge });
};
exports.deepMerge = deepMerge;
const prefixStr = (str, prefix, separator) => {
    if (prefix && prefix.trim().length > 0) {
        return `${prefix}${separator}${str}`;
    }
    return str.trim();
};
exports.prefixStr = prefixStr;
const capitalize = (s) => s.charAt(0).toUpperCase() + s.slice(1);
exports.capitalize = capitalize;
const camelize = (s) => {
    return s
        .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
        .replace(/\s+/g, "");
};
exports.camelize = camelize;
const colonCaseToCamelCase = (s) => {
    const parts = s.split(":");
    let camelCase = parts.shift() || "";
    for (const part of parts) {
        camelCase += capitalize(part);
    }
    return camelCase;
};
exports.colonCaseToCamelCase = colonCaseToCamelCase;
const getTimestamp = () => {
    return Math.floor(new Date().getTime() / 1000);
};
exports.getTimestamp = getTimestamp;
const mapToObject = (map) => {
    return Array.from(map).reduce((obj, [key, value]) => {
        obj[key] = value;
        return obj;
    }, {});
};
exports.mapToObject = mapToObject;
const safeJSONParse = (data) => {
    try {
        const json = JSON.parse(data);
        return [true, json];
    }
    catch (e) {
        const error = (e + "").split("at JSON.parse")[0];
        return [false, { error }];
    }
};
exports.safeJSONParse = safeJSONParse;
const arrayEqual = (array1, array2) => {
    if (!Array.isArray(array1) || !Array.isArray(array2)) {
        return false;
    }
    return array1.length === array2.length && array1.every((v) => array2.includes(v));
};
exports.arrayEqual = arrayEqual;
