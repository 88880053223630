"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StandardMode = void 0;
const AbstractNavigationMode_1 = require("./AbstractNavigationMode");
class StandardMode extends AbstractNavigationMode_1.AbstractNavigationMode {
    getNavigationList(filterFn = null) {
        const candidates = [];
        const answerable = Object.values(this.form.selectors.getAnswerableSelectorsInfos()).sort(this.globalIndexSortingClosure);
        for (const sel of answerable) {
            const candidate = sel;
            if (this.form.qualifications[sel.UID]) {
                candidate.relatedSelectors = this.propertiesSelectorsMapper.mapSelectors(this.form.schema.search(candidate.UID));
                candidates.push(candidate);
            }
        }
        let list = [...new Set(candidates)];
        if (filterFn) {
            list = list.filter(filterFn);
        }
        return list;
    }
}
exports.StandardMode = StandardMode;
