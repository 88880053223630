import React, { useContext } from "react"
import classNames from "classnames"
import IconWrapper from "@akinoxsolutions/gerudo-ui/dist/Icons/IconWrapper/IconWrapper"
import useTranslation from "../../hooks/useTranslation"
import { FormContext } from "../../context/form/formState"
import { Property } from "../Property/Property"
import { Button, Html } from "../index"
import styles from "./Array.module.css"

export const Array = ({ parentIsDisabled, parentIsReadOnly, property }) => {
  const { translate } = useTranslation()

  const { arrayOptions, disabled, readOnly, labels = {} } = property.def.ui ?? {}
  const { displayIndexInTitle, embedRemoveItemButton, hideActions, itemSeparator } = arrayOptions ?? {}
  const { addButton: addButtonLabel, removeButton: removeButtonLabel, noItem: noItemLabel } = labels

  const {
    addItemTo,
    deleteItem,
    formQualifications: { [property.UID]: isQualified },
    form,
    isLoadingItems: { [property.UID]: isLoading },
  } = useContext(FormContext)

  if (!isQualified) {
    return null
  }

  const child = [...form.selectors.get(property.UID).property().childProps().values()]
  const childSize = child.length
  const isDisabled = parentIsDisabled || disabled
  const isReadOnly = parentIsReadOnly || readOnly

  const handleDeleteClick = (index) => {
    if (!isLoading && (!property.arrayMinItems || childSize > property.arrayMinItems)) {
      deleteItem(property.UID, index)
    }
  }

  const handleAddClick = () => {
    if (!isLoading && (!property.arrayMaxItems || childSize < property.arrayMaxItems)) {
      addItemTo(property.UID)
    }
  }

  const hasEmbedChild = () => child.some((c) => c.def.ui?.options?.highlight === "embed")
  const hasBorderedChild = () => child.some((c) => c.def.ui?.options?.highlight === true)

  const displayFixedNumberOfElements = property.arrayMaxItems === property.arrayMinItems
  const displayAddItemAction =
    !displayFixedNumberOfElements &&
    !isReadOnly &&
    !hideActions?.addItem &&
    (!property.arrayMaxItems || childSize < property.arrayMaxItems)
  const displayRemoveItemAction = !displayFixedNumberOfElements && !isReadOnly && !hideActions?.removeItem

  const itemClassNames = []
  if (displayRemoveItemAction) {
    itemClassNames.push(styles.CanBeDeleted)
    if (embedRemoveItemButton) {
      itemClassNames.push(styles.EmbededDeleteButton)
    }
  }

  return (
    <>
      {childSize > 0 && (
        <section className={classNames({ [styles.BorderBetweenProperties]: itemSeparator })}>
          {child.map((childProperty, index) => (
            <div className={styles.Item} key={`${childProperty.UID}-${index}`}>
              {displayRemoveItemAction && (
                <button
                  aria-label={translate("actions.removeItem")}
                  className={classNames(styles.DeleteItem, { [styles.HighlightBordered]: hasBorderedChild() })}
                  onClick={() => handleDeleteClick(index)}
                  disabled={isLoading || childSize <= property.arrayMinItems}
                  id={`remove-${childProperty.UID}`}
                >
                  <IconWrapper name="X" />
                  {removeButtonLabel && (
                    <Html className={styles.RemoveButtonLabel} content={translate(removeButtonLabel)} />
                  )}
                </button>
              )}
              <Property
                className={itemClassNames.join(" ")}
                parentIsDisabled={isDisabled}
                parentIsReadOnly={isReadOnly}
                property={childProperty}
                title={displayIndexInTitle ? `${translate(childProperty.def.title)}${index + 1}` : undefined}
              />
            </div>
          ))}
        </section>
      )}

      {!childSize && noItemLabel && <div className={styles.NoItem}>{<Html content={translate(noItemLabel)} />}</div>}

      {displayAddItemAction && (
        <div className={classNames(styles.AddItem, { [styles.EmbedBordered]: hasEmbedChild() })}>
          <Button
            aria-label={translate("actions.addItem")}
            disabled={isLoading}
            id={`add-${property.UID}`}
            icon={<IconWrapper name="Plus" />}
            label={addButtonLabel ? <Html content={translate(addButtonLabel)} /> : undefined}
            onClick={handleAddClick}
            type="secondary"
          />
        </div>
      )}
    </>
  )
}
