import { useEffect, useReducer } from "react"
import devPanelReducer, { devPanelInitialState } from "./devPanelReducer"
import devPanelTypes from "./devPanelTypes"
import loaderService from "../../services/LoaderService"
import { getCustomResources } from "../../i18n"
import { isValidJson, loadJson } from "../../utils/devUtils"

function useDevPanelAction() {
  const [state, dispatch] = useReducer(devPanelReducer, devPanelInitialState, () => devPanelInitialState)

  useEffect(() => {
    if (state.autoUpdate && state.schemaIsValid) {
      loaderService.debounce({ fnc: reloadForm, uid: state.debounceUid }, state.debounceTime)()
    }
  }, [state.autoUpdate, state.schema, state.schemaIsValid])

  const initDevPanel = () => {
    dispatch({ type: devPanelTypes.INIT_DEV_PANEL })
  }

  const reloadForm = () => {
    if (loaderService.isDebouncing(state.debounceUid)) {
      loaderService.forceDebounce(state.debounceUid)
    } else {
      loadJson({ schema: state.schema, translations: getCustomResources(), events: state.customEvents })
    }
  }

  const setAutoUpdate = (value) => {
    dispatch({ type: devPanelTypes.UPDATE_AUTO_UPDATE, value })
  }

  const setCustomEvents = (newCustomEvents) => {
    dispatch({ type: devPanelTypes.SET_CUSTOM_EVENTS, customEvents: newCustomEvents })
  }

  const setEditorTheme = (value) => {
    dispatch({ type: devPanelTypes.UPDATE_EDITOR_THEME, value })
  }

  const setIsEditable = (value) => {
    dispatch({ type: devPanelTypes.UPDATE_IS_EDITABLE, value })
  }

  const setQvFilters = (qvFilters) => {
    dispatch({ type: devPanelTypes.UPDATE_Q_V_FILTERS, qvFilters })
  }

  const setQVTable = (value) => {
    dispatch({ type: devPanelTypes.UPDATE_Q_V_TABLE, value })
  }

  const setSchema = (schema) => {
    const [isValid, errorMessage] = isValidJson(schema)
    dispatch({ type: devPanelTypes.UPDATE_SCHEMA, schema, isValid, errorMessage })
  }

  const setTabActiveKey = (value) => {
    dispatch({ type: devPanelTypes.UPDATE_TAB_ACTIVE_KEY, value })
  }

  return [
    {
      ...state,
      initDevPanel,
      reloadForm,
      setAutoUpdate,
      setCustomEvents,
      setEditorTheme,
      setIsEditable,
      setQvFilters,
      setQVTable,
      setSchema,
      setTabActiveKey,
    },
    dispatch,
  ]
}

export default useDevPanelAction
