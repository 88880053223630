"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DefinitionProcessor = void 0;
const RefDefinitionProcessor_1 = require("./RefDefinitionProcessor");
class DefinitionProcessor extends RefDefinitionProcessor_1.RefDefinitionProcessor {
    constructor() {
        super("definitions");
    }
}
exports.DefinitionProcessor = DefinitionProcessor;
