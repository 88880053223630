"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SchemaNodesValidationService = void 0;
const tslib_1 = require("tslib");
const Events_1 = require("../Event/Events");
class SchemaNodesValidationService {
    constructor(form) {
        this.form = form;
    }
    registerEvents() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.form.events.addEvent(Events_1.Events.DataUpdated, () => tslib_1.__awaiter(this, void 0, void 0, function* () {
                yield this.form.nodes.sections.updateValidations();
                this.form.nodes.groups.updateValidations();
                this.form.nodes.arrays.updateValidations();
            }));
            yield this.form.nodes.sections.updateValidations();
            this.form.nodes.groups.updateValidations();
            this.form.nodes.arrays.updateValidations();
        });
    }
}
exports.SchemaNodesValidationService = SchemaNodesValidationService;
