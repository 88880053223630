"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EventsResolverContext = void 0;
const tslib_1 = require("tslib");
const ValidatorFactory_1 = require("../Validator/ValidatorFactory");
const Events_1 = require("../Event/Events");
const Logger_1 = require("../../Logger");
class EventsResolverContext {
    constructor() {
        this.validatorFactory = new ValidatorFactory_1.ValidatorFactory();
    }
    getContext(form) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return {
                form: form,
                addEvent: (type, fn) => form.events.addEvent(type, fn),
                addDataUpdatedEvent: (fn) => form.events.addEvent(Events_1.Events.DataUpdated, fn),
                addDataUpdatedEventTo: (selector, fn, selectorsTargeted = []) => {
                    form.events.addEventTo(selector, Events_1.Events.DataUpdated, fn);
                    form.events.selectorEventsManager.addSelectorsTargetedByEvents(selectorsTargeted);
                },
                addQualificationTo: (selector, fn, selectorsTargeted = []) => {
                    typeof fn === "function"
                        ? form.events.addEventTo(selector, Events_1.Events.DataQualification, fn)
                        : this.registerValidatorAsDefinition(form, Events_1.Events.DataQualification, selector, fn);
                    form.events.selectorEventsManager.addSelectorsTargetedByEvents(selectorsTargeted);
                },
                addValidationTo: (selector, fn, selectorsTargeted = []) => {
                    typeof fn === "function"
                        ? form.events.addEventTo(selector, Events_1.Events.DataValidation, fn)
                        : this.registerValidatorAsDefinition(form, Events_1.Events.DataValidation, selector, fn);
                    form.events.selectorEventsManager.addSelectorsTargetedByEvents(selectorsTargeted);
                },
                addSelectorsTargetedByEvents: (selectors) => form.events.selectorEventsManager.addSelectorsTargetedByEvents(selectors),
                setPassHandler: (handler, selectorsTargeted = []) => {
                    form.passHandler = handler;
                    form.events.selectorEventsManager.addSelectorsTargetedByEvents(selectorsTargeted);
                },
                onFormReady: (handler) => {
                    form.miscHandlers["onFormReady"] = handler;
                },
            };
        });
    }
    registerValidatorAsDefinition(form, type, selector, definition) {
        if (!definition || Array.isArray(definition) || typeof definition !== "object") {
            const fnName = type === Events_1.Events.DataValidation ? "addValidationTo" : "addQualificationTo";
            Logger_1.Logger.log(`${fnName}("${selector}") second param is not function or object literal representing a validator definition`);
            return;
        }
        for (const [validatorName, validatorOpts] of Object.entries(definition)) {
            const validator = this.validatorFactory.create(validatorName, validatorOpts, type);
            form.events.addEventTo(selector, type, ({ form, data }) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                return yield validator.validate({ form, data, prop: form.schema.search(selector) });
            }));
        }
    }
}
exports.EventsResolverContext = EventsResolverContext;
