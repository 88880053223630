export default {
  ca_phoneNumber: {
    mask: "(999) 999-9999",
    opts: {
      ariaLabel: "ariaLabel.ca_phoneNumber",
      inputType: "tel",
      placeholder: "placeholders.ca_phoneNumber",
    },
  },
  ca_postalCode: {
    mask: "a9a 9a9",
    opts: {
      ariaLabel: "ariaLabel.ca_postalCode",
      placeholder: "placeholders.ca_postalCode",
    },
  },
  ca_qc_hin: {
    mask: "aaaa 9999 99*9",
    opts: {
      ariaLabel: "ariaLabel.ca_qc_hin",
      placeholder: "placeholders.ca_qc_hin",
    },
  },
  ca_sin: {
    mask: "999 999 999",
    opts: {
      ariaLabel: "ariaLabel.ca_sin",
      placeholder: "placeholders.ca_sin",
    },
  },
}
