import React, { ReactNode } from "react"

import { DropzonePreview } from "@akinoxsolutions/gerudo-ui/dist/Dropzone/DropzonePreview"
import { Attachment } from "./Attachment"
import { UseDropzonePreviewActionsProps } from "@akinoxsolutions/gerudo-ui/dist/Dropzone/types"

interface AttachmentListPropsInterface {
  files: Attachment[]
  isDeletable: boolean
  onRemovePreview: (file: Attachment) => void
  renderPreviewInfo: (file: Attachment) => ReactNode
  actions: UseDropzonePreviewActionsProps<Attachment>[]
}

const AttachmentList = ({
  files,
  isDeletable,
  onRemovePreview,
  renderPreviewInfo,
  actions,
}: AttachmentListPropsInterface) => {
  return (
    <div>
      {files.map((file) => {
        return (
          <DropzonePreview<Attachment>
            key={file.name}
            onRemovePreview={onRemovePreview}
            file={file}
            loadingProgress={file.progress}
            isDeletable={isDeletable}
            renderPreviewInfo={renderPreviewInfo}
            actions={actions}
          />
        )
      })}
    </div>
  )
}

export default AttachmentList
