import React, { ElementType, ReactNode, useContext, useEffect } from "react"
import classNames from "classnames"
import useScroll from "../../hooks/useScroll"
import useTranslation from "../../hooks/useTranslation"
import { FormContext } from "../../context/form/formState"
import {
  getDataAttribute,
  getHtmlHeadingSemanticLevel,
  getHtmlHeadingStyleLevel,
  getStyle,
  isPropertyUsingHtmlHeading,
} from "../../utils/uiUtils"
import { getTranslationKeyToUse } from "../../utils/translationUtils"
import { Paragraph } from "../Typography/Paragraph/Paragraph"
import { Watermark } from "../Watermark/Watermark"
import { HiddenText, Html, HtmlPropsInterface, Label, Reset, Title } from "../index"
import styles from "./Property.module.css"
import { PropertyInterface, FormInterface } from "@akinoxsolutions/formol"
import { PropertySemanticType } from "./types"

export interface PropertyWrapperPropsInterface {
  children: ReactNode
  className?: string
  isAnswerable?: boolean
  isDisabled?: boolean
  isDivided?: boolean
  isReadOnly?: boolean
  property: PropertyInterface
  semanticType: PropertySemanticType
  title?: string
}

const getParagraph = (content) => (
  <Paragraph className={styles.SurroundingArea} type="secondary">
    <Html content={content} />
  </Paragraph>
)

export const PropertyWrapper = ({
  children,
  className,
  isAnswerable,
  isDisabled,
  isDivided,
  isReadOnly,
  property,
  semanticType,
  title,
}: PropertyWrapperPropsInterface) => {
  const { translate } = useTranslation()
  const { after, before, description, title: propertyTitle, ui = {} } = property.def
  const {
    conditionalAfter,
    conditionalBefore,
    conditionalDescription,
    dataAttributes,
    displayWatermark,
    optionalFlag,
    options: {
      borders = undefined,
      boxed = true,
      hideStandardTitle = false,
      highlight = false,
      unbreakablePrint = false,
      canResetProperty = false,
      titleStyledAs = undefined,
      tooltip = undefined,
    } = {},
    a11y: { hiddenTitle = false } = {},
    requiredFlag,
    titles: uiTitles,
    watermarkOptions,
  } = ui

  const {
    autofocusUid,
    form,
    formOptions,
    formValidations: { [property.UID]: isValid },
    isFirstTimeViewingCurrentSection,
    resetData,
  } = useContext(FormContext)

  const { highlightInvalid } = formOptions

  const [executeScroll, elRef] = useScroll()
  useEffect(() => {
    if (autofocusUid === property.UID) {
      executeScroll()
    }
  }, [])

  const isResettable =
    canResetProperty && !isReadOnly && !isDisabled && form?.selectors.hasAtLeastOneAnswer(property.UID)

  const wrapperClassNames = classNames("property-wrapper", styles.PropertyWrapper, {
    [styles.Answerable]: isAnswerable,
    [styles.TopBorder]: Boolean(borders?.top),
    [styles.RightBorder]: Boolean(borders?.right),
    [styles.BottomBorder]: Boolean(borders?.bottom),
    [styles.LeftBorder]: Boolean(borders?.left),
  })

  const propertyClassNames = classNames("property", styles.Property, className, {
    [styles.HighlightInvalid]: highlight && highlightInvalid && !isValid && !isFirstTimeViewingCurrentSection,
    [styles.Bordered]: highlight && highlight !== "embed",
    [styles.EmbedBordered]: highlight && highlight === "embed",
    [styles.Valid]: isValid,
    [styles.Unboxed]: !boxed,
    [styles.UnbreakablePrint]: isAnswerable || unbreakablePrint,
    [styles.CanReset]: isResettable,
  })

  let PropertyTag: ElementType
  let PropertyTitleWrapperTag: ElementType
  let PropertyTitleInnerTag: ElementType

  switch (semanticType) {
    case PropertySemanticType.Input:
      PropertyTag = "div"
      PropertyTitleWrapperTag = "div"
      PropertyTitleInnerTag = "label"
      break
    case PropertySemanticType.InputGroup:
      PropertyTag = "fieldset"
      PropertyTitleWrapperTag = "legend"
      PropertyTitleInnerTag = "span"
      break
    case PropertySemanticType.Display:
    default:
      PropertyTag = "div"
      PropertyTitleWrapperTag = "div"
      PropertyTitleInnerTag = "span"
  }

  const getContentBefore = () => {
    if (!before && !conditionalBefore) return
    return getParagraph(
      translate(
        getTranslationKeyToUse({
          formSelectors: (form as FormInterface).selectors,
          key: before,
          conditionalKey: conditionalBefore,
          uid: property.UID,
        }),
      ),
    )
  }

  const getTitle = () => {
    if (hideStandardTitle || (!uiTitles && !title && !propertyTitle)) return

    let titleContent: ReactNode

    if (uiTitles) {
      titleContent = uiTitles.map(({ title: uiTitle, htmlElement: Tag = "span", ...ui }, index) => (
        <Html
          key={`${property.UID}-title-${index}`}
          content={translate(uiTitle)}
          Tag={Tag as HtmlPropsInterface["Tag"]}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          style={getStyle(ui as any)}
        />
      ))
    } else if (isPropertyUsingHtmlHeading(property)) {
      titleContent = (
        <Title
          level={getHtmlHeadingSemanticLevel(property.UID, form as FormInterface)}
          styledAs={titleStyledAs || getHtmlHeadingStyleLevel(property.UID)}
          tooltip={tooltip}
        >
          <Label Tag={PropertyTitleInnerTag} htmlFor={property.UID} inheritStyle>
            <Html content={title ?? translate(propertyTitle)} />
          </Label>
        </Title>
      )
    } else {
      titleContent = (
        <Label Tag={PropertyTitleInnerTag} htmlFor={property.UID} tooltip={tooltip}>
          <Html content={title ?? translate(propertyTitle)} />
        </Label>
      )
    }

    const requiredFlagElement = requiredFlag ? (
      <div className={styles.Required}>{typeof requiredFlag === "string" ? translate(requiredFlag) : "*"}</div>
    ) : null

    const optionalFlagElement = optionalFlag ? (
      <div className={styles.Optional}>
        {typeof optionalFlag === "string" ? translate(optionalFlag) : translate("optional.flag")}
      </div>
    ) : null

    if (hiddenTitle) {
      return (
        <HiddenText htmlTag={PropertyTitleWrapperTag}>
          <div className="property-title">
            {titleContent}
            {requiredFlagElement}
          </div>
          {optionalFlagElement}
        </HiddenText>
      )
    }

    return (
      <PropertyTitleWrapperTag
        className={classNames(styles.TitleWrapper, {
          [styles.DividedTitleWrapper]: isDivided,
          [styles.WithRequiredFlag]: Boolean(requiredFlagElement),
          [styles.WithOptionalFlag]: Boolean(optionalFlagElement),
        })}
      >
        <div className={classNames("property-title", styles.Titles)}>
          {titleContent}
          {requiredFlagElement}
        </div>
        {optionalFlagElement}
      </PropertyTitleWrapperTag>
    )
  }

  const getDescription = () => {
    if (!description && !conditionalDescription) return
    return getParagraph(
      translate(
        getTranslationKeyToUse({
          formSelectors: (form as FormInterface).selectors,
          key: description,
          conditionalKey: conditionalDescription,
          uid: property.UID,
        }),
      ),
    )
  }

  const getContentAfter = () => {
    if (!after && !conditionalAfter) return
    return getParagraph(
      translate(
        getTranslationKeyToUse({
          formSelectors: (form as FormInterface).selectors,
          key: after,
          conditionalKey: conditionalAfter,
          uid: property.UID,
        }),
      ),
    )
  }

  return (
    <div
      className={wrapperClassNames}
      id={`${property.UID}-property`}
      ref={elRef}
      style={getStyle(ui)}
      {...getDataAttribute(dataAttributes)}
    >
      <PropertyTag className={propertyClassNames}>
        {displayWatermark && <Watermark id={property.UID} {...watermarkOptions} />}
        {getContentBefore()}
        {getTitle()}
        {getDescription()}
        {children}
        {getContentAfter()}
      </PropertyTag>
      {isResettable && (
        <Reset className={styles.ResetLink} id={`reset-${property.UID}`} resetData={() => resetData(property.UID)} />
      )}
    </div>
  )
}
