"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormPlugins = void 0;
class FormPlugins {
    constructor() {
        this.plugins = new Map();
    }
    register(plugin) {
        this.plugins.set(plugin.name, plugin);
        return this;
    }
    triggerEvent(event, eventCtx) {
        this.plugins.forEach((plugin) => {
            plugin.triggerEvent(event, eventCtx);
        });
        return this;
    }
    get(pluginName) {
        return this.plugins.get(pluginName);
    }
    count() {
        return this.plugins.size;
    }
}
exports.FormPlugins = FormPlugins;
