import React, { useContext } from "react"
import { FormContext } from "../../context/form/formState"
import { DownloadPrintable } from "../../components/DownloadPrintable/DownloadPrintable"
import { Footer } from "../../components/Footer/Footer"
import { StepLayout } from "../StepLayout/StepLayout"
import { Stepper } from "../../components/Stepper/Stepper"
import { StepNavigation } from "../../components"
import styles from "./SectionalFormRenderer.module.css"

export const SectionalFormRenderer = () => {
  const { form } = useContext(FormContext)
  const { ui = {} } = form.schema.def

  return (
    <div className={styles.SectionalFormRenderer}>
      <section className={styles.Stepper}>
        <Stepper />
        <DownloadPrintable />
      </section>

      <section className={styles.Layout} style={ui.style}>
        <StepLayout />
        <StepNavigation />
        <Footer />
      </section>
    </div>
  )
}
