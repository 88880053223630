const filterNumbers = (numbers) => {
  return [...new Set(numbers)].sort()
}

const getRangeNumbers = (N, start = 0, step = 1) => {
  return [...Array(N)].map((_, i) => start + i * step)
}

const getRangeNumbersFromTo = ({ from, to }, step) => {
  if (from > to) {
    return []
  }
  return getRangeNumbers(to - from + 1, from, step)
}

export { filterNumbers, getRangeNumbers, getRangeNumbersFromTo }
