"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PluginBase = void 0;
const utils_1 = require("../../utils");
class PluginBase {
    triggerEvent(event, eventCtx) {
        const methodName = (0, utils_1.colonCaseToCamelCase)(event);
        if (this[methodName]) {
            let method = this[methodName];
            method = method.bind(this);
            method(eventCtx);
        }
    }
}
exports.PluginBase = PluginBase;
