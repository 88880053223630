"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UiDefinitionsProcessor = void 0;
const RefDefinitionProcessor_1 = require("./RefDefinitionProcessor");
class UiDefinitionsProcessor extends RefDefinitionProcessor_1.RefDefinitionProcessor {
    constructor() {
        super("uiDefinitions");
    }
}
exports.UiDefinitionsProcessor = UiDefinitionsProcessor;
