"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EventsManager = void 0;
const tslib_1 = require("tslib");
class EventsManager {
    constructor() {
        this.events = new Map();
    }
    addEvent(type, fn) {
        const event = { type, fn };
        if (this.events.has(type)) {
            const events = this.events.get(type);
            events.push(event);
        }
        else {
            this.events.set(type, [event]);
        }
        return this;
    }
    triggerEvent(eventType, eventCtx) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.events.has(eventType)) {
                const events = this.events.get(eventType);
                for (const event of events) {
                    if (event.type === eventType) {
                        const fn = event.fn;
                        yield fn(eventCtx);
                    }
                }
            }
        });
    }
    triggerEvents(eventsType, eventCtx) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            for (const eventType of eventsType) {
                yield this.triggerEvent(eventType, eventCtx);
            }
        });
    }
}
exports.EventsManager = EventsManager;
