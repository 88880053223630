"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AbstractPropertyType = void 0;
class AbstractPropertyType {
    get answerableSelectors() {
        return this._selectors;
    }
    constructor(property) {
        this.property = property;
        this._selectors = [];
    }
    _mapChildrenAnswerableSelectors(props) {
        props.forEach((prop) => {
            if (prop.isAnswerable()) {
                this._selectors.push(prop.UID);
            }
            if (prop.hasChildProps()) {
                this._mapChildrenAnswerableSelectors(prop.childProps());
            }
        });
    }
    mapChildrenAnswerableSelectors() {
        this._selectors = [];
        this._mapChildrenAnswerableSelectors(this.property.childProps());
    }
}
exports.AbstractPropertyType = AbstractPropertyType;
