import React, { useContext } from "react"
import { FormContext } from "../../context/form/formState"
import Message from "../Message/Message"
import useTranslation from "../../hooks/useTranslation"
import styles from "./StepWarning.module.css"

export const StepWarning = ({ sectionUid }) => {
  const { translate } = useTranslation()
  const {
    formValidations: { [sectionUid]: isValid },
    formStats: {
      sections: {
        [sectionUid]: { qualified, valid },
      },
    },
  } = useContext(FormContext)

  if (isValid) {
    return null
  }

  return (
    <Message
      className={styles.StepWarning}
      description={translate("stepWarnings.invalidQuestions.description", { numberOfErrors: qualified - valid })}
      message={translate("stepWarnings.invalidQuestions.message")}
      type="warning"
    />
  )
}
