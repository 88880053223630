"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormSelectors = void 0;
const tslib_1 = require("tslib");
const SelectorResolver_1 = require("./Selector/SelectorResolver");
const ArraySelector_1 = require("./Selector/ArraySelector");
const PropetySelectorsMapper_1 = require("../Schema/Mapper/PropetySelectorsMapper");
const Selector_1 = require("./Selector/Selector");
const Property_1 = require("../Schema/Property/Property");
class FormSelectors {
    get keys() {
        return Object.keys(this.selectors);
    }
    get values() {
        return Object.values(this.selectors);
    }
    get count() {
        return this.keys.length;
    }
    constructor(form) {
        this.form = form;
        this.selectors = {};
        this.selectorResolver = new SelectorResolver_1.SelectorResolver();
        const selectors = new PropetySelectorsMapper_1.PropertySelectorsMapper().mapSelectors(form.schema);
        for (const sel of selectors) {
            const property = form.schema.search(sel);
            if (property) {
                this.addProperty(property);
            }
        }
        this.array = new ArraySelector_1.ArraySelector(this.form);
    }
    mapArraySelectors() {
        this.array.mapSelectors();
    }
    addProperty(property) {
        var _a;
        const newSelector = {
            arrayIndex: property.arrayIndex,
            arrayUID: property.arrayUID,
            belongToArray: property.belongToArray(),
            belongToGroup: property.belongToGroup(),
            belongToSection: property.belongToSection(),
            conditionId: "_",
            defaultValue: property.get("default", null),
            excludedFromStats: property.isExcludedFromStats(),
            hidden: property.isHidden(),
            globalIndex: property.globalIndex,
            groupUID: property.groupUID,
            hasChildProps: property.hasChildProps(),
            hasQualifications: property.has("qualifications"),
            hasValidations: property.has("validations"),
            isAnswerable: property.isAnswerable(),
            isArray: property.type === Property_1.Property.TYPE_ARRAY,
            isGroup: property.isGroup(),
            isNode: property.isNode(),
            isParentQualified: () => this.isParentQualified(property.UID),
            isQualified: () => this.form.qualifications[property.UID],
            isSection: property.isSection(),
            isValid: () => this.form.validations[property.UID],
            linkedEnumChildren: [],
            linkedEnumParent: property.get("linkedEnumParent", ""),
            parentUID: property.parentUID,
            preserveDataOnDisqualification: property.isAnswerable() || property.type === Property_1.Property.TYPE_ARRAY
                ? property.get("preserveDataOnDisqualification", this.form.settings.preserveDataOnDisqualification)
                : false,
            property: () => property,
            sectionUID: property.sectionUID,
            type: property.type,
            UID: property.UID,
        };
        this.selectors[property.UID] = newSelector;
        if (newSelector.linkedEnumParent) {
            let parent = newSelector.linkedEnumParent;
            if (parent.startsWith(Selector_1.Selector.separator)) {
                parent = `${property.parentUID}${parent}`;
                this.selectors[property.UID].linkedEnumParent = parent;
            }
            (_a = this.selectors[parent]) === null || _a === void 0 ? void 0 : _a.linkedEnumChildren.push(property.UID);
        }
    }
    expandArray(arraySelector, times = 1) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            for (let i = 0; i < times; i++) {
                const succeed = yield this.array.expand(arraySelector);
                if (!succeed) {
                    return false;
                }
            }
            return true;
        });
    }
    reduceArray(arraySelector, times = 1) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            for (let i = 0; i < times; i++) {
                const succeed = yield this.array.reduce(arraySelector);
                if (!succeed) {
                    return false;
                }
            }
            return true;
        });
    }
    spliceArray(arraySelector, index) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.array.splice(arraySelector, index);
        });
    }
    resetArray(selector) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const prop = this.get(selector);
            if (prop) {
                const currentLength = this.form.dataSelector.get(selector).length;
                const diff = currentLength - prop.property().arrayMinItems;
                if (diff > 0) {
                    return yield this.reduceArray(selector, diff);
                }
            }
            return false;
        });
    }
    get(selector) {
        return this.selectors[selector];
    }
    has(selector) {
        return !!this.selectors[selector];
    }
    hasAtLeastOneAnswer(selector) {
        const selectors = [];
        if (this.isAnswerable(selector)) {
            selectors.push(selector);
        }
        for (const uid of Object.keys(this.getAnswerableSelectorsInfos())) {
            if (uid.startsWith(`${selector}${Selector_1.Selector.separator}`)) {
                selectors.push(uid);
            }
        }
        for (const sel of selectors) {
            if (this.form.dataSelector.get(sel) !== this.getDefaultValue(sel)) {
                return true;
            }
        }
        return false;
    }
    countAnswerableSelectors() {
        return this.values.filter((s) => s.isAnswerable).length;
    }
    answerableSelectors() {
        return this.getAnswerableSelectorsInfos();
    }
    getAnswerableSelectorsInfos() {
        const answerables = {};
        for (const [sel, info] of Object.entries(this.selectors)) {
            if (info.isAnswerable) {
                answerables[sel] = info;
            }
        }
        return answerables;
    }
    resolveWildcard(selectorsToResolve) {
        return this.selectorResolver.resolve(selectorsToResolve, this.keys);
    }
    updateConditionId(selector, conditionId) {
        if (this.selectors[selector]) {
            this.selectors[selector].conditionId = conditionId;
        }
    }
    getChildrenSelectors(selector) {
        const list = [];
        for (const sel of this.keys) {
            if (sel.startsWith(`${selector}${Selector_1.Selector.separator}`)) {
                list.push(sel);
            }
        }
        return list;
    }
    getParentSelectors(selector) {
        if (!this.has(selector)) {
            return [];
        }
        const list = [];
        let lastSeparatorIndex;
        while ((lastSeparatorIndex = selector.lastIndexOf(Selector_1.Selector.separator)) !== -1) {
            selector = selector.substring(0, lastSeparatorIndex);
            list.unshift(selector);
        }
        return list;
    }
    getChildrenAnswerableSelectors(selector) {
        const list = [];
        for (const sel of this.values) {
            if (sel.isAnswerable && sel.UID.startsWith(`${selector}${Selector_1.Selector.separator}`)) {
                list.push(sel.UID);
            }
        }
        return list;
    }
    getConditionId(selector) {
        var _a;
        return ((_a = this.selectors[selector]) === null || _a === void 0 ? void 0 : _a.conditionId) || "_";
    }
    getDefaultValue(selector) {
        if (!this.has(selector)) {
            return null;
        }
        const sel = this.selectors[selector];
        if (Array.isArray(sel.defaultValue) && sel.linkedEnumParent) {
            const [parentExists, parentValue] = this.form.dataSelector.tryGet(sel.linkedEnumParent);
            if (parentExists) {
                for (const item of sel.defaultValue) {
                    if (item.link === parentValue) {
                        return item.value;
                    }
                }
            }
            return null;
        }
        return sel.defaultValue;
    }
    getLinkedEnumParent(selector) {
        var _a;
        return ((_a = this.selectors[selector]) === null || _a === void 0 ? void 0 : _a.linkedEnumParent) || "";
    }
    getLinkedEnumChildren(selector) {
        var _a;
        return ((_a = this.selectors[selector]) === null || _a === void 0 ? void 0 : _a.linkedEnumChildren) || [];
    }
    isAnswerable(selector) {
        var _a;
        return ((_a = this.selectors[selector]) === null || _a === void 0 ? void 0 : _a.isAnswerable) || false;
    }
    isParentQualified(selector) {
        const parent = this.get(selector).parentUID;
        if (parent !== "") {
            return !this.form.qualifications[parent] ? false : this.isParentQualified(parent);
        }
        return true;
    }
    isParentTypeOf(selector, type) {
        const sel = this.selectors[selector];
        if (sel && sel.parentUID) {
            const parent = this.selectors[sel.parentUID];
            if (parent.type === type) {
                const parentOfParent = this.isParentTypeOf(parent.UID, type);
                return parentOfParent !== false ? parentOfParent : parent.UID;
            }
            return this.isParentTypeOf(sel.parentUID, type);
        }
        return false;
    }
    is(selector, expectations) {
        const sel = this.selectors[selector];
        if (!sel) {
            return false;
        }
        for (const [key, expectedValue] of Object.entries(expectations)) {
            if (sel[key] === undefined || sel[key] !== expectedValue) {
                return false;
            }
        }
        return true;
    }
}
exports.FormSelectors = FormSelectors;
