import React, { useContext } from "react"
import { FormContext } from "../../../context/form/formState"
import { PreviousLabel } from "../../../components/Label/PreviousLabel/PreviousLabel"
import { Button } from "../../../components"

export const PreviousButton = ({ setProperty }) => {
  const {
    form: { navigation },
  } = useContext(FormContext)

  const onClick = () => {
    const previousQualifiedProperty = navigation.conversational.getPreviousQualifiedProperty()

    if (previousQualifiedProperty) {
      navigation.setCurrentSelector(previousQualifiedProperty.UID)
      setProperty(previousQualifiedProperty)
    }
  }

  return (
    <Button
      disabled={navigation.conversational.isCurrentSelectorFirst()}
      id="navigate-to-previous-step"
      label={<PreviousLabel />}
      onClick={onClick}
    />
  )
}
