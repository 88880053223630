"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PropertiesMapper = void 0;
const Property_1 = require("../Property/Property");
class PropertiesMapper {
    map(properties, parentUid, parentGlobalIndex) {
        const propertiesMap = new Map();
        let i = 0;
        for (const [id, def] of Object.entries(properties)) {
            const newGlobalIndex = parentGlobalIndex ? `${parentGlobalIndex}.${i}` : undefined;
            propertiesMap.set(id, new Property_1.Property(id, parentUid, def, newGlobalIndex));
            i++;
        }
        return propertiesMap;
    }
}
exports.PropertiesMapper = PropertiesMapper;
