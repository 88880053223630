import React, { useContext, useEffect } from "react"
import qvValues from "./qvValues"
import { DevPanelContext } from "../../../context/devPanel/devPanelState"
import { FormContext } from "../../../context/form/formState"
import styles from "./QV.module.css"

export const QV = () => {
  const { formQualifications, formValidations, formValidationsErrors } = useContext(FormContext)
  const { filteredQvTable, qvFilters, setQvFilters, setQVTable } = useContext(DevPanelContext)

  const getQvValueFromEvent = (e) => {
    const value = e.target.value
    return ["false", "true"].includes(value) ? value === "true" : undefined
  }
  const handleQvFiltersUidChange = (e) => setQvFilters({ uid: e.target.value })
  const handleQvFiltersQualificationChange = (e) => setQvFilters({ qualification: getQvValueFromEvent(e) })
  const handleQvFiltersValidationChange = (e) => setQvFilters({ validation: getQvValueFromEvent(e) })
  const handleQvFiltersErrorCodeChange = (e) => setQvFilters({ errorCode: e.target.value })

  useEffect(() => {
    const qv = []
    for (let [selector, qualification] of Object.entries(formQualifications)) {
      qv.push({
        selector,
        qualification: qualification,
        validation: formValidations[selector],
        errorCode: formValidationsErrors[selector]?.code ?? "",
      })
    }
    setQVTable(qv)
  }, [formQualifications, formValidations])

  return (
    <table className={styles.QV}>
      <thead>
        <tr>
          <th>
            <fieldset>
              <label htmlFor="qvFilters.uid">Question UID</label>
              <br />
              <input type="text" id="qvFilters.uid" value={qvFilters.uid} onChange={handleQvFiltersUidChange} />
            </fieldset>
          </th>
          <th>
            <fieldset>
              <label htmlFor="qvFilters.qualification">Qualification</label>
              <br />
              <select onChange={handleQvFiltersQualificationChange} value={qvFilters.qualification}>
                <option />
                <option value={true} dangerouslySetInnerHTML={{ __html: qvValues.true }} />
                <option value={false} dangerouslySetInnerHTML={{ __html: qvValues.false }} />
              </select>
            </fieldset>
          </th>
          <th>
            <fieldset>
              <label htmlFor="qvFilters.validation">Validation</label>
              <br />
              <select onChange={handleQvFiltersValidationChange} value={qvFilters.validation}>
                <option />
                <option value={true} dangerouslySetInnerHTML={{ __html: qvValues.true }} />
                <option value={false} dangerouslySetInnerHTML={{ __html: qvValues.false }} />
              </select>
            </fieldset>
          </th>
          <th>
            <fieldset>
              <label htmlFor="qvFilters.errorCode">Error code</label>
              <br />
              <input
                type="text"
                id="qvFilters.errorCode"
                value={qvFilters.errorCode}
                onChange={handleQvFiltersErrorCodeChange}
              />
            </fieldset>
          </th>
        </tr>
      </thead>
      <tbody>
        {filteredQvTable.map(({ selector, qualification, validation, errorCode }) => (
          <tr key={selector}>
            <td>{selector}</td>
            <td dangerouslySetInnerHTML={{ __html: qvValues[qualification] }} />
            <td dangerouslySetInnerHTML={{ __html: qvValues[validation] }} />
            <td>{errorCode}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}
