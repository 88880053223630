import React, { SyntheticEvent, useContext } from "react"
import { ButtonTypes } from "@akinoxsolutions/gerudo-ui/dist/Button/ButtonPropTypes"
import { ConfigContext } from "../../context/config/configState"
import { FormContext } from "../../context/form/formState"
import useTranslation from "../../hooks/useTranslation"
import { MessagingContext } from "../../context/messaging/messagingState"
import { CustomEventsService } from "../../services/CustomEventsService"
import { CustomEventInterface } from "../interfaces"
import { Button } from ".."

import { ValueCompareOptionsInterface } from "@akinoxsolutions/formol"

export interface CustomFormActionInterface {
  label: string
  buttonType?: ButtonTypes
  customEvents?: CustomEventInterface[]
  conditionalDisplay?: CustomFormActionsValidationRulesInterface
  conditionalEnable?: CustomFormActionsValidationRulesInterface
}

interface CustomFormActionsValidationRulesInterface {
  asSubmitButton?: boolean
  formPass?: Record<never, never>
  isReadOnlyMode?: boolean
  valueCompare?: ValueCompareOptionsInterface | ValueCompareOptionsInterface[]
}

const CustomFormActions = (): JSX.Element => {
  const { translate } = useTranslation()
  const { readOnlyMode } = useContext(ConfigContext)
  const { form, formOptions } = useContext(FormContext)
  const { postCustomEvent } = useContext(MessagingContext)
  const { customFormActions = [] }: { customFormActions: CustomFormActionInterface[] } = form?.schema?.def?.ui || {}
  const isReadOnly = readOnlyMode || (formOptions.readOnlyMode && readOnlyMode !== false)

  const validate = (validationRules: CustomFormActionsValidationRulesInterface = {}): boolean => {
    const { asSubmitButton, formPass, isReadOnlyMode, valueCompare } = validationRules

    if (valueCompare) {
      const valueCompares: ValueCompareOptionsInterface[] = Array.isArray(valueCompare) ? valueCompare : [valueCompare]
      for (const vc of valueCompares) {
        if (vc.selectors && vc.should === "equal") {
          for (const selector of vc.selectors) {
            if (form?.dataSelector.get(selector) !== vc.value) {
              return false
            }
          }
        } else {
          throw new Error('Only `should: "equal"` option is supported inside `valueCompare` for custom form actions')
        }
      }
    }
    if (asSubmitButton && (!form?.pass || isReadOnly)) {
      return false
    }
    if (formPass && !form?.pass) {
      return false
    }
    if ((isReadOnlyMode === true && !isReadOnly) || (isReadOnlyMode === false && isReadOnly)) {
      return false
    }
    return true
  }

  return (
    <>
      {customFormActions.map((action, i) => {
        const { label, buttonType, customEvents, conditionalDisplay, conditionalEnable } = action
        const isVisible = validate(conditionalDisplay)
        const isEnabled = validate(conditionalEnable)
        let onClick: ((e: SyntheticEvent) => void) | undefined = undefined
        if (form) {
          onClick = new CustomEventsService(form, customEvents).getEventHandler("onClick", postCustomEvent)
        }
        return (
          isVisible && (
            <Button
              key={i}
              id={`custom-action-btn-${i}`}
              label={translate(label)}
              type={buttonType}
              disabled={!isEnabled}
              onClick={onClick}
            />
          )
        )
      })}
    </>
  )
}

export default CustomFormActions
