"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ScoringValidator = void 0;
const tslib_1 = require("tslib");
const AbstractValidator_1 = require("../../Form/Validator/AbstractValidator");
class ScoringValidator extends AbstractValidator_1.AbstractValidator {
    constructor(options, type) {
        super(type);
        this.options = options;
        this.configValidated = false;
        const { metaKey = "score" } = options;
        this.metaKey = metaKey;
        this.genericError = this.formatResult([false, { code: "INVALID_SELECTOR_SCORING", context: options }], options);
    }
    validate({ form, prop }) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const selectors = this.options.selectors || [prop.UID];
            if (!this.configValidated) {
                const context = {
                    selector: prop.UID,
                    options: this.options,
                };
                if (!form.meta[this.metaKey]) {
                    return this.formatResult([false, { code: "SCORING_META_KEY_NOT_FOUND", context }], this.options);
                }
                for (const selector of selectors) {
                    if (!form.meta[this.metaKey].detailsByQuestion[selector]) {
                        return this.formatResult([false, { code: "SCORING_META_KEY_HAS_NO_DETAILS", context }], this.options);
                    }
                }
                if (!this[this.options.should]) {
                    return this.formatResult([false, { code: "INVALID_SHOULD_OPTION", context }], this.options);
                }
                this.shouldMethod = this[this.options.should].bind(this);
                this.configValidated = true;
            }
            return this.shouldMethod(this.options.value, form.meta[this.metaKey].detailsByQuestion, this.options.selectors || [prop.UID]);
        });
    }
    equal(v, details, selectors) {
        for (const selector of selectors) {
            if (details[selector].current !== v) {
                return this.genericError;
            }
        }
        return [true, null];
    }
    notEqual(v, details, selectors) {
        for (const selector of selectors) {
            if (details[selector].current === v) {
                return this.genericError;
            }
        }
        return [true, null];
    }
    equalOneOf(v, details, selectors) {
        for (const selector of selectors) {
            if (!v.includes(details[selector].current)) {
                return this.genericError;
            }
        }
        return [true, null];
    }
    notEqualOneOf(v, details, selectors) {
        for (const selector of selectors) {
            if (v.includes(details[selector].current)) {
                return this.genericError;
            }
        }
        return [true, null];
    }
    greaterThan(v, details, selectors) {
        for (const selector of selectors) {
            if (details[selector].current <= v) {
                return this.genericError;
            }
        }
        return [true, null];
    }
    greaterThanOrEqual(v, details, selectors) {
        for (const selector of selectors) {
            if (details[selector].current < v) {
                return this.genericError;
            }
        }
        return [true, null];
    }
    lowerThan(v, details, selectors) {
        for (const selector of selectors) {
            if (details[selector].current >= v) {
                return this.genericError;
            }
        }
        return [true, null];
    }
    lowerThanOrEqual(v, details, selectors) {
        for (const selector of selectors) {
            if (details[selector].current > v) {
                return this.genericError;
            }
        }
        return [true, null];
    }
    totalOf(details, selectors) {
        let total = 0;
        for (const selector of selectors) {
            total += details[selector].current;
        }
        return total;
    }
    totalEqual(v, details, selectors) {
        return v === this.totalOf(details, selectors) ? [true, null] : this.genericError;
    }
    totalNotEqual(v, details, selectors) {
        return v !== this.totalOf(details, selectors) ? [true, null] : this.genericError;
    }
    totalGreaterThan(v, details, selectors) {
        return this.totalOf(details, selectors) > v ? [true, null] : this.genericError;
    }
    totalGreaterThanOrEqual(v, details, selectors) {
        return this.totalOf(details, selectors) >= v ? [true, null] : this.genericError;
    }
    totalLowerThan(v, details, selectors) {
        return this.totalOf(details, selectors) < v ? [true, null] : this.genericError;
    }
    totalLowerThanOrEqual(v, details, selectors) {
        return this.totalOf(details, selectors) <= v ? [true, null] : this.genericError;
    }
}
exports.ScoringValidator = ScoringValidator;
