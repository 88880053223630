"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Property = void 0;
const PropertiesMapper_1 = require("../Mapper/PropertiesMapper");
const PropertyGroup_1 = require("./PropertyGroup");
const PropetySelectorsMapper_1 = require("../Mapper/PropetySelectorsMapper");
const Selector_1 = require("../../Form/Selector/Selector");
class Property {
    constructor(id, parentUid, def, globalIndex) {
        this.id = id;
        this.def = def;
        this.childrenProperties = new Map();
        this.arrayPropertiesDef = {};
        this.hasArrayValidItems = false;
        this._arrayMinItems = 1;
        this._arrayMaxItems = null;
        this._uid = "";
        this._parentUid = "";
        this._groupUid = "";
        this._sectionUid = "";
        this._arrayUid = "";
        this._globalIndex = "0";
        this._arrayIndex = -1;
        this._globalIndex = !globalIndex ? `${++Property.globalIndex}` : globalIndex;
        if (parentUid) {
            this._parentUid = parentUid;
            this._uid = `${parentUid}${Selector_1.Selector.separator}${id}`;
        }
        else {
            this._uid = id;
        }
        if (def) {
            const propertiesMapper = new PropertiesMapper_1.PropertiesMapper();
            const childParentUid = this._uid === "root" ? "" : this._uid;
            if (def.properties) {
                this.childrenProperties = propertiesMapper.map(def.properties, childParentUid, this._globalIndex);
            }
            else if (this.type === Property.TYPE_ARRAY) {
                const items = this.get("items", {});
                this.arrayPropertiesDef = items;
                this.hasArrayValidItems = Object.keys(items).length > 0;
                if (def.minItems !== undefined) {
                    this._arrayMinItems = def.minItems;
                }
                if (def.maxItems !== undefined) {
                    this._arrayMaxItems = def.maxItems;
                }
            }
        }
    }
    get globalIndex() {
        return this._globalIndex;
    }
    set globalIndex(newGlobalIndex) {
        this._globalIndex = newGlobalIndex;
    }
    get UID() {
        return this._uid;
    }
    get parentUID() {
        return this._parentUid;
    }
    set parentUID(newParentUID) {
        this._uid = this._uid.replace(`${this._parentUid}${Selector_1.Selector.separator}`, `${newParentUID}${Selector_1.Selector.separator}`);
        this._parentUid = newParentUID;
    }
    get groupUID() {
        return this._groupUid;
    }
    set groupUID(newUid) {
        this._groupUid = newUid;
    }
    get sectionUID() {
        return this._sectionUid;
    }
    set sectionUID(newUid) {
        this._sectionUid = newUid;
    }
    get arrayUID() {
        return this._arrayUid;
    }
    set arrayUID(newUid) {
        this._arrayUid = newUid;
    }
    get type() {
        return this.def.type || Property.TYPE_NULL;
    }
    get arrayIndex() {
        return this._arrayIndex;
    }
    set arrayIndex(newIndex) {
        this._arrayIndex = newIndex;
    }
    get arrayMinItems() {
        return this._arrayMinItems;
    }
    set arrayMinItems(newVal) {
        this._arrayMinItems = newVal;
    }
    get arrayMaxItems() {
        return this._arrayMaxItems;
    }
    set arrayMaxItems(newVal) {
        this._arrayMaxItems = newVal;
    }
    get(key, defaultValue = undefined) {
        if (Object.prototype.hasOwnProperty.call(this.def, key)) {
            return this.def[key];
        }
        return defaultValue;
    }
    has(key) {
        return Object.prototype.hasOwnProperty.call(this.def, key);
    }
    addChildProp(prop) {
        this.childrenProperties.set(prop.id, prop);
    }
    childProp(id) {
        return this.childrenProperties.get(id) || null;
    }
    childProps() {
        return this.childrenProperties;
    }
    hasChildProp(id) {
        return this.childrenProperties.has(id);
    }
    hasChildProps() {
        return this.childrenProperties.size > 0;
    }
    arrayExpand() {
        if (this.hasArrayValidItems) {
            if (this.arrayMaxItems && this.childrenProperties.size >= this.arrayMaxItems) {
                return null;
            }
            const newKey = this.childrenProperties.size;
            const newProperty = new Property(`${newKey}`, this._uid, Object.assign({}, this.arrayPropertiesDef), `${this.globalIndex}.${newKey}`);
            newProperty.arrayIndex = newKey;
            if (this.arrayPropertiesDef["group"] === true) {
                new PropertyGroup_1.PropertyGroup(newProperty);
            }
            if (this.belongToSection()) {
                newProperty.sectionUID = this.sectionUID;
            }
            if (this.belongToGroup()) {
                newProperty.groupUID = this.groupUID;
            }
            this.childrenProperties.set(`${newKey}`, newProperty);
            return newProperty;
        }
        return null;
    }
    arrayReduce() {
        if (this.hasArrayValidItems) {
            if (this.childrenProperties.size <= this.arrayMinItems) {
                return null;
            }
            const key = this.childrenProperties.size - 1;
            const deletedProp = this.childrenProperties.get(`${key}`);
            this.childrenProperties.delete(`${key}`);
            return deletedProp;
        }
        return null;
    }
    arraySplice(index) {
        if (this.hasArrayValidItems) {
            if (this.childrenProperties.size <= this.arrayMinItems) {
                return null;
            }
            const deletedProp = this.childrenProperties.get(`${index}`);
            if (!deletedProp) {
                return null;
            }
            const result = {
                deletedProperty: deletedProp,
                droppedSelectors: [],
            };
            const droppedProperty = this.childrenProperties.get(`${this.childrenProperties.size - 1}`);
            result.droppedSelectors = new PropetySelectorsMapper_1.PropertySelectorsMapper().mapSelectors(droppedProperty);
            result.droppedSelectors.push(droppedProperty.UID);
            this.childrenProperties.delete(`${index}`);
            let nextIndex = index + 1;
            while (this.hasChildProp(`${nextIndex}`)) {
                const p = this.childProp(`${nextIndex}`);
                const renamedIndex = nextIndex - 1;
                p.renameID(`${renamedIndex}`);
                p.arrayIndex = renamedIndex;
                p.globalIndex = `${this._globalIndex}.${renamedIndex}`;
                this.childrenProperties.set(`${renamedIndex}`, p);
                this.childrenProperties.delete(`${nextIndex}`);
                nextIndex++;
            }
            return result;
        }
        return null;
    }
    isAnswerable() {
        return Property.ANSWERABLE_TYPES.includes(this.type);
    }
    isNode() {
        return Property.NODE_TYPES.includes(this.type);
    }
    isGroup() {
        return this.def.group || false;
    }
    isExcludedFromStats() {
        const excludedFromStats = this.get("excludedFromStats", false);
        const hidden = this.isHidden();
        return excludedFromStats || hidden;
    }
    isHidden() {
        var _a, _b;
        return ((_b = (_a = this.def) === null || _a === void 0 ? void 0 : _a.ui) === null || _b === void 0 ? void 0 : _b.type) == "hidden" || false;
    }
    isSection() {
        return this.def.section || false;
    }
    belongToGroup() {
        return this.groupUID !== "";
    }
    belongToSection() {
        return this.sectionUID !== "";
    }
    belongToArray() {
        return this.arrayUID !== "";
    }
    renameID(newID) {
        this.id = newID;
        const splittedUID = this._uid.split(Selector_1.Selector.separator);
        splittedUID[splittedUID.length - 1] = newID;
        this._uid = splittedUID.join(Selector_1.Selector.separator);
        if (this.hasChildProps()) {
            for (const child of this.childrenProperties.values()) {
                this.renameParentID(this._uid, child);
            }
        }
    }
    renameParentID(newParentUID, prop) {
        prop.parentUID = newParentUID;
        if (prop.hasChildProps()) {
            for (const child of prop.childProps().values()) {
                this.renameParentID(prop.UID, child);
            }
        }
    }
}
exports.Property = Property;
Property.TYPE_ARRAY = "array";
Property.TYPE_BOOLEAN = "boolean";
Property.TYPE_INTEGER = "integer";
Property.TYPE_NULL = "null";
Property.TYPE_NUMBER = "number";
Property.TYPE_OBJECT = "object";
Property.TYPE_RESOURCE = "resource";
Property.TYPE_STRING = "string";
Property.globalIndex = 0;
Property.ANSWERABLE_TYPES = [
    Property.TYPE_BOOLEAN,
    Property.TYPE_INTEGER,
    Property.TYPE_NUMBER,
    Property.TYPE_RESOURCE,
    Property.TYPE_STRING,
];
Property.NODE_TYPES = [Property.TYPE_ARRAY, Property.TYPE_OBJECT];
Property.ALL_TYPES = [
    Property.TYPE_ARRAY,
    Property.TYPE_BOOLEAN,
    Property.TYPE_INTEGER,
    Property.TYPE_NULL,
    Property.TYPE_NUMBER,
    Property.TYPE_OBJECT,
    Property.TYPE_RESOURCE,
    Property.TYPE_STRING,
];
