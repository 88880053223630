"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PluginFactory = void 0;
const PluginsList_1 = require("./PluginsList");
const Logger_1 = require("../Logger");
class PluginFactory {
    create(plugin, options) {
        if (!PluginsList_1.plugins[plugin]) {
            Logger_1.Logger.log(`cannot find plugin ${plugin}`);
            return null;
        }
        return PluginsList_1.plugins[plugin](options);
    }
}
exports.PluginFactory = PluginFactory;
