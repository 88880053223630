import React, { createContext, ReactElement } from "react"
import useMessagingAction from "./useMessagingAction"
import { MessagingProviderPropsInterface, UseMessagingActionInterface } from "./messagingInterfaces"

export const initialState = {
  context: {
    containerId: null,
  },
}

export const MessagingContext = createContext<UseMessagingActionInterface>(initialState as never)

export const MessagingProvider = ({ children, containerId }: MessagingProviderPropsInterface): ReactElement => {
  const [state] = useMessagingAction({ containerId })

  return <MessagingContext.Provider value={state}>{children}</MessagingContext.Provider>
}
