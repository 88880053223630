import React, { useContext, useEffect, useState } from "react"
import Tabs from "antd/lib/tabs"
import { Logger } from "@akinoxsolutions/formol"
import { ConfigContext } from "../../../context/config/configState"
import { DevPanelContext } from "../../../context/devPanel/devPanelState"
import { Editor } from "../Editor/Editor"
import { FormContext } from "../../../context/form/formState"
import { getAllResources } from "../../../i18n"
import { prettifyJson } from "../../../utils/devUtils"
import { QV } from "../QV/QV"
import styles from "./PanelContent.module.css"

export const PanelContent = () => {
  const { TabPane } = Tabs
  const { numberOfErrors } = useContext(ConfigContext)
  const { form, formStatus } = useContext(FormContext)
  const { customEvents, isEditable, schema, schemaErrors, schemaIsValid, setSchema, setTabActiveKey, tabActiveKey } =
    useContext(DevPanelContext)
  const [loggerStack, setLoggerStack] = useState(Logger.stack)
  const formIsLoaded = formStatus === "SUCCESSFULLY_LOADED"

  const handleSchemaChange = (newSchema) => {
    if (isEditable) {
      setSchema(newSchema)
    }
  }

  useEffect(() => {
    if (formStatus === "SUCCESSFULLY_LOADED" && !isEditable) {
      setSchema(prettifyJson(form.schema.def))
    }
  }, [formStatus, isEditable])

  useEffect(() => {
    setLoggerStack(Logger.stack)
  }, [Logger.stack, numberOfErrors])

  let index = 0

  return (
    <Tabs activeKey={tabActiveKey} className={styles.PanelContent} onChange={setTabActiveKey} tabPosition="top">
      <TabPane tab="Schema" key={index++}>
        <Editor
          isValid={schemaIsValid}
          errorMessage={schemaErrors}
          onChange={handleSchemaChange}
          readOnly={!isEditable}
          value={schema}
        />
      </TabPane>
      <TabPane tab="Custom Events" key={index++}>
        <Editor readOnly value={prettifyJson(customEvents)} />
      </TabPane>
      <TabPane tab="Translations" key={index++}>
        <Editor readOnly value={prettifyJson(getAllResources())} />
      </TabPane>
      <TabPane tab="Data" key={index++}>
        <Editor readOnly value={prettifyJson(formIsLoaded ? form.data : {})} />
      </TabPane>
      <TabPane tab="Meta" key={index++}>
        <Editor readOnly value={prettifyJson(formIsLoaded ? form.meta : {})} />
      </TabPane>
      <TabPane tab="Stats" key={index++}>
        <Editor readOnly value={prettifyJson(formIsLoaded ? form.stats : {})} />
      </TabPane>
      <TabPane tab="QV" key={index++} className={styles.Scrollable}>
        <QV />
      </TabPane>
      <TabPane tab={`Logger (${loggerStack.length})`} key={index++}>
        <Editor readOnly value={prettifyJson(loggerStack)} />
      </TabPane>
    </Tabs>
  )
}
