import React, { useContext } from "react"
import useTranslation from "../../hooks/useTranslation"
import { FormContext } from "../../context/form/formState"
import { getStyle } from "../../utils/uiUtils"
import { Paragraph } from "../Typography/Paragraph/Paragraph"
import { Html } from "../index"
import styles from "./Footer.module.css"

export const Footer = () => {
  const { translate } = useTranslation()
  const { form } = useContext(FormContext)
  const { footer: { lines: footerLines, ui = {} } = {} } = form?.schema?.def?.ui || {}

  if (!footerLines) return null

  return (
    <footer className={styles.Footer} style={getStyle(ui)}>
      {footerLines.map((key) => (
        <Paragraph key={key} type="secondary">
          <Html content={translate(key)} />
        </Paragraph>
      ))}
    </footer>
  )
}
