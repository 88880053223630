"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SelectorEvents = void 0;
const tslib_1 = require("tslib");
const Events_1 = require("./Events");
const Logger_1 = require("../../Logger");
class SelectorEvents {
    static typeNameToVarName(eventType) {
        switch (eventType) {
            case Events_1.Events.DataQualification:
                return "dataQualification";
            case Events_1.Events.DataValidation:
                return "dataValidation";
            case Events_1.Events.DataUpdated:
                return "dataUpdated";
        }
    }
    static executeEvent(selector, event, eventCtx) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let answer = event.fn(eventCtx);
            const answerObject = answer;
            if (answerObject && answerObject["then"]) {
                answer = yield answer;
            }
            if (event.type === Events_1.Events.DataUpdated) {
                return null;
            }
            let result;
            let errorContent = null;
            if (answer instanceof Array) {
                result = answer[0];
                errorContent = answer[1];
            }
            else if (typeof answer === "boolean") {
                result = answer;
            }
            else {
                Logger_1.Logger.log(`invalid event answer for ${selector} (type > ${event.type})`);
                return null;
            }
            if (event.type === Events_1.Events.DataQualification) {
                eventCtx.form.qualificationsErrors[selector] = errorContent;
                yield eventCtx.form.updateQualification(selector, result);
            }
            else if (event.type === Events_1.Events.DataValidation) {
                eventCtx.form.validationsErrors[selector] = errorContent;
                eventCtx.form.validations[selector] = result;
            }
            return answer;
        });
    }
}
exports.SelectorEvents = SelectorEvents;
