"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GroupsNodes = void 0;
class GroupsNodes {
    constructor(form) {
        this.form = form;
    }
    isValid(selector) {
        var _a;
        if (this.form.schema.nodes.groups.has(selector) && this.form.qualifications[selector]) {
            const childrenSelectors = (_a = this.form.schema.nodes.groups.get(selector)) === null || _a === void 0 ? void 0 : _a.answerableSelectors;
            for (const sel of childrenSelectors) {
                if (this.form.qualifications[sel] && !this.form.validations[sel]) {
                    return false;
                }
            }
            return true;
        }
        return false;
    }
    updateValidations() {
        for (const group of this.form.schema.nodes.groups.values()) {
            this.form.validations[group.property.UID] = this.isValid(group.property.UID);
        }
    }
    getValidationErrors(selector) {
        var _a;
        const errors = {};
        if (this.form.schema.nodes.groups.has(selector)) {
            const childrenSelectors = (_a = this.form.schema.nodes.groups.get(selector)) === null || _a === void 0 ? void 0 : _a.answerableSelectors;
            for (const sel of childrenSelectors) {
                if (this.form.validations[sel] !== null && this.form.validationsErrors[sel] !== null) {
                    errors[sel] = this.form.validationsErrors[sel];
                }
            }
        }
        return errors;
    }
    mapGroupChildrenAncestors(selector) {
        const groupProp = this.form.schema.nodes.groups.get(selector);
        if (groupProp) {
            groupProp.mapChildrenAncestors();
        }
    }
    mapGroupChildrenAnswerableSelectors(selector) {
        const groupProp = this.form.schema.nodes.groups.get(selector);
        if (groupProp) {
            groupProp.mapChildrenAnswerableSelectors();
        }
    }
    updateGroupSelectors(selector) {
        this.mapGroupChildrenAncestors(selector);
        this.mapGroupChildrenAnswerableSelectors(selector);
    }
}
exports.GroupsNodes = GroupsNodes;
