import { version as libVersion } from "@akinoxsolutions/formol"

const changeLangTo = (language = "fr") => {
  window.postMessage({ type: "DYNAMIC_FORM_CHANGE_LANGUAGE", language }, "*")
}

const isValidJson = (data) => {
  try {
    JSON.parse(data)
    return [true, null]
  } catch (e) {
    const error = (e + "").split("at JSON.parse")[0]
    return [false, error]
  }
}

const loadJson = (payload, { devMode = true, ...opts } = {}) => {
  window.postMessage({ type: "DYNAMIC_FORM_PAYLOAD", payload, devMode, ...opts }, "*")
}

const prettifyJson = (json) => {
  return JSON.stringify(json, null, "\t")
}

const prettifyJsonString = (jsonString) => {
  return prettifyJson(JSON.parse(jsonString))
}

const versions = () => ({
  sdk: process.env.REACT_APP_SDK_VERSION,
  lib: libVersion,
})

export { changeLangTo, isValidJson, loadJson, prettifyJson, prettifyJsonString, versions }
