"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormNotPassValidator = void 0;
const tslib_1 = require("tslib");
const AbstractValidator_1 = require("./AbstractValidator");
class FormNotPassValidator extends AbstractValidator_1.AbstractValidator {
    constructor(options, type) {
        super(type);
        this.options = options;
    }
    validate({ form }) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield form.compileStats();
            if (!form.passHandler(form)) {
                return [true, null];
            }
            return this.formatResult([false, { code: "FORM_NOT_PASS_FAILED" }], this.options);
        });
    }
}
exports.FormNotPassValidator = FormNotPassValidator;
