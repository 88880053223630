import React, { SyntheticEvent, useContext } from "react"
import { Link as GerudoLink, LinkProps as GerudoLinkProps } from "@akinoxsolutions/gerudo-ui/dist/Link"
import useTranslation from "../../hooks/useTranslation"
import { FormContext } from "../../context/form/formState"
import { UseFormActionInterface } from "../../context/form/useFormAction"
import { Html } from "../index"
import styles from "./Link.module.css"

export interface LinkPropsInterface extends GerudoLinkProps {
  href?: string
  icon?: unknown
  label: string
  onClick?: (e: SyntheticEvent) => unknown
  onClickMiscHandlerKey?: string
  title?: string
}

export const Link = ({
  className,
  disabled,
  href,
  icon,
  label,
  onClick,
  onClickMiscHandlerKey,
  title,
  ...otherProps
}: LinkPropsInterface) => {
  const { form }: UseFormActionInterface = useContext(FormContext)
  const { translate } = useTranslation()
  const htmlProps = {
    content: translate(label),
  }

  const onClickHandler = (event: SyntheticEvent) => {
    if (disabled) {
      event.preventDefault()
      return null
    }

    if (onClickMiscHandlerKey && form?.miscHandlers[onClickMiscHandlerKey]) {
      form.miscHandlers[onClickMiscHandlerKey](event as any) // eslint-disable-line @typescript-eslint/no-explicit-any
    }

    if (onClick) {
      onClick(event)
    }
  }

  const classNames: string[] = [styles.Link]
  if (className) {
    classNames.push(className)
  }

  return (
    <GerudoLink
      className={classNames.join(" ")}
      disabled={Boolean(disabled)}
      href={href ? translate(href) : "#"}
      onClick={onClickHandler}
      title={translate(title)}
      {...otherProps}
    >
      {icon}
      <Html {...htmlProps} />
    </GerudoLink>
  )
}

export default Link
