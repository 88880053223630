"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormEvents = void 0;
const tslib_1 = require("tslib");
const EventsManager_1 = require("./Event/EventsManager");
const SelectorEventsManager_1 = require("./Event/SelectorEventsManager");
const Events_1 = require("./Event/Events");
class FormEvents {
    constructor(form) {
        this.form = form;
        this.eventsManager = new EventsManager_1.EventsManager();
        this.selectorEventsManager = new SelectorEventsManager_1.SelectorEventsManager(form);
    }
    addEvent(type, fn) {
        this.eventsManager.addEvent(type, fn);
        return this;
    }
    addEventTo(selector, type, fn, dependencies = []) {
        this.selectorEventsManager.addEvent(selector, type, fn, dependencies);
        return this;
    }
    buildCtx(selector, oldData) {
        const [exists, selectorValue] = this.form.dataSelector.tryGet(selector);
        const data = exists ? selectorValue : null;
        return {
            form: this.form,
            data: data,
            oldData: oldData ? oldData : data,
            selector,
        };
    }
    buildBasicCtx() {
        return {
            form: this.form,
            data: "",
            oldData: "",
            selector: "",
        };
    }
    refresh(ignoreSelectors) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            for (const selector of this.selectorEventsManager.eventsSelectors) {
                if (!ignoreSelectors.includes(selector)) {
                    yield this.selectorEventsManager.triggerEvents(selector, [Events_1.Events.DataQualification, Events_1.Events.DataValidation], this.buildCtx(selector));
                }
            }
        });
    }
    refreshAll() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            for (const selector of this.form.selectors.keys) {
                yield this.selectorEventsManager.triggerEvents(selector, [Events_1.Events.DataQualification, Events_1.Events.DataValidation], this.buildCtx(selector));
            }
        });
    }
    triggerEvent(eventType, eventCtx) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.eventsManager.triggerEvent(eventType, eventCtx);
            return this;
        });
    }
    triggerEvents(eventsType, eventCtx) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.eventsManager.triggerEvents(eventsType, eventCtx);
            return this;
        });
    }
    triggerSelectorEvent(selector, eventType, eventCtx) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!eventCtx) {
                eventCtx = this.buildCtx(selector);
            }
            yield this.selectorEventsManager.triggerEvent(selector, eventType, eventCtx);
            return this;
        });
    }
    triggerSelectorEvents(selector, eventsType, eventCtx) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!eventCtx) {
                eventCtx = this.buildCtx(selector);
            }
            yield this.selectorEventsManager.triggerEvents(selector, eventsType, eventCtx);
            return this;
        });
    }
}
exports.FormEvents = FormEvents;
