import React, { useContext } from "react"
import { FormContext } from "../../../context/form/formState"
import { NavigationWarning } from "../../../components/NavigationWarning/NavigationWarning"
import { NextButton } from "./NextButton"
import { PreviousButton } from "./PreviousButton"
import { SubmitButton } from "../../../components"
import styles from "./Navigation.module.css"

export const Navigation = ({ property, setProperty }) => {
  const {
    form: {
      navigation: { conversational },
    },
  } = useContext(FormContext)

  return (
    <section id="conversational-navigation" className={styles.Navigation}>
      <PreviousButton setProperty={setProperty} />
      <NavigationWarning className={styles.NavigationWarning} />
      {conversational.isCurrentSelectorLast() ? (
        <SubmitButton />
      ) : (
        <NextButton property={property} setProperty={setProperty} />
      )}
    </section>
  )
}
