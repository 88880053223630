"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PropertySection = void 0;
const AbstractPropertyType_1 = require("./AbstractPropertyType");
class PropertySection extends AbstractPropertyType_1.AbstractPropertyType {
    constructor(property) {
        super(property);
        this._hasChildrenSections = false;
        this.mapChildrenAncestors();
        this.mapChildrenAnswerableSelectors();
    }
    _mapChildrenAncestors(props, sectionUID) {
        props.forEach((prop) => {
            prop.sectionUID = sectionUID;
            if (prop.isSection()) {
                this._hasChildrenSections = true;
            }
            if (prop.hasChildProps()) {
                this._mapChildrenAncestors(prop.childProps(), sectionUID);
            }
        });
    }
    mapChildrenAncestors() {
        this._hasChildrenSections = false;
        this._mapChildrenAncestors(this.property.childProps(), this.property.UID);
    }
    hasChildrenSections() {
        return this._hasChildrenSections;
    }
}
exports.PropertySection = PropertySection;
