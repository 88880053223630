"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SchemaArraysMapper = void 0;
const Property_1 = require("../Property/Property");
const PropertyArray_1 = require("../Property/PropertyArray");
class SchemaArraysMapper {
    map(schema) {
        return this._map(new Map(), schema.childProps());
    }
    _map(arrays, props) {
        props.forEach((prop) => {
            if (prop.type === Property_1.Property.TYPE_ARRAY) {
                arrays.set(prop.UID, new PropertyArray_1.PropertyArray(prop));
            }
            if (prop.hasChildProps()) {
                arrays = new Map([
                    ...arrays,
                    ...this._map(new Map(), prop.childProps()),
                ]);
            }
        });
        return arrays;
    }
}
exports.SchemaArraysMapper = SchemaArraysMapper;
