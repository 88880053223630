"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SectionsNodes = void 0;
const tslib_1 = require("tslib");
const Events_1 = require("../Event/Events");
class SectionsNodes {
    constructor(form) {
        this.form = form;
        this.viewedSections = new Set();
    }
    isViewed(selector) {
        return this.viewedSections.has(selector);
    }
    addViewedSection(selector) {
        if (!this.viewedSections.has(selector)) {
            this.viewedSections.add(selector);
            this.form.meta.viewedSections = [...this.viewedSections];
        }
    }
    deleteViewedSection(selector) {
        if (this.viewedSections.has(selector)) {
            this.viewedSections.delete(selector);
            this.form.meta.viewedSections = [...this.viewedSections];
        }
    }
    setViewedSections(viewedSections) {
        this.viewedSections = new Set([...this.viewedSections, ...viewedSections]);
        this.form.meta.viewedSections = [...this.viewedSections];
    }
    getViewedSections() {
        return this.viewedSections;
    }
    isValid(selector) {
        var _a;
        if (this.form.qualifications[selector] && this.form.schema.nodes.sections.has(selector)) {
            if (this.form.events.selectorEventsManager.hasValidations(selector)) {
                return this.form.validations[selector];
            }
            const childrenSelectors = (_a = this.form.schema.nodes.sections.get(selector)) === null || _a === void 0 ? void 0 : _a.answerableSelectors;
            for (const sel of childrenSelectors) {
                if (this.form.qualifications[sel] && !this.form.validations[sel]) {
                    return false;
                }
            }
            return true;
        }
        return false;
    }
    updateValidations() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            for (const section of this.form.schema.nodes.sections.values()) {
                if (this.form.events.selectorEventsManager.hasValidations(section.property.UID)) {
                    yield this.form.events.triggerSelectorEvent(section.property.UID, Events_1.Events.DataValidation, this.form.events.buildCtx(section.property.UID));
                }
                else {
                    this.form.validations[section.property.UID] = this.isValid(section.property.UID);
                }
            }
        });
    }
    getValidationErrors(selector) {
        var _a;
        const errors = {};
        if (this.form.schema.nodes.sections.has(selector)) {
            const childrenSelectors = (_a = this.form.schema.nodes.sections.get(selector)) === null || _a === void 0 ? void 0 : _a.answerableSelectors;
            for (const sel of childrenSelectors) {
                if (this.form.validations[sel] === false && this.form.validationsErrors[sel] !== null) {
                    errors[sel] = this.form.validationsErrors[sel];
                }
            }
        }
        return errors;
    }
    mapSectionChildrenAncestors(selector) {
        const sectionProp = this.form.schema.nodes.sections.get(selector);
        if (sectionProp) {
            sectionProp.mapChildrenAncestors();
        }
    }
    mapSectionChildrenAnswerableSelectors(selector) {
        const sectionProp = this.form.schema.nodes.sections.get(selector);
        if (sectionProp) {
            sectionProp.mapChildrenAnswerableSelectors();
        }
    }
    updateSectionSelectors(selector) {
        this.mapSectionChildrenAncestors(selector);
        this.mapSectionChildrenAnswerableSelectors(selector);
    }
}
exports.SectionsNodes = SectionsNodes;
