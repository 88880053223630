"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RangeNumberValidator = void 0;
const tslib_1 = require("tslib");
const AbstractValidator_1 = require("./AbstractValidator");
class RangeNumberValidator extends AbstractValidator_1.AbstractValidator {
    constructor(options, type) {
        super(type);
        this.options = options;
        this.min = null;
        this.max = null;
        this.valueType = "int";
        if (options["type"] !== undefined && ["int", "float"].includes(options["type"])) {
            this.valueType = options["type"];
        }
        if (options["min"] !== undefined) {
            const min = this.parse(options.min);
            if (!isNaN(min)) {
                this.min = min;
            }
        }
        if (options["max"] !== undefined) {
            const max = this.parse(options.max);
            if (!isNaN(max)) {
                this.max = max;
            }
        }
    }
    validate({ data }) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const d = this.parse(data);
            if (data === null || isNaN(d)) {
                return this.formatResult([false, { code: "OUT_OF_RANGE_VALUE", context: { direction: "any", number: data } }], this.options);
            }
            if (this.min !== null && d < this.min) {
                return this.formatResult([false, { code: "OUT_OF_RANGE_VALUE", context: { direction: "lower", number: this.min } }], this.options);
            }
            if (this.max !== null && d > this.max) {
                return this.formatResult([false, { code: "OUT_OF_RANGE_VALUE", context: { direction: "higher", number: this.max } }], this.options);
            }
            return [true, null];
        });
    }
    parse(value) {
        return this.valueType === "float" ? parseFloat(value + "") : parseInt(value + "");
    }
}
exports.RangeNumberValidator = RangeNumberValidator;
