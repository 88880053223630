"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SchemaPluginsService = void 0;
class SchemaPluginsService {
    constructor(form, pluginsFactory) {
        this.pluginsFactory = pluginsFactory;
        this.parse(form.schema, form);
    }
    parse(schema, form) {
        const schemaPlugins = schema.get("plugins", {});
        for (const [plugin, opts] of Object.entries(schemaPlugins)) {
            const pluginInstance = this.pluginsFactory.create(plugin, opts);
            if (pluginInstance) {
                form.use(pluginInstance);
            }
        }
    }
}
exports.SchemaPluginsService = SchemaPluginsService;
