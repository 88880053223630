class LoaderService {
  static get DEFAULT_WAIT() {
    return 750
  }

  constructor() {
    this.debounceTimers = {}
  }

  debounce({ fnc, uid }, wait = LoaderService.DEFAULT_WAIT) {
    const { debounceTimers } = this
    clearTimeout(debounceTimers[uid]?.timeout)

    return (...args) => {
      const context = this
      const callback = () => fnc.apply(context, args)
      debounceTimers[uid] = {
        callback,
        timeout: setTimeout(
          () => {
            delete debounceTimers[uid]
            callback()
          },
          isNaN(wait) ? LoaderService.DEFAULT_WAIT : wait,
        ),
      }
    }
  }

  forceDebounce(uid) {
    const { debounceTimers } = this
    if (debounceTimers[uid]) {
      const { callback, timeout } = debounceTimers[uid]
      clearTimeout(timeout)
      delete debounceTimers[uid]
      callback()
    }
  }

  isDebouncing(uid) {
    return !!this.debounceTimers[uid]
  }
}

const loaderService = new LoaderService()
export default loaderService
