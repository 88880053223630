interface ReactNativeWebViewInterface {
  postMessage: (payload: string) => void
}

const isHostReactNative = () => {
  return window.ReactNativeWebView
}

const postMessageToReactNativeWebView = (payload) => {
  window.ReactNativeWebView?.postMessage(JSON.stringify(payload))
}

const isIOS = () => {
  return (
    ["iPad Simulator", "iPhone Simulator", "iPod Simulator", "iPad", "iPhone", "iPod"].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  )
}

export { ReactNativeWebViewInterface, isHostReactNative, postMessageToReactNativeWebView, isIOS }
