"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DisableFutureDateValidator = void 0;
const tslib_1 = require("tslib");
const AbstractValidator_1 = require("./AbstractValidator");
class DisableFutureDateValidator extends AbstractValidator_1.AbstractValidator {
    constructor(options, type) {
        super(type);
        this.options = options;
    }
    validate({ data }) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (data === null) {
                return [true, null];
            }
            const dateToValidate = data.includes("T") ? data.split("T")[0] : data;
            if (new Date(dateToValidate) > new Date()) {
                return this.formatResult([false, { code: "FUTURE_DATE_NOT_ALLOWED" }], this.options);
            }
            return [true, null];
        });
    }
}
exports.DisableFutureDateValidator = DisableFutureDateValidator;
