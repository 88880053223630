"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UploadValidator = void 0;
const tslib_1 = require("tslib");
const AbstractValidator_1 = require("./AbstractValidator");
class UploadValidator extends AbstractValidator_1.AbstractValidator {
    constructor(options, type) {
        super(type);
        this.options = options;
        const { required = false, minItems, maxItems } = options;
        this.options.minItems = minItems !== null && minItems !== void 0 ? minItems : (required ? 1 : 0);
        this.options.maxItems = maxItems !== null && maxItems !== void 0 ? maxItems : undefined;
    }
    validate({ data, prop }) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (data && Array.isArray(data)) {
                if (data.some((value) => typeof value === "object" &&
                    "status" in value &&
                    (value === null || value === void 0 ? void 0 : value.status) !== "Available" &&
                    (value === null || value === void 0 ? void 0 : value.status) !== "done" &&
                    (value === null || value === void 0 ? void 0 : value.status) !== "error")) {
                    return this.formatResult([false, { code: "UPLOAD_IN_PROGRESS" }], this.options);
                }
            }
            if (this.options.minItems === 0 && !this.options.maxItems) {
                return [true, null];
            }
            const context = { selector: prop.UID, minItems: this.options.minItems, maxItems: this.options.maxItems };
            if (!data) {
                if (!this.options.minItems) {
                    return [true, null];
                }
                return this.formatResult([false, { code: "UPLOAD_MIN_ITEMS", context }], this.options);
            }
            if (!Array.isArray(data)) {
                return this.formatResult([false, { code: "UPLOAD_DATA_INVALID", context }], this.options);
            }
            if (data.some((value) => (value === null || value === void 0 ? void 0 : value.status) === "error")) {
                return this.formatResult([false, { code: "UPLOAD_FAILED", context }], this.options);
            }
            if (this.options.minItems && data.length < this.options.minItems) {
                return this.formatResult([false, { code: "UPLOAD_MIN_ITEMS", context }], this.options);
            }
            else if (this.options.maxItems && data.length > this.options.maxItems) {
                return this.formatResult([false, { code: "UPLOAD_MAX_ITEMS", context }], this.options);
            }
            return [true, null];
        });
    }
}
exports.UploadValidator = UploadValidator;
