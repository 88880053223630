import React, { useContext } from "react"
import { PropertyInterface } from "@akinoxsolutions/formol"
import useTranslation from "../../hooks/useTranslation"
import { FormStateInterface } from "../../context/form/formReducer"
import { FormContext } from "../../context/form/formState"
import { getStyle, getSubType } from "../../utils/uiUtils"
import { Html, Title, RawImage } from "../index"

export interface HtmlElementPropsInterface {
  property: PropertyInterface
}

const HtmlElement = ({ property }: HtmlElementPropsInterface): JSX.Element => {
  const getContent = (): string => {
    if (property.def.content) {
      return translate(content, property.def)
    } else if (form?.dataSelector.has(property.UID)) {
      return form?.dataSelector.get<string>(property.UID)
    }
    return ""
  }
  const { form }: FormStateInterface = useContext(FormContext)
  const { translate } = useTranslation()
  const { content, ui = {} } = property.def
  const { htmlOptions = {}, options = {}, titleOptions = {}, type: uiType } = ui
  const contentToDisplay = getContent()
  const style = getStyle(ui)

  const subtype = getSubType(uiType)
  let subtypeToUse: string

  switch (subtype) {
    case "title":
      return (
        <Title style={style} tooltip={options.tooltip} {...titleOptions}>
          {contentToDisplay}
        </Title>
      )
    case "img":
      // eslint-disable-next-line no-case-declarations
      const { width, height, alt, src } = property.def
      return <RawImage width={width} height={height} alt={alt} src={src} />
    case "h1":
    case "h2":
    case "h3":
    case "h4":
    case "h5":
    case "h6":
    case "div":
    case "p":
    case "span":
      subtypeToUse = subtype
      break
    default:
      subtypeToUse = "div"
  }

  return <Html {...htmlOptions} content={contentToDisplay} style={style} Tag={subtypeToUse} tooltip={options.tooltip} />
}

export default HtmlElement
