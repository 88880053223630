import React, { useContext, useEffect, useState } from "react"
import { FormContext } from "../../context/form/formState"
import { MessagingContext } from "../../context/messaging/messagingState"
import { Header } from "./Header/Header"
import { Navigation } from "./Navigation/Navigation"
import { Property } from "../../components/Property/Property"

export const ConversationalFormRenderer = () => {
  const { form } = useContext(FormContext)
  const { postNavigateToMessage } = useContext(MessagingContext)
  const [property, setProperty] = useState(
    form.selectors.get(form.navigation.conversational.getCurrentSelector())?.property(),
  )

  if (!property) {
    return null
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    postNavigateToMessage(property.UID)
  }, [property])

  return (
    <>
      <Header property={property} />
      <Property property={property} />
      <Navigation property={property} setProperty={setProperty} />
    </>
  )
}
