import React from "react"
import useTranslation from "../../hooks/useTranslation"
import { ImagePropsInterface } from "../Image/Image"

export type RawImagePropsInterface = Omit<ImagePropsInterface, "id" | "preview" | "title">

const RawImage = ({ alt, height, src, width, ...props }: RawImagePropsInterface) => {
  const { translate } = useTranslation()

  return (
    <img alt={translate(alt)} title={translate(alt)} height={height} src={translate(src)} width={width} {...props} />
  )
}

export default RawImage
