"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PartialFormValidationPlugin = void 0;
const tslib_1 = require("tslib");
const PluginBase_1 = require("../../Form/Plugin/PluginBase");
const Property_1 = require("../../Schema/Property/Property");
const PartialFormValidationHandler_1 = require("./PartialFormValidationHandler");
const Events_1 = require("../../Form/Event/Events");
class PartialFormValidationPlugin extends PluginBase_1.PluginBase {
    constructor() {
        super(...arguments);
        this.name = "partialFormValidation";
        this.selectors = {};
    }
    formCreated({ form }) {
        this.mapObjectProperties(form);
        for (const [selector, threshold] of Object.entries(this.selectors)) {
            this.registerCustomValidation(form, selector, threshold);
        }
        form.passHandler = PartialFormValidationHandler_1.partialFormValidationHandler;
    }
    isValid(form, selector, threshold) {
        const processedSelectors = [];
        const processedGroups = [];
        const children = form.selectors.getChildrenSelectors(selector);
        let validCount = 0;
        for (const child of children) {
            processedSelectors.push(child);
            if (validCount >= threshold.minItems) {
                break;
            }
            const childInfo = form.selectors.get(child);
            if (childInfo.belongToGroup) {
                if (!processedGroups.includes(childInfo.groupUID)) {
                    if (form.nodes.isValidGroup(childInfo.groupUID)) {
                        validCount++;
                    }
                    processedGroups.push(childInfo.groupUID);
                }
            }
            else if (form.qualifications[child] && childInfo.isAnswerable && form.validations[child]) {
                validCount++;
            }
        }
        return [validCount >= threshold.minItems, processedSelectors];
    }
    mapObjectProperties(form) {
        for (const sel of form.selectors.values) {
            if (sel.type === Property_1.Property.TYPE_OBJECT && !sel.isGroup) {
                const prop = sel.property();
                if (prop.def.partialValidation) {
                    const schemaValidation = prop.get("partialValidation");
                    if (schemaValidation.minItems !== undefined) {
                        this.selectors[sel.UID] = {
                            minItems: schemaValidation.minItems || 0,
                        };
                    }
                }
            }
        }
    }
    registerCustomValidation(form, selector, threshold) {
        form.events.addEventTo(selector, Events_1.Events.DataValidation, (ctx) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const [isValid] = this.isValid(ctx.form, selector, threshold);
            return [isValid, isValid ? {} : { code: "PARTIAL_VALIDATION_FAILED" }];
        }));
    }
}
exports.PartialFormValidationPlugin = PartialFormValidationPlugin;
