"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormStateHydrator = void 0;
const tslib_1 = require("tslib");
const utils_1 = require("../../utils");
const Events_1 = require("../Event/Events");
class FormStateHydrator {
    hydrate(state, form) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const { data, qualifications, validations, meta } = state;
            if (data) {
                form.data = (0, utils_1.deepMerge)(form.data, data);
            }
            if (qualifications) {
                form.qualifications = Object.assign(Object.assign({}, form.qualifications), qualifications);
            }
            if (validations) {
                form.validations = Object.assign(Object.assign({}, form.validations), validations);
            }
            if (meta) {
                form.meta = Object.assign(Object.assign({}, form.meta), meta);
                if (meta.viewedSections) {
                    form.nodes.sections.setViewedSections(meta.viewedSections);
                }
            }
            yield form.events.refresh([]);
            yield form.compileStats();
            yield form.events.eventsManager.triggerEvent(Events_1.Events.DataUpdated, { form });
            yield form.compileStats();
        });
    }
}
exports.FormStateHydrator = FormStateHydrator;
