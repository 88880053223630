import React, { CSSProperties, ReactNode } from "react"
import { Title as GerudoTitle } from "@akinoxsolutions/gerudo-ui/dist/Title"
import { Html, HtmlPropsInterface, Tooltip, TooltipPropsInterface } from "../index"
import styles from "./Title.module.css"
import { IconSizes } from "@akinoxsolutions/gerudo-ui/dist/Icons/PropTypes/IconSizes"

export interface TitlePropsInterface extends Pick<HtmlPropsInterface, "tooltip" | "useOverflowEllipsis"> {
  children: string | ReactNode
  className?: string
  level?: number
  style?: CSSProperties
  styledAs?: number
  tooltip?: TooltipPropsInterface
}

const Title = ({
  children,
  className,
  level = 1,
  style,
  styledAs = level,
  tooltip,
  ...htmlProps
}: TitlePropsInterface): JSX.Element => {
  const semanticLevel = clampLevel(level)
  const styleLevel = clampLevel(styledAs)
  const classNames: string[] = [styles.Title]
  if (className) {
    classNames.push(className)
  }

  return (
    <GerudoTitle className={classNames.join(" ")} level={semanticLevel} styledAs={styleLevel} style={style}>
      {typeof children === "string" ? <Html content={children} Tag="div" {...htmlProps} /> : children}
      {tooltip && (
        <>
          &nbsp;
          <Tooltip {...tooltip} size={IconSizes.Medium} />
        </>
      )}
    </GerudoTitle>
  )
}

const clampLevel = (level: number) => Math.max(1, Math.min(level, 6)) as 1 | 2 | 3 | 4 | 5 | 6

export default Title
