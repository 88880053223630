const getCurrentQuestionScore = ({ meta = {} }, uid) =>
  getCalculatedScore(
    Object.values(meta)
      .filter((val) => val.detailsByQuestion)
      .map((val) => val.detailsByQuestion[uid]?.current),
  )

const getCurrentSectionScore = ({ meta = {} }, uid) =>
  getCalculatedScore(
    Object.values(meta)
      .filter((val) => val.detailsBySection)
      .map((val) => val.detailsBySection[uid]?.current),
  )

const getCalculatedScore = (array) => array.reduce((total, current) => total + (current ?? 0), 0)

export { getCurrentQuestionScore, getCurrentSectionScore }
