"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MetaDataPlugin = void 0;
const PluginBase_1 = require("../../Form/Plugin/PluginBase");
class MetaDataPlugin extends PluginBase_1.PluginBase {
    constructor(data) {
        super();
        this.name = "metaData";
        this.data = data;
    }
    formCreated({ form }) {
        form.meta["data"] = this.data;
    }
}
exports.MetaDataPlugin = MetaDataPlugin;
