import React from "react"
import { HtmlPropsInterface } from "../index"

export interface SafeHtmlPropsInterface extends HtmlPropsInterface {}

const SafeHtml = ({ className, content, id, style, Tag = "span" }: SafeHtmlPropsInterface): JSX.Element => {
  return (
    <Tag className={className} id={id} style={style}>
      {content}
    </Tag>
  )
}

export default SafeHtml
