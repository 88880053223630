import React, { SyntheticEvent } from "react"
import { ReloadIcon } from "../Icons"
import { Link, LinkPropsInterface } from "../index"
import styles from "./Reset.module.css"

export interface ResetPropsInterface extends Omit<LinkPropsInterface, "label" | "title"> {
  resetData: () => void
}

const Reset = ({ className, disabled, id = "reset", resetData }: ResetPropsInterface) => {
  const classNames: string[] = [styles.Reset]
  if (className) {
    classNames.push(className)
  }

  const onClickHandler = (event: SyntheticEvent): void => {
    if (disabled) return

    event.preventDefault()
    resetData()
  }

  return (
    <div className={classNames.join(" ")}>
      <Link
        disabled={disabled}
        icon={<ReloadIcon className={styles.ResetIcon} />}
        id={id}
        label="actions.reset"
        onClick={onClickHandler}
        target="_self"
        title="actions.reset"
      />
    </div>
  )
}

export default Reset
