"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormNavigation = void 0;
const ConversationalMode_1 = require("./Navigation/ConversationalMode");
const StandardMode_1 = require("./Navigation/StandardMode");
class FormNavigation {
    constructor(form) {
        this.conversational = new ConversationalMode_1.ConversationalMode(form);
        this.standard = new StandardMode_1.StandardMode(form);
    }
    setCurrentSelector(selector) {
        this.conversational.setCurrentSelector(selector);
        this.standard.setCurrentSelector(selector);
    }
}
exports.FormNavigation = FormNavigation;
