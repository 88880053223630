import React, { StrictMode, Suspense } from "react"
import ReactDOM from "react-dom"
import "./index.css"
import styles from "./index.module.css"
import { App } from "./App"
import { LoadingMessage } from "./components"
import { loadJson, changeLangTo, versions } from "./utils/devUtils"

ReactDOM.render(
  <StrictMode>
    <Suspense
      fallback={
        <div className={styles.LoadingLanguages}>
          <LoadingMessage description="Loading languages" message="Loading..." tip="Loading..." />
        </div>
      }
    >
      <App />
    </Suspense>
  </StrictMode>,
  document.getElementById("root"),
)

if (window) {
  window.loadJson = loadJson
  window.versions = versions
  window.changeLangTo = changeLangTo
}
