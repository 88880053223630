import React from "react"
import { EyeClosedIcon as GerudoEyeClosedIcon } from "@akinoxsolutions/gerudo-ui/dist/Icons/EyeClosed"
import useTranslation from "../../hooks/useTranslation"

export const EyeClosedIcon = ({ onClick, titleKey = "password.hide" }) => {
  const { translate } = useTranslation()

  return (
    <GerudoEyeClosedIcon
      color="#949494"
      key="EyeClosedIcon"
      onClick={onClick}
      size="medium"
      title={titleKey ? translate(titleKey) : undefined}
    />
  )
}
