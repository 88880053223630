"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PluginEvents = void 0;
var PluginEvents;
(function (PluginEvents) {
    PluginEvents["DataUpdated"] = "data:updated";
    PluginEvents["FormPass"] = "form:pass";
    PluginEvents["FormCreated"] = "form:created";
    PluginEvents["EventsRefreshed"] = "events:refreshed";
    PluginEvents["SelectorAdded"] = "selector:added";
    PluginEvents["SelectorRemoved"] = "selector:removed";
})(PluginEvents || (exports.PluginEvents = PluginEvents = {}));
