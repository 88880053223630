import React, { useContext } from "react"
import useTranslation from "../../../hooks/useTranslation"
import { getHtmlHeadingSemanticLevel } from "../../../utils/uiUtils"
import { Title } from "../../../components"
import { FormContext } from "../../../context/form/formState"

export const HeaderLine = ({ property }) => {
  const { translate } = useTranslation()
  const { form } = useContext(FormContext)
  const { title } = property.def
  if (!title) {
    return <></>
  }

  return <Title level={getHtmlHeadingSemanticLevel(property.UID, form)}>{translate(title)}</Title>
}
