import React from "react"
import Progress from "antd/lib/progress"
import styles from "./LoadingBar.module.css"

export interface LoadingBarPropsInterface {
  className?: string
}

const LoadingBar = ({ className }: LoadingBarPropsInterface): JSX.Element => {
  const classNames: string[] = [styles.LoadingBar]
  if (className) {
    classNames.push(className)
  }

  return (
    <div className={classNames.join(" ")}>
      <Progress percent={100} status="active" showInfo={false} />
    </div>
  )
}

export default LoadingBar
