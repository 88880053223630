"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TimeTrackerPlugin = void 0;
const PluginBase_1 = require("../../Form/Plugin/PluginBase");
const utils_1 = require("../../utils");
class TimeTrackerPlugin extends PluginBase_1.PluginBase {
    constructor() {
        super(...arguments);
        this.name = "timeTracker";
        this.time = {
            createdAt: 0,
            startedAt: 0,
            updatedAt: 0,
            duration: 0,
        };
    }
    formCreated({ form }) {
        form.meta[this.name] = this.time;
        form.meta[this.name].createdAt = (0, utils_1.getTimestamp)();
        if (form.pass) {
            this.dataUpdated({ form });
        }
    }
    dataUpdated({ form }) {
        const currentTimeStamp = (0, utils_1.getTimestamp)();
        if (form.meta[this.name].startedAt === 0) {
            form.meta[this.name].startedAt = currentTimeStamp;
        }
        form.meta[this.name].updatedAt = currentTimeStamp;
        form.meta[this.name].duration = form.meta[this.name].updatedAt - form.meta[this.name].startedAt;
    }
    formPass(ctx) {
        this.dataUpdated(ctx);
    }
}
exports.TimeTrackerPlugin = TimeTrackerPlugin;
