import React, { CSSProperties } from "react"
import { IconSizes } from "@akinoxsolutions/gerudo-ui/dist/Icons/PropTypes/IconSizes"
import { Tooltip, TooltipPropsInterface } from "../index"
import styles from "./Html.module.css"

export interface HtmlPropsInterface {
  className?: string
  content: string
  id?: string
  style?: CSSProperties
  Tag?: keyof Omit<HTMLElementTagNameMap, "dir" | "font" | "frame" | "frameset" | "marquee">
  tooltip?: TooltipPropsInterface
  useOverflowEllipsis?: boolean
}

const Html = ({
  className,
  content,
  id,
  style,
  Tag = "span",
  tooltip,
  useOverflowEllipsis,
}: HtmlPropsInterface): JSX.Element => {
  const classNames: string[] = [styles.Html]
  if (className) {
    classNames.push(className)
  }
  if (useOverflowEllipsis) {
    classNames.push(styles.OverflowEllipsis)
  }

  const title = useOverflowEllipsis ? content.replace(/<[^>]+>/g, "") : undefined

  return (
    <span className={styles.HtmlWrapper}>
      <Tag
        className={classNames.join(" ")}
        dangerouslySetInnerHTML={{ __html: content }}
        id={id}
        style={style}
        title={title}
      />
      {tooltip && (
        <>
          &nbsp;
          <Tooltip size={IconSizes.Small} {...tooltip} />
        </>
      )}
    </span>
  )
}

export default Html
