"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Schema = void 0;
const Property_1 = require("./Property/Property");
const SchemaNodes_1 = require("./SchemaNodes");
class Schema extends Property_1.Property {
    get cachedProperties() {
        return this._cachedProperties;
    }
    constructor(def) {
        super("root", "", def);
        this._cachedProperties = new Map();
        this.nodes = new SchemaNodes_1.SchemaNodes(this);
    }
    get $schema() {
        return this.def.$schema || "";
    }
    search(selector) {
        if (this._cachedProperties.has(selector)) {
            return this._cachedProperties.get(selector);
        }
        const prop = this._search(this.childProps(), selector);
        if (prop !== null) {
            this._cachedProperties.set(selector, prop);
        }
        return prop;
    }
    clearCachedSelector(key) {
        this._cachedProperties.delete(key);
    }
    clearAllCachedSelectors() {
        this._cachedProperties.clear();
    }
    mapNodes() {
        this.nodes.mapAll();
    }
    getAncestors(selector) {
        const ancestors = [];
        const property = this.search(selector);
        if (property) {
            if (property.parentUID) {
                ancestors.push(...this.getAncestors(property.parentUID));
            }
            ancestors.push(property);
        }
        return ancestors;
    }
    _search(props, selector) {
        for (const [, prop] of props) {
            if (prop.UID === selector) {
                return prop;
            }
            else if (prop.hasChildProps()) {
                const result = this._search(prop.childProps(), selector);
                if (result) {
                    return result;
                }
            }
        }
        return null;
    }
}
exports.Schema = Schema;
