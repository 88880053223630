import React, { CSSProperties } from "react"
import { Html } from "../index"
import styles from "./Message.module.css"

import { NotificationBar, NotificationBarType } from "@akinoxsolutions/gerudo-ui/dist/NotificationBar"

export interface MessagePropsInterface {
  className?: string
  description?: string
  hideIcon?: boolean
  message: string
  style?: CSSProperties
  type?: "success" | "info" | "warning" | "error"
  withDelay?: boolean
}

const typeMap: Record<NonNullable<MessagePropsInterface["type"]>, NotificationBarType> = {
  success: NotificationBarType.Success,
  info: NotificationBarType.Info,
  warning: NotificationBarType.Warning,
  error: NotificationBarType.Alert,
}

const Message = ({
  className,
  description,
  hideIcon = false,
  message,
  style,
  type = "info",
  withDelay,
}: MessagePropsInterface): JSX.Element => {
  const classNames: string[] = [styles.Message]
  if (withDelay) {
    classNames.push(styles.WithDelay)
  }
  if (className) {
    classNames.push(className)
  }

  return (
    <div className={classNames.join(" ")} style={style}>
      <NotificationBar
        open
        type={typeMap[type]}
        title={message ? <Html content={message} /> : undefined}
        content={description ? <Html content={description} /> : undefined}
        hideIcon={hideIcon}
      />
    </div>
  )
}

export default Message
