import {
  DefinitionInterface,
  FormDataInterface,
  FormInterface,
  FormStatsInterface,
  OptionsInterface,
} from "@akinoxsolutions/formol"
import formTypes, { SectionId, FormSelector } from "./formTypes"
import { ValidateStatus } from "antd/lib/form/FormItem"

export interface FormBuildOptionsInterface extends OptionsInterface {
  lastUpdatedSelector: SectionId
}

type DisplayModeType = "conversational" | "sectional" | "standard"

export type FormStatusType =
  | "WAITING_FOR_PAYLOAD"
  | "EMPTY"
  | "LOADING_ERROR"
  | "LOADING_IN_PROGRESS"
  | "SUCCESSFULLY_LOADED"
  | "SUBMITTED"

export interface FormErrorInterface {
  code: number
  msg: string
  stackTrace?: string
}

export interface FormOptionsInterface {
  allowInvalidSection: boolean
  displayMode?: DisplayModeType
  highlightInvalid?: boolean
  isPartialDisplay: boolean
  fixedNavigationButtons: boolean
  mode?: DisplayModeType
  readOnlyMode: boolean
}

export interface ValidationErrorInterface {
  code?: string
  context?: DefinitionInterface
  status?: ValidateStatus
}

export interface FormStateInterface {
  autofocusUid?: SectionId
  error?: FormErrorInterface
  form?: FormInterface
  formBuildOptions?: FormBuildOptionsInterface
  formBuildNo: number
  formStatus: FormStatusType
  formOptions: FormOptionsInterface
  formVars: Record<string, unknown>
  formQualifications: Record<SectionId, boolean>
  formQualificationsErrors: FormDataInterface
  formStats: FormStatsInterface
  formValidations: Record<SectionId, boolean>
  formValidationsErrors: FormDataInterface
  hasSections: boolean
  isFormStarted: boolean
  isFirstTimeViewingCurrentSection: boolean
  isSubmitting: boolean
  isUpdating: boolean
  isLoadingItems: Record<string, unknown>
  lastUpdatedSelector?: string
  navigableSectionUids: SectionId[]
  navigationTree: unknown[]
  sectionUids: SectionId[]
  stepIndex: number
  stepUid?: SectionId
  style?: unknown
}

export type FormReducerAction =
  | { type: formTypes.ADD_ITEM_TO; selector: FormSelector }
  | { type: formTypes.DELETE_ITEM; selector: FormSelector }
  | { type: formTypes.ADD_ITEM_FAILED; selector: FormSelector }
  | { type: formTypes.DELETE_ITEM_FAILED; selector: FormSelector }
  | { type: formTypes.ADD_ITEM_SUCCEEDED; selector: FormSelector; form: FormInterface }
  | { type: formTypes.DELETE_ITEM_SUCCEEDED; selector: FormSelector; form: FormInterface }
  | { type: formTypes.CLEAR_AUTOFOCUS_UID }
  | { type: formTypes.SET_AUTOFOCUS_UID; autofocusUid: SectionId }
  | { type: formTypes.FETCH_FORM }
  | { type: formTypes.FETCH_FORM_EMPTY }
  | { type: formTypes.FETCH_FORM_ERROR; error: { code: number; msg: string; stackTrace?: string } }
  | {
      type: formTypes.FETCH_FORM_SUCCESS
      form: FormInterface
      sectionUids: SectionId[]
      navigableSectionUids: SectionId[]
      navigationTree: unknown[]
      hasSections: boolean
    }
  | { type: formTypes.FORM_INIT }
  | { type: formTypes.FORM_RESET }
  | { type: formTypes.FORM_SUBMITTED }
  | { type: formTypes.RESET_DATA_FINISHED; form: FormInterface; selector: string }
  | { type: formTypes.UPDATE_DATA_FINISHED; form: FormInterface; selector: string }
  | { type: formTypes.RESET_DATA_IN_PROGRESS }
  | { type: formTypes.UPDATE_DATA_IN_PROGRESS }
  | { type: formTypes.REFRESH_FINISHED; form: FormInterface }
  | { type: formTypes.REFRESH_IN_PROGRESS }
  | { type: formTypes.SET_FORM_BUILD_OPTIONS; formBuildOptions: FormBuildOptionsInterface }
  | { type: formTypes.SET_FORM_STYLE; style: unknown }
  | { type: formTypes.SET_STEP; stepIndex: number; stepUid: SectionId; isFirstTimeViewingSection: boolean }
  | { type: formTypes.SUBMIT_FORM }

export const defaultFormOptions: FormOptionsInterface = {
  allowInvalidSection: true,
  displayMode: undefined,
  highlightInvalid: false,
  fixedNavigationButtons: false,
  isPartialDisplay: false,
  readOnlyMode: false,
}

const defaultFormStats: FormStatsInterface = {
  qualified: 0,
  valid: 0,
  invalid: 0,
  progression: 0,
  sections: {},
}

export const formInitialState: FormStateInterface = {
  autofocusUid: undefined,
  error: undefined,
  form: undefined,
  formBuildOptions: undefined,
  formBuildNo: 1,
  formStatus: "WAITING_FOR_PAYLOAD",
  formOptions: defaultFormOptions,
  formVars: {},
  formQualifications: {},
  formQualificationsErrors: {},
  formStats: defaultFormStats,
  formValidations: {},
  formValidationsErrors: {},
  hasSections: false,
  isFormStarted: false,
  isFirstTimeViewingCurrentSection: true,
  isSubmitting: false,
  isUpdating: false,
  isLoadingItems: {},
  lastUpdatedSelector: undefined,
  navigableSectionUids: [],
  navigationTree: [],
  sectionUids: [],
  stepIndex: 0,
  stepUid: undefined,
  style: undefined,
}

const getUpdatedFormState = (form: FormInterface): Partial<FormStateInterface> => ({
  formVars: Object.assign({}, form.vars),
  formQualifications: Object.assign({}, form.qualifications),
  formQualificationsErrors: Object.assign({}, form.qualificationsErrors),
  formStats: Object.assign({}, form.stats),
  formValidations: Object.assign({}, form.validations),
  formValidationsErrors: Object.assign({}, form.validationsErrors),
})

const formReducer = (state: FormStateInterface, action: FormReducerAction): FormStateInterface => {
  switch (action.type) {
    case formTypes.ADD_ITEM_TO:
    case formTypes.DELETE_ITEM: {
      return {
        ...state,
        isLoadingItems: {
          ...state.isLoadingItems,
          [action.selector]: true,
        },
      }
    }

    case formTypes.ADD_ITEM_FAILED:
    case formTypes.DELETE_ITEM_FAILED: {
      return {
        ...state,
        isLoadingItems: {
          ...state.isLoadingItems,
          [action.selector]: false,
        },
      }
    }

    case formTypes.ADD_ITEM_SUCCEEDED:
    case formTypes.DELETE_ITEM_SUCCEEDED: {
      return {
        ...state,
        ...getUpdatedFormState(action.form),
        isLoadingItems: {
          ...state.isLoadingItems,
          [action.selector]: false,
        },
        lastUpdatedSelector: action.selector,
      }
    }

    case formTypes.CLEAR_AUTOFOCUS_UID: {
      return {
        ...state,
        autofocusUid: undefined,
      }
    }

    case formTypes.SET_AUTOFOCUS_UID: {
      return {
        ...state,
        autofocusUid: action.autofocusUid,
      }
    }

    case formTypes.FORM_INIT: {
      return formInitialState
    }

    case formTypes.FETCH_FORM: {
      return {
        ...state,
        formStatus: "LOADING_IN_PROGRESS",
      }
    }

    case formTypes.FETCH_FORM_EMPTY: {
      return {
        ...formInitialState,
        formStatus: "EMPTY",
      }
    }

    case formTypes.FETCH_FORM_ERROR: {
      return {
        ...formInitialState,
        error: action.error,
        formStatus: "LOADING_ERROR",
      }
    }

    case formTypes.FETCH_FORM_SUCCESS: {
      return {
        ...state,
        form: action.form,
        formStatus: "SUCCESSFULLY_LOADED",
        formOptions: {
          ...defaultFormOptions,
          ...action.form.schema.def.ui?.options,
        },
        isFormStarted: Boolean(state.formBuildOptions?.lastUpdatedSelector),
        sectionUids: action.sectionUids,
        hasSections: action.hasSections,
        navigableSectionUids: action.navigableSectionUids,
        navigationTree: action.navigationTree,
        ...getUpdatedFormState(action.form),
      }
    }

    case formTypes.FORM_RESET: {
      return {
        ...formInitialState,
        formBuildNo: state.formBuildNo + 1,
        formBuildOptions: state.formBuildOptions,
        isFormStarted: Boolean(state.formBuildOptions?.lastUpdatedSelector),
      }
    }

    case formTypes.FORM_SUBMITTED: {
      return {
        ...formInitialState,
        formStatus: "SUBMITTED",
      }
    }

    case formTypes.RESET_DATA_FINISHED:
    case formTypes.UPDATE_DATA_FINISHED: {
      return {
        ...state,
        isFormStarted: true,
        isUpdating: false,
        lastUpdatedSelector: action.selector,
        ...getUpdatedFormState(action.form),
      }
    }

    case formTypes.REFRESH_FINISHED: {
      return {
        ...state,
        isUpdating: false,
        ...getUpdatedFormState(action.form),
      }
    }

    case formTypes.REFRESH_IN_PROGRESS:
    case formTypes.RESET_DATA_IN_PROGRESS:
    case formTypes.UPDATE_DATA_IN_PROGRESS: {
      return {
        ...state,
        isUpdating: true,
      }
    }

    case formTypes.SET_FORM_BUILD_OPTIONS: {
      return {
        ...state,
        autofocusUid: action.formBuildOptions.lastUpdatedSelector,
        formBuildNo: 1,
        formBuildOptions: action.formBuildOptions,
      }
    }

    case formTypes.SET_FORM_STYLE: {
      return {
        ...state,
        style: action.style,
      }
    }

    case formTypes.SET_STEP: {
      return {
        ...state,
        stepIndex: action.stepIndex,
        stepUid: action.stepUid,
        isFirstTimeViewingCurrentSection: action.isFirstTimeViewingSection,
      }
    }

    case formTypes.SUBMIT_FORM: {
      return {
        ...state,
        isSubmitting: true,
      }
    }

    default:
      return state
  }
}

export default formReducer
