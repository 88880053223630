export class WatermarkOptions {
  constructor({ fullCover, pattern, rect, svg, text } = {}) {
    this.fullCover = fullCover === true
    this.setPatternOptions(pattern)
    this.setRectOptions(rect)
    this.setSvgOptions(svg)
    this.setTextOptions(text)
  }

  getPatternOptions() {
    return this.patternOptions
  }
  setPatternOptions({ x = 0, y = 0, width = "100px", height = "100px", patternUnits = "userSpaceOnUse" } = {}) {
    this.patternOptions = { x, y, width, height, patternUnits }
  }

  getRectOptions() {
    return this.rectOptions
  }
  setRectOptions({ x = 0, y = 0, height = "100%", width = "100%" } = {}) {
    this.rectOptions = { x, y, height, width }
  }

  getSvgOptions() {
    return this.svgOptions
  }
  setSvgOptions({
    backgroundColor,
    top = 0,
    left = 0,
    right = 0,
    bottom = 0,
    opacity = 0.25,
    position = this.fullCover ? "fixed" : "absolute",
    height = "100%",
    width = "100%",
    zIndex = this.fullCover ? -1 : 999,
  } = {}) {
    this.svgOptions = { style: { backgroundColor, top, left, right, bottom, opacity, position, height, width, zIndex } }
  }

  getTextOptions() {
    return this.textOptions
  }
  setTextOptions({
    fill,
    fontWeight = "normal",
    fontSize = "inherit",
    translateX = "10px",
    translateY = "75px",
    rotate = "-45deg",
  } = {}) {
    const transformOptions = []
    if (translateX) {
      transformOptions.push(`translateX(${translateX})`)
    }
    if (translateY) {
      transformOptions.push(`translateY(${translateY})`)
    }
    if (rotate) {
      transformOptions.push(`rotate(${rotate})`)
    }
    this.textOptions = { fill, style: { fontWeight, fontSize, transform: transformOptions.join(" ") } }
  }
}
