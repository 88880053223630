import React, { useState } from "react"
import { NotificationBar as GerudoNotificationBar } from "@akinoxsolutions/gerudo-ui"
import { PropertyInterface } from "../../../../lib"
import useTranslation from "../../hooks/useTranslation"
import { NotificationBarType } from "@akinoxsolutions/gerudo-ui/dist/NotificationBar"
import NotificationModal from "./NotificationModal/NotificationModal"
import Button from "../Button/Button"
import { ButtonTypes } from "@akinoxsolutions/gerudo-ui/dist/Button"
import styles from "./NotificationBar.module.css"
export interface NotificationBarPropsInterface {
  property: PropertyInterface
  className?: string
}

const NotificationBar = ({ property, className }: NotificationBarPropsInterface) => {
  const { translate } = useTranslation()
  const { title, text, link, ui = {} } = property.def
  const { notificationBarOptions = {}, modalOptions = {} } = ui
  const { notificationType, icon, open, handleClose, isModal } = notificationBarOptions
  const [visible, setModalVisible] = useState(false)

  const onClick = () => {
    if (isModal) {
      setModalVisible(true)
    }
  }

  const onCancel = () => {
    setModalVisible(false)
  }

  const linkContent = link && (
    <Button
      id={"link-button"}
      className={styles["notification-bar-link"]}
      label={translate(link.label)}
      onClick={onClick}
      type={ButtonTypes.Text}
    />
  )
  const content = (
    <div>
      <p>{translate(text)}</p>
      {linkContent}
    </div>
  )

  return (
    <>
      <NotificationModal modalOptions={modalOptions} visible={visible} onCancel={onCancel} />
      <GerudoNotificationBar
        className={className}
        type={(notificationType as NotificationBarType) || NotificationBarType.Info}
        icon={icon || null}
        content={text ? content : null}
        open={open || true}
        title={translate(title)}
        handleClose={handleClose}
      />
    </>
  )
}

export default NotificationBar
