import localeEN from "antd/lib/locale/en_US"
import localeES from "antd/lib/locale/es_ES"
import localeFR from "antd/lib/locale/fr_CA"

const locales = {
  en: localeEN,
  es: localeES,
  fr: localeFR,
}
const defaultLocaleKey = "fr"
const defaultLocale = locales[defaultLocaleKey]
const gerudoLocale = {
  en: "en_US",
  fr: "fr_FR",
}
const gerudoDefaultLocale = gerudoLocale[defaultLocaleKey]

export default locales
export { defaultLocale, defaultLocaleKey, gerudoDefaultLocale, gerudoLocale }
