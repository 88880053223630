import { useRef } from "react"

export default () => {
  const elRef = useRef(null)
  const executeScroll = () => {
    setTimeout(() => {
      elRef.current?.scrollIntoView({ behavior: "smooth", block: "center" })
    })
  }

  return [executeScroll, elRef]
}
