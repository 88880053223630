import { useTranslation } from "react-i18next"
import { replaceSubstitutionStrings } from "../utils/translationUtils"
import { useContext } from "react"
import { FormContext } from "../context/form/formState"
import { TOptions } from "i18next"

export type TranslationFn = (key?: string, context?: TOptions) => string

export default (): { translate: TranslationFn } => {
  const { t } = useTranslation()
  const { form } = useContext(FormContext)
  return {
    translate: (key, context) => {
      if (!key) return ""
      if (!form) return t(key, context)

      const substitutedKey = replaceSubstitutionStrings(form, key)
      return replaceSubstitutionStrings(form, t(substitutedKey, context))
    },
  }
}
