import React, { useState, useEffect } from "react"
import useTranslation from "../../hooks/useTranslation"
import { RadioCard } from "@akinoxsolutions/gerudo-ui/dist/RadioCard"
import { RadioCardGroupIcons } from "./RadioCardGroup.helpers"
import { AnswerableComponentPropsInterface } from "../interfaces"
import { RadioCardGroup as GerudoRadioCardGroup } from "@akinoxsolutions/gerudo-ui/dist/RadioCard/RadioCardGroup/RadioCardGroup"
import styles from "./RadioCardGroup.module.css"
import { scrollToNextFieldset } from "../../utils/uiUtils"
import { useMediaQuery } from "react-responsive"

export interface RadioCardGroupPropsInterface extends AnswerableComponentPropsInterface<string> {}

const RadioCardGroup = ({ isDisabled, isReadOnly, property, value, onChange }: RadioCardGroupPropsInterface) => {
  const { translate } = useTranslation()
  const { enum: enumeration, ui = {}, id } = property.def
  const { size, gerudoType, isLastRadioCardFullWidth, isDisplayColumn, scrollToNext } = ui

  const [formValue, setFormValue] = useState(value)

  useEffect(() => {
    if (value !== formValue) setFormValue(value)
  }, [value])

  const className = isLastRadioCardFullWidth
    ? styles["radio-card-group-last-card"]
    : isDisplayColumn
    ? styles["radio-card-group-display-card"]
    : ""

  const isMobile = useMediaQuery({ query: `(max-width: 768px)` })

  return (
    <div className={className}>
      <GerudoRadioCardGroup defaultValue={value}>
        {({ onChange: onRadioChange }) => {
          const onChangeHandler = (value) => {
            onRadioChange(value)
            onChange(value)
            setFormValue(value)

            if (scrollToNext && isMobile) scrollToNextFieldset(property.id)
          }

          return (
            <>
              {enumeration?.map(({ label, value, icon, subtitle }) => {
                return (
                  <RadioCard
                    col={{ xs: 12 }}
                    key={value}
                    name={id}
                    className={
                      (isDisabled || isReadOnly) && formValue === value
                        ? styles["gerudo-radio-card-checked-disabled"]
                        : null
                    }
                    size={size}
                    type={gerudoType}
                    icon={<RadioCardGroupIcons name={icon} />}
                    value={value}
                    title={translate(label || value)}
                    subtitle={translate(subtitle || value)}
                    checked={formValue === value}
                    onChange={onChangeHandler}
                    disabled={isDisabled || isReadOnly}
                  />
                )
              })}
            </>
          )
        }}
      </GerudoRadioCardGroup>
    </div>
  )
}

export default RadioCardGroup
