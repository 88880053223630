"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AbstractNavigationMode = void 0;
const PropetySelectorsMapper_1 = require("../../Schema/Mapper/PropetySelectorsMapper");
class AbstractNavigationMode {
    constructor(form) {
        this.form = form;
        this.propertiesSelectorsMapper = new PropetySelectorsMapper_1.PropertySelectorsMapper();
        this.globalIndexSortingClosure = (a, b) => {
            return a.globalIndex < b.globalIndex ? -1 : a.globalIndex > b.globalIndex ? 1 : 0;
        };
        this.currentSelector = "";
        this.currentSelectorIndex = -1;
        this.setCurrentSelectorToFirstAnswerable();
    }
    getCurrentSelector() {
        return this.currentSelector;
    }
    setCurrentSelector(selector) {
        const currentSelectorIndex = this.getNavigationList(null).findIndex((sel) => {
            return sel.UID === selector || sel.relatedSelectors.includes(selector);
        });
        if (currentSelectorIndex >= 0) {
            this.currentSelector = selector;
            this.currentSelectorIndex = currentSelectorIndex;
        }
    }
    setCurrentSelectorToFirstAnswerable() {
        const selectors = this.getNavigationList(null);
        if (selectors.length > 0) {
            this.setCurrentSelector(selectors[0].UID);
        }
    }
    getNavigationSelectorsList(filter = null) {
        return this.getNavigationList(filter).map(({ UID }) => UID);
    }
    getNextQualifiedSelector() {
        const answerableSelectors = this.getNavigationSelectorsList();
        if (this.currentSelectorIndex >= answerableSelectors.length - 1) {
            return null;
        }
        return answerableSelectors[this.currentSelectorIndex + 1];
    }
    getNextQualifiedProperty() {
        const nextSelector = this.getNextQualifiedSelector();
        return nextSelector ? this.form.schema.search(nextSelector) : null;
    }
    getPreviousQualifiedSelector() {
        if (this.currentSelectorIndex < 1) {
            return null;
        }
        return this.getNavigationSelectorsList()[this.currentSelectorIndex - 1];
    }
    getPreviousQualifiedProperty() {
        const prevSelector = this.getPreviousQualifiedSelector();
        return prevSelector ? this.form.schema.search(prevSelector) : null;
    }
    isCurrentSelectorFirst() {
        return this.currentSelectorIndex === 0;
    }
    isCurrentSelectorLast() {
        return this.currentSelectorIndex === this.getNavigationSelectorsList().length - 1;
    }
}
exports.AbstractNavigationMode = AbstractNavigationMode;
