import { CSSProperties } from "react"
import { FormInterface, PropertyInterface } from "@akinoxsolutions/formol"
import merge from "deepmerge"
import { SchemaUIInterface } from "../components/interfaces"
import { ComponentName } from "../components/Property/types"
import { componentNames } from "../components/Property/constants"

const formIsEmpty = ({ schema }: Partial<FormInterface> = {}): boolean => {
  return Object.keys(schema?.def?.properties ?? {}).length === 0
}

const getDataAttribute = (data = {}): Record<string, unknown> => {
  return Object.entries(data).reduce((acc, [k, v]) => {
    acc[`data-${k.toLowerCase()}`] = v
    return acc
  }, {})
}

const getStyles = (defs: Partial<SchemaUIInterface>[] = []): CSSProperties => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return getStyle(merge.all(defs) as any)
}

const getStyle = ({ flex, order, style }: Partial<SchemaUIInterface>): CSSProperties => {
  const { grow = 1, shrink = 0, basis = "100%", direction = "row", wrap = "wrap" } = flex || {}
  return {
    flex: `${grow} ${shrink} ${basis}`,
    flexDirection: direction,
    flexWrap: wrap,
    order,
    ...style,
  }
}

const getComponentName = (type: string): ComponentName | null => {
  if (!type) return null
  const name = type.toLowerCase().replace(/-/g, "").split(":")[0] as ComponentName
  if (componentNames.includes(name)) return name
  return null
}

const getSubType = (type: string): string => {
  if (!type) {
    return ""
  }
  return type.toLowerCase().split(":")[1]
}

const isPropertyUsingHtmlHeading = (property: PropertyInterface): boolean => {
  const uiOpts = property.def.ui?.options || {}
  const { hideStandardTitle = false, useHTMLHeadingElement = true } = uiOpts
  return !hideStandardTitle && useHTMLHeadingElement
}

const getHtmlHeadingSemanticLevel = (selector: string, form: FormInterface): number => {
  let level = 3
  const parentSelectors = form?.selectors.getParentSelectors(selector) || []
  for (const ps of parentSelectors) {
    const parentProperty = form?.selectors.get(ps).property()
    if (isPropertyUsingHtmlHeading(parentProperty)) {
      level++
    }
  }
  return Math.min(level, 6)
}

const getHtmlHeadingStyleLevel = (selector: string): number => {
  const level = selector.split(".").length + 2
  return Math.min(level, 6)
}

const scrollToNextFieldset = (id: string) => {
  setTimeout(() => {
    const fieldsets = document.querySelectorAll("fieldset")

    for (let index = 0; index < fieldsets.length; index++) {
      const currentFieldset = fieldsets[index].contains(document.getElementById(`${id}-wrapper`))

      if (currentFieldset && fieldsets?.[index + 1]) {
        fieldsets?.[index + 1]?.scrollIntoView({ behavior: "smooth" })
        break
      }
    }
  }, 50)
}

export {
  formIsEmpty,
  getDataAttribute,
  getStyle,
  getStyles,
  getComponentName,
  getSubType,
  isPropertyUsingHtmlHeading,
  getHtmlHeadingSemanticLevel,
  getHtmlHeadingStyleLevel,
  scrollToNextFieldset,
}
