import React, { ChangeEvent } from "react"
import useTranslation from "../../hooks/useTranslation"
import {
  CheckboxButton as GerudoCheckboxButton,
  CheckboxGroup as GerudoCheckboxGroup,
  Checkbox as GerudoCheckbox,
} from "@akinoxsolutions/gerudo-ui/dist/Checkbox"
import { getStyles, getSubType } from "../../utils/uiUtils"
import { AnswerableComponentPropsInterface, SchemaUIInterface } from "../interfaces"
import { Unsupported } from "../Unsupported/Unsupported"
import { Html } from "../index"
import styles from "./CheckboxGroup.module.css"

export interface CheckboxGroupPropsInterface extends AnswerableComponentPropsInterface<string | string[]> {}

const CheckboxGroup = ({
  autofocus,
  id,
  isDisabled,
  isReadOnly,
  name,
  onChange,
  property,
  value,
  ...otherProps
}: CheckboxGroupPropsInterface): JSX.Element => {
  const { translate } = useTranslation()
  const { enum: enumeration = [], ui = {} } = property.def
  const { size = null, type: uiType = null, checkboxGroupOptions = {} } = ui
  const { multiple = true } = checkboxGroupOptions

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const checkboxValue = e.target?.value ?? null
    if (multiple) {
      let newValues: string[] | null
      if (value && value.includes(checkboxValue)) {
        newValues = [...value]
        newValues.splice(value.indexOf(checkboxValue), 1)
        newValues = newValues.length ? newValues : []
      } else {
        newValues = value ? [...value].concat([checkboxValue]) : [checkboxValue]
      }
      onChange(newValues)
    } else {
      const newValue: string | null = checkboxValue === value ? null : checkboxValue
      onChange(newValue)
    }
  }

  let CheckboxComponent: typeof GerudoCheckbox | typeof GerudoCheckboxButton
  let checkboxClassName: string
  let groupClassName = ""

  const subtype = getSubType(uiType)
  if (subtype === "buttons") {
    CheckboxComponent = GerudoCheckboxButton
    checkboxClassName = styles.CheckboxButton
  } else if (!subtype) {
    CheckboxComponent = GerudoCheckbox
    checkboxClassName = styles.Checkbox
    groupClassName = styles.CheckboxGroup
  } else {
    return <Unsupported name={uiType} uid={id} />
  }

  // Handle both multiple and simple selections as arrays
  let currentValues: string[]
  if (typeof value === "string") {
    currentValues = [value]
  } else {
    currentValues = value || []
  }

  const autofocusKeyValue = currentValues[0] ?? enumeration[0].value.toString()
  return (
    <GerudoCheckboxGroup
      className={groupClassName}
      disabled={isDisabled || isReadOnly}
      id={id}
      size={size}
      value={currentValues}
      {...otherProps}
    >
      {enumeration.map(({ disabled, label, readOnly, tooltip, ui = {}, value: enumValue }) => {
        const strValue = enumValue.toString()
        const uiDefs: SchemaUIInterface[] = [{ flex: { wrap: "nowrap" } }, checkboxGroupOptions, ui]
        return (
          <div key={strValue} style={getStyles(uiDefs)}>
            <CheckboxComponent
              autoFocus={autofocus && autofocusKeyValue === strValue}
              checked={currentValues.includes(strValue)}
              className={checkboxClassName}
              disabled={disabled || readOnly}
              name={name}
              onClick={onChangeHandler}
              value={strValue}
            >
              <Html content={translate(label)} tooltip={tooltip} />
            </CheckboxComponent>
          </div>
        )
      })}
    </GerudoCheckboxGroup>
  )
}

export default CheckboxGroup
