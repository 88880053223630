"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Form = void 0;
const tslib_1 = require("tslib");
const ArraySelectorsDataInitializer_1 = require("./Selector/ArraySelectorsDataInitializer");
const DefaultPassHandler_1 = require("./PassHandler/DefaultPassHandler");
const Events_1 = require("./Event/Events");
const FormDataSelector_1 = require("./FormDataSelector");
const FormEvents_1 = require("./FormEvents");
const FormNavigation_1 = require("./FormNavigation");
const FormNodes_1 = require("./FormNodes");
const FormPlugins_1 = require("./FormPlugins");
const FormQVInitializer_1 = require("./FormQVInitializer");
const FormSelectors_1 = require("./FormSelectors");
const FormStats_1 = require("./FormStats");
const FormStatuses_1 = require("./FormStatuses");
const Logger_1 = require("../Logger");
const PluginEvents_1 = require("./Plugin/PluginEvents");
const PluginFactory_1 = require("../Plugin/PluginFactory");
const SchemaDataMapper_1 = require("./Schema/SchemaDataMapper");
const SchemaDefaultDataService_1 = require("./Schema/SchemaDefaultDataService");
const SchemaEventsService_1 = require("./Schema/SchemaEventsService");
const SchemaNodesValidationService_1 = require("./Schema/SchemaNodesValidationService");
const SchemaPartialSelectorResolver_1 = require("./Schema/SchemaPartialSelectorResolver");
const SchemaPluginsService_1 = require("./Schema/SchemaPluginsService");
const SchemaWildcardSelectorsResolver_1 = require("./Schema/SchemaWildcardSelectorsResolver");
const UpdateQualification_1 = require("./Action/UpdateQualification");
const UpdateState_1 = require("./Event/UpdateState");
const ValidatorFactory_1 = require("./Validator/ValidatorFactory");
class Form {
    get pass() {
        return this.lastPass;
    }
    get dataMeta() {
        return { data: this.data, meta: this.meta };
    }
    get status() {
        return this.formStatus;
    }
    constructor(schema, settings, vars) {
        this.schema = schema;
        this.settings = settings;
        this.vars = vars;
        this.data = {};
        this.qualifications = {};
        this.validations = {};
        this.qualificationsErrors = {};
        this.validationsErrors = {};
        this.meta = {};
        this.stats = Object.assign({}, FormStats_1.FormStats.defaultStats);
        this.passHandler = DefaultPassHandler_1.defaultPassHandler;
        this.miscHandlers = {};
        this.language = "fr-CA";
        this.lastPass = true;
        this.formStats = new FormStats_1.FormStats(this);
        this.formStatus = FormStatuses_1.FormStatuses.Init;
        this.dataSelector = new FormDataSelector_1.FormDataSelector(this);
        this.selectors = new FormSelectors_1.FormSelectors(this);
        this.selectors.mapArraySelectors();
        new FormQVInitializer_1.FormQVInitializer(this);
        new SchemaDataMapper_1.SchemaDataMapper(this);
        new SchemaDefaultDataService_1.SchemaDefaultDataService(this);
        new SchemaWildcardSelectorsResolver_1.SchemaWildcardSelectorsResolver(this);
        new SchemaPartialSelectorResolver_1.SchemaPartialSelectorResolver(this);
        this.events = new FormEvents_1.FormEvents(this);
        this.plugins = new FormPlugins_1.FormPlugins();
        this.nodes = new FormNodes_1.FormNodes(this);
        this.navigation = new FormNavigation_1.FormNavigation(this);
        this.updateQualificationAction = new UpdateQualification_1.UpdateQualification(this);
    }
    init() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.events.refresh([]);
            yield this.compileStats();
            yield this.registerSchemaServices();
            yield this.compileStats();
            yield new ArraySelectorsDataInitializer_1.ArraySelectorsDataInitializer(this).init();
            yield this.nodes.updateValidations();
            return () => tslib_1.__awaiter(this, void 0, void 0, function* () {
                this.formStatus = FormStatuses_1.FormStatuses.Ready;
                if (this.miscHandlers.onFormReady) {
                    yield this.miscHandlers.onFormReady(this);
                }
            });
        });
    }
    registerSchemaServices() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            new SchemaPluginsService_1.SchemaPluginsService(this, new PluginFactory_1.PluginFactory());
            yield new SchemaNodesValidationService_1.SchemaNodesValidationService(this).registerEvents();
            yield new SchemaEventsService_1.SchemaEventsService(this, new ValidatorFactory_1.ValidatorFactory()).registerEvents();
        });
    }
    use(plugin) {
        this.plugins.register(plugin);
        plugin.triggerEvent(PluginEvents_1.PluginEvents.FormCreated, { form: this });
        return this;
    }
    updateQualification(selector, isQualified) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.updateQualificationAction.execute(selector, isQualified);
        });
    }
    update(selector, newValue) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!this.selectors.has(selector)) {
                Logger_1.Logger.log(`cannot get selector ${selector}`);
                return;
            }
            const oldData = this.dataSelector.get(selector);
            let eventCtx = {
                form: this,
                data: newValue,
                oldData: oldData,
                selector,
            };
            const state = UpdateState_1.UpdateState.fromContext(eventCtx);
            const [setSucceeded] = this.dataSelector.trySet(selector, newValue);
            if (!setSucceeded) {
                Logger_1.Logger.log(`cannot set selector ${selector}`);
                return;
            }
            yield this.events.triggerEvent(Events_1.Events.DataUpdated, eventCtx);
            yield this.compileStats();
            if (this.events.selectorEventsManager.hasTargetedSelector(selector)) {
                yield this.events.selectorEventsManager.triggerAllEventsType(selector, eventCtx);
                yield this.plugins.triggerEvent(PluginEvents_1.PluginEvents.DataUpdated, eventCtx);
                state.mutate();
                if (state.hasQVChange()) {
                    yield this.compileStats();
                }
                if (state.hasChange()) {
                    eventCtx = this.events.buildCtx(selector, oldData);
                    yield this.events.refresh([selector]);
                    yield this.compileStats();
                }
                yield this.events.triggerEvent(Events_1.Events.DataUpdated, eventCtx);
                yield this.compileStats();
            }
            yield this.plugins.triggerEvent(PluginEvents_1.PluginEvents.DataUpdated, eventCtx);
            if (this.plugins.count() > 0) {
                yield this.events.triggerEvent(Events_1.Events.DataUpdated, eventCtx);
            }
            if (state.hasChange()) {
                for (const child of this.selectors.getLinkedEnumChildren(selector)) {
                    yield this.update(child, this.selectors.getDefaultValue(child));
                }
            }
            this.navigation.setCurrentSelector(selector);
            yield this.nodes.updateValidations();
        });
    }
    updates(data) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            for (const [selector, value] of Object.entries(data)) {
                yield this.update(selector, value);
            }
        });
    }
    reset(selector) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.selectors.has(selector)) {
                for (const sel of [selector, ...this.selectors.getChildrenSelectors(selector)]) {
                    if (this.selectors.is(sel, { isAnswerable: true })) {
                        yield this.update(sel, this.selectors.getDefaultValue(sel));
                    }
                }
            }
        });
    }
    compileStats() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.stats = this.formStats.compile();
            const pass = this.passHandler(this);
            if (pass && !this.lastPass) {
                yield this.events.triggerEvent(Events_1.Events.FormPass, { form: this });
                yield this.plugins.triggerEvent(PluginEvents_1.PluginEvents.FormPass, { form: this });
            }
            this.lastPass = pass;
        });
    }
}
exports.Form = Form;
