import { MessagingReducerActionType } from "./messagingTypes"
import { MessagingStateInterface } from "./messagingInterfaces"

export default (state: MessagingStateInterface, action: MessagingReducerActionType): MessagingStateInterface => {
  switch (action.type) {
    case "DYNAMIC_FORM_CONTEXT":
      return {
        ...state,
        context: action.context,
      }

    default:
      return state
  }
}
