import React, { useContext } from "react"
import { PropertyInterface } from "@akinoxsolutions/formol"
import { FormContext } from "../../context/form/formState"
import { FormStateInterface } from "../../context/form/formReducer"
import useTranslation from "../../hooks/useTranslation"
import { Item, SafeHtml, SafeHtmlPropsInterface } from ".."
import styles from "./DisplayItem.module.css"

export interface DisplayItemPropsInterface {
  className?: string
  id?: string
  property: PropertyInterface
  Tag?: SafeHtmlPropsInterface["Tag"]
}

const DisplayItem = ({ className, property, ...safeHtmlProps }: DisplayItemPropsInterface): JSX.Element => {
  const { translate } = useTranslation()
  const { form }: FormStateInterface = useContext(FormContext)
  if (!form) return <></>

  const classNames: string[] = [styles.DisplayItem]
  if (className) {
    classNames.push(className)
  }

  const { enum: enumeration, ui = {} } = property.def
  const { displayFallbackContent } = ui.displayItemOptions ?? {}

  let valueToDisplay = ui.value
  if (!valueToDisplay) {
    const [isValid, value] = form.dataSelector.tryGet(property.UID)
    if (isValid) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      valueToDisplay = translate(value as any)
      const enumItem = enumeration?.find(({ value: enumValue }) => enumValue === value)
      if (enumItem) {
        valueToDisplay = translate(enumItem.label, property.def)
      }
    }
    if (!valueToDisplay && displayFallbackContent) {
      const trKey = typeof displayFallbackContent === "string" ? displayFallbackContent : "displayItem.fallbackContent"
      valueToDisplay = translate(trKey, property.def)
    }
  }

  return (
    <Item className={classNames.join(" ")} property={property}>
      <SafeHtml content={valueToDisplay} {...safeHtmlProps} />
    </Item>
  )
}

export default DisplayItem
