import React, { useContext, useEffect, useState } from "react"
import { BodyPartPicker as GerudoBodyPartPicker } from "@akinoxsolutions/gerudo-ui/dist/BodyPartPicker"
import { ButtonGroup as GerudoButtonGroup } from "@akinoxsolutions/gerudo-ui/dist/Button"
import useTranslation from "../../hooks/useTranslation"
import { ConfigContext } from "../../context/config/configState"
import { getStyle } from "../../utils/uiUtils"
import { Button, Html } from "../index"
import styles from "./BodyPartPicker.module.css"

export const BodyPartPicker = ({ autofocus, isDisabled, isReadOnly, onChange, property, value }) => {
  const { translate } = useTranslation()
  const { readOnlyMode } = useContext(ConfigContext)
  const { ui = {} } = property.def
  const { gender, side, options = {} } = ui
  const preventClick = readOnlyMode || isDisabled || isReadOnly
  const [valueToUse, setValueToUse] = useState(value ?? [])

  const add = (bodyPart) => setValueToUse([...valueToUse, bodyPart])
  const remove = (bodyPart) => setValueToUse(valueToUse.filter((val) => val !== bodyPart))
  const handleOnCLick = (e) => {
    if (!preventClick) {
      const bodyPart = e.target.id
      if (bodyPart && !bodyPart.startsWith(property.UID)) {
        if (valueToUse.includes(bodyPart)) {
          remove(bodyPart)
        } else {
          add(bodyPart)
        }
      }
    }
  }

  useEffect(() => onChange(valueToUse), [valueToUse])

  return (
    <GerudoBodyPartPicker
      autoFocus={autofocus}
      bodyParts={valueToUse}
      className={styles.BodyPartPicker}
      disabled={isDisabled}
      gender={gender}
      onClick={handleOnCLick}
      readOnly={isReadOnly || readOnlyMode}
      side={side}
      style={getStyle(ui)}
    >
      {({ clearParts, setSide, switchGender, switchSide, currentSide, nextGenderValue, nextSideValue }) => {
        const handleClearParts = () => {
          setValueToUse([])
          clearParts()
        }
        return (
          <GerudoButtonGroup className={styles.ButtonGroup}>
            {options.switchGender && (
              <Button
                id={`${property.UID}-switchGender`}
                label={<Html content={translate(`bodyPartPicker.genders.${nextGenderValue}`)} />}
                onClick={switchGender}
              />
            )}

            {(options.setSide || options.bothSides) && (
              <Button
                disabled={currentSide === "front"}
                id={`${property.UID}-setSideToFront`}
                label={<Html content={translate("bodyPartPicker.sides.front")} />}
                onClick={() => setSide("front")}
              />
            )}

            {options.bothSides && (
              <Button
                disabled={currentSide === "both"}
                id={`${property.UID}-toggleDisplayBothSides`}
                label={<Html content={translate("bodyPartPicker.sides.both")} />}
                onClick={() => setSide("both")}
              />
            )}

            {(options.setSide || options.bothSides) && (
              <Button
                disabled={currentSide === "back"}
                id={`${property.UID}-setSideToBack`}
                label={<Html content={translate("bodyPartPicker.sides.back")} />}
                onClick={() => setSide("back")}
              />
            )}

            {options.switchSide && (
              <Button
                id={`${property.UID}-switchSide`}
                label={<Html content={translate(`bodyPartPicker.sides.${nextSideValue}`)} />}
                onClick={switchSide}
              />
            )}

            {options.clearParts && (
              <Button
                id={`${property.UID}-clearParts`}
                label={<Html content={translate("bodyPartPicker.clearParts")} />}
                onClick={handleClearParts}
              />
            )}
          </GerudoButtonGroup>
        )
      }}
    </GerudoBodyPartPicker>
  )
}
