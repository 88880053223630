import { Dispatch, useEffect, useReducer } from "react"
import { build, FormInterface } from "@akinoxsolutions/formol"
import { formIsEmpty } from "../../utils/uiUtils"
import FormNavigation from "./FormNavigation"
import formReducer, {
  formInitialState,
  FormStateInterface,
  FormReducerAction,
  FormBuildOptionsInterface,
} from "./formReducer"
import formTypes, { SectionId, FormSelector } from "./formTypes"

export interface UseFormActionInterface extends FormStateInterface {
  addItemTo: (selector: FormSelector) => void
  clearAutofocusUid: () => void
  deleteItem: (selector: FormSelector, index: number) => void
  initForm: () => void
  resetData: (selector: SectionId) => void
  resetForm: () => void
  setAutofocusUid: (autofocusUid: SectionId) => void
  setFormBuildOptions: (newFormBuildOptions: FormBuildOptionsInterface) => void
  setFormStyle: (style: unknown) => void
  setStep: ({ stepIndex, stepUid }: { stepIndex?: number; stepUid?: SectionId }) => void
  submit: () => void
  submitted: () => void
  updateData: (selector: FormSelector, value: unknown) => void
  refreshUi: () => void
}

function useFormAction(): [UseFormActionInterface, Dispatch<FormReducerAction>] {
  const [{ form, formBuildNo, formBuildOptions, ...state }, dispatch] = useReducer(
    formReducer,
    formInitialState,
    () => formInitialState,
  )

  useEffect(() => {
    if (formBuildOptions) {
      dispatch({ type: formTypes.FETCH_FORM })
      setTimeout(async () => {
        try {
          const formol: FormInterface = await build(formBuildOptions)
          if (formIsEmpty(formol)) {
            dispatch({ type: formTypes.FETCH_FORM_EMPTY })
          } else {
            const formNavigation = new FormNavigation(formol)
            dispatch({ type: formTypes.FETCH_FORM_SUCCESS, form: formol, ...formNavigation })
          }
        } catch (error: unknown) {
          dispatch({
            type: formTypes.FETCH_FORM_ERROR,
            error: { code: 400, msg: (error as Error)?.message || "Bad request", stackTrace: (error as Error)?.stack },
          })
        }
      })
    }
  }, [formBuildNo, formBuildOptions])

  useEffect(() => {
    if (state.formStatus === "SUCCESSFULLY_LOADED") {
      initStepIndex()
    }
  }, [state.formStatus])

  const clearAutofocusUid = (): void => {
    dispatch({ type: formTypes.CLEAR_AUTOFOCUS_UID })
  }

  const setAutofocusUid = (autofocusUid: SectionId): void => {
    dispatch({ type: formTypes.SET_AUTOFOCUS_UID, autofocusUid })
  }

  const initForm = (): void => {
    dispatch({ type: formTypes.FORM_INIT })
  }

  const initStepIndex = (): void => {
    const lastUpdatedUid = formBuildOptions?.lastUpdatedSelector
    if (lastUpdatedUid) {
      assertForm(form)
      const lastUpdatedProperty = form.selectors.get(lastUpdatedUid)?.property()
      if (lastUpdatedProperty?.isSection()) {
        setStep({ stepUid: lastUpdatedProperty.UID })
        return
      } else if (lastUpdatedProperty?.belongToSection()) {
        setStep({ stepUid: lastUpdatedProperty.sectionUID })
        return
      }
    }

    setStep({ stepIndex: 0 })
  }

  const resetData = (selector: SectionId) => {
    assertForm(form)

    dispatch({ type: formTypes.RESET_DATA_IN_PROGRESS })
    setTimeout(async () => {
      await form.reset(selector)
      dispatch({ type: formTypes.RESET_DATA_FINISHED, form, selector })
    })
  }

  const resetForm = () => {
    dispatch({ type: formTypes.FORM_RESET })
  }

  const setFormBuildOptions = (newFormBuildOptions: FormBuildOptionsInterface): void => {
    dispatch({ type: formTypes.SET_FORM_BUILD_OPTIONS, formBuildOptions: newFormBuildOptions })
  }

  const setFormStyle = (style: unknown): void => {
    dispatch({ type: formTypes.SET_FORM_STYLE, style })
  }

  const setStep = ({ stepIndex, stepUid }: { stepIndex?: number; stepUid?: SectionId }): void => {
    let newStepUid = stepUid
    let newStepIndex = stepIndex

    if (newStepUid && newStepIndex === undefined) {
      newStepIndex = state.navigableSectionUids.indexOf(newStepUid)
    }

    if (!newStepUid && newStepIndex !== undefined) {
      newStepUid = state.navigableSectionUids[newStepIndex]
    }

    const viewedSections = form?.meta.viewedSections ?? []

    const isFirstTimeViewingSection = !viewedSections.includes(newStepUid) || viewedSections.length === 1

    if (newStepIndex !== undefined && newStepIndex > -1 && newStepUid) {
      dispatch({ type: formTypes.SET_STEP, stepUid: newStepUid, stepIndex: newStepIndex, isFirstTimeViewingSection })
    }
  }

  const submit = (): void => {
    dispatch({ type: formTypes.SUBMIT_FORM })
  }

  const submitted = (): void => {
    dispatch({ type: formTypes.FORM_SUBMITTED })
  }

  const updateData = (selector: FormSelector, value: unknown): void => {
    assertForm(form)

    dispatch({ type: formTypes.UPDATE_DATA_IN_PROGRESS })
    setTimeout(async () => {
      await form.update(selector, value)
      dispatch({ type: formTypes.UPDATE_DATA_FINISHED, form, selector })
    })
  }

  const refreshUi = (): void => {
    assertForm(form)
    dispatch({ type: formTypes.REFRESH_IN_PROGRESS })
    setTimeout(async () => {
      await form.events.refreshAll()
      dispatch({ type: formTypes.REFRESH_FINISHED, form })
    })
  }

  const addItemTo = (selector: FormSelector): void => {
    assertForm(form)

    dispatch({ type: formTypes.ADD_ITEM_TO, selector })
    setTimeout(async () => {
      if (await form.selectors.expandArray(selector)) {
        dispatch({ type: formTypes.ADD_ITEM_SUCCEEDED, form, selector })
      } else {
        dispatch({ type: formTypes.ADD_ITEM_FAILED, selector })
      }
    })
  }

  const deleteItem = (selector: FormSelector, index: number): void => {
    assertForm(form)

    dispatch({ type: formTypes.DELETE_ITEM, selector })
    setTimeout(async () => {
      if (await form.selectors.spliceArray(selector, index)) {
        dispatch({ type: formTypes.DELETE_ITEM_SUCCEEDED, form, selector })
      } else {
        dispatch({ type: formTypes.DELETE_ITEM_FAILED, selector })
      }
    })
  }

  return [
    {
      form,
      formBuildNo,
      formBuildOptions,
      ...state,
      addItemTo,
      clearAutofocusUid,
      deleteItem,
      initForm,
      resetData,
      resetForm,
      setAutofocusUid,
      setFormBuildOptions,
      setFormStyle,
      setStep,
      submit,
      submitted,
      updateData,
      refreshUi,
    },
    dispatch,
  ]
}

export default useFormAction

function assertForm(form?: FormInterface): asserts form {
  if (!form) {
    throw new Error("Form not loaded in useFormAction")
  }
}
