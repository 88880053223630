import moment from "moment"

const convertMomentToString = (value, format) => {
  return Array.isArray(value) ? value.map((val) => formatMoment(val, format)) : formatMoment(value, format)
}

const convertStringToMoment = (value, format) => {
  return Array.isArray(value) ? value.map((val) => createMoment(val, format)) : createMoment(value, format)
}

const convertStringPeriodToMomentPeriod = ({ from, to }, format) => {
  return {
    from: from ? convertStringToMoment(from, format) : getBeginningOfToday(),
    to: to ? convertStringToMoment(to, format) : getEndOfToday(),
  }
}

const convertStringPeriodsToMomentPeriods = (periods, format) => {
  return periods.map((period) => convertStringPeriodToMomentPeriod(period, format))
}

const createMoment = (value, format) => {
  return value ? new moment(value, format, true) : ""
}

const formatMoment = (value, format) => {
  return value ? value.format(format) : ""
}

const getBeginningOfToday = () => {
  return moment().startOf("day")
}

const getEndOfToday = () => {
  return moment().endOf("day")
}

export { convertMomentToString, convertStringToMoment, convertStringPeriodsToMomentPeriods, getBeginningOfToday }
