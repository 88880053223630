import { SyntheticEvent } from "react"
import { FormInterface } from "@akinoxsolutions/formol"
import { CustomEventInterface } from "../components/interfaces"
import { UseMessagingActionInterface } from "../context/messaging/messagingInterfaces"

export class CustomEventsService {
  private form: FormInterface
  private customEvents: CustomEventInterface[]
  constructor(form: FormInterface, customEvents?: CustomEventInterface[]) {
    this.form = form
    this.customEvents = customEvents || []
  }

  getEventHandler(
    eventType: string,
    postCustomEvent: UseMessagingActionInterface["postCustomEvent"],
  ): ((e: SyntheticEvent) => void) | undefined {
    const events: CustomEventInterface[] = this.customEvents.filter((e) => e.eventType === eventType)
    let aggregatedHandler: ((e: SyntheticEvent) => void) | undefined = undefined
    if (events.length) {
      aggregatedHandler = (e: SyntheticEvent) => {
        e.preventDefault()
        for (const { context, eventType, miscHandlerName } of events) {
          if (miscHandlerName && this.form.miscHandlers && this.form.miscHandlers[miscHandlerName]) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ;(this.form.miscHandlers[miscHandlerName] as any)(e, context)
          }
          postCustomEvent(eventType, { context, miscHandlerName })
        }
      }
    }
    return aggregatedHandler
  }
}
