import React, { createContext, ReactNode } from "react"
import { formInitialState } from "./formReducer"
import useFormAction, { UseFormActionInterface } from "./useFormAction"

export const FormContext = createContext<UseFormActionInterface>(formInitialState as never)

interface FormProviderPropsInterface {
  children: ReactNode
}

export const FormProvider = ({ children }: FormProviderPropsInterface): JSX.Element => {
  const [state] = useFormAction()

  return <FormContext.Provider value={state}>{children}</FormContext.Provider>
}
