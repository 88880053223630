"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.replaceVars = exports.build = void 0;
const tslib_1 = require("tslib");
const FormBuilder_1 = require("./Form/FormBuilder");
const VarsProcessor_1 = require("./Schema/Processor/VarsProcessor");
function isOptionInterface(arg) {
    return arg && arg.schema;
}
const build = (...args) => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
    if ([1, 2].includes(args.length)) {
        if (isOptionInterface(args[0])) {
            return yield FormBuilder_1.FormBuilder.build(args[0]);
        }
        return yield FormBuilder_1.FormBuilder.buildFromSchema(args[0], args[1]);
    }
    throw new Error("Invalid Form build parameter(s)");
});
exports.build = build;
const replaceVars = (vars, def) => {
    return new VarsProcessor_1.VarsProcessor(vars).process(def);
};
exports.replaceVars = replaceVars;
