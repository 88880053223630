"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FailValidationValidator = void 0;
const tslib_1 = require("tslib");
const AbstractValidator_1 = require("./AbstractValidator");
class FailValidationValidator extends AbstractValidator_1.AbstractValidator {
    constructor(options, type) {
        super(type);
        this.options = options;
    }
    validate({ form }) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            for (const selector of this.options.selectors) {
                if (form.qualifications[selector] && form.validations[selector]) {
                    return this.formatResult([false, { code: "INVALID_VALUE", context: { selector } }], this.options);
                }
            }
            return [true, null];
        });
    }
}
exports.FailValidationValidator = FailValidationValidator;
