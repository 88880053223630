import React, { useContext } from "react"
import { ConfigContext } from "../../context/config/configState"
import { Html } from "../index"
import useTranslation from "../../hooks/useTranslation"
import styles from "./NavigationWarning.module.css"

export interface NavigationWarningPropsInterface {
  className?: string
}

export const NavigationWarning = ({ className }: NavigationWarningPropsInterface) => {
  const { translate } = useTranslation()
  const { readOnlyMode } = useContext(ConfigContext)

  if (readOnlyMode) {
    return null
  }

  const classNames = [styles.NavigationWarning]
  if (className) {
    classNames.push(className)
  }

  return <Html className={classNames.join(" ")} content={translate("eachUpdate")} id="navigation-warning" />
}
