"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdateState = void 0;
class UpdateState {
    constructor(form, selector, oldValue, newValue) {
        this.form = form;
        this.selector = selector;
        this.state = {
            oldValue: oldValue,
            newValue: newValue,
            oldQ: form.qualifications[selector],
            newQ: form.qualifications[selector],
            oldV: form.validations[selector],
            newV: form.validations[selector],
        };
    }
    static fromContext(ctx) {
        return new UpdateState(ctx.form, ctx.selector, ctx.oldData, ctx.data);
    }
    mutate() {
        this.state.newV = this.form.validations[this.selector];
        this.state.newQ = this.form.qualifications[this.selector];
    }
    hasQVChange() {
        return this.state.oldQ !== this.state.newQ || this.state.oldV !== this.state.newV;
    }
    hasChange() {
        return (this.state.oldQ !== this.state.newQ ||
            this.state.oldV !== this.state.newV ||
            this.state.oldValue !== this.state.newValue);
    }
}
exports.UpdateState = UpdateState;
