"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RegExpValidator = void 0;
const tslib_1 = require("tslib");
const AbstractValidator_1 = require("./AbstractValidator");
const RegExpPresets_1 = require("./RegExpPresets");
class RegExpValidator extends AbstractValidator_1.AbstractValidator {
    constructor(options, type) {
        super(type);
        this.options = options;
        const { flags, pattern, preset } = options;
        if (!preset && !pattern) {
            throw new Error("Missing regex preset or pattern option");
        }
        else if (preset && pattern) {
            throw new Error("Cannot specify a regex preset and a pattern at the same time, please specify one of them only");
        }
        if (preset) {
            const presetName = preset;
            if (!RegExpPresets_1.regExpPresets[presetName]) {
                throw new Error(`Cannot find regex preset named "${presetName}"`);
            }
            this.options.pattern = RegExpPresets_1.regExpPresets[presetName];
        }
        if (!flags) {
            this.options["flags"] = "gm";
        }
    }
    validate({ data }) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const result = new RegExp(this.options.pattern, this.options.flags).exec(data);
            if (result) {
                return [true, null];
            }
            return this.formatResult([false, { code: "REGEX_NO_MATCH", context: this.options }], this.options);
        });
    }
}
exports.RegExpValidator = RegExpValidator;
