import { SyntheticEvent } from "react"
import { PropertyInterface as FormolPropertyInterface } from "@akinoxsolutions/formol"
import { UiPropertyBaseInterface, UiPropertyInterface } from "../Property/UiProperty"

export interface LinkUiPropertyBaseInterface extends UiPropertyBaseInterface {
  external: boolean
  href?: string
  icon?: unknown
  label: string
  onClick?: (e: SyntheticEvent) => unknown
  onClickMiscHandlerKey?: string
  rel?: string
  target?: "_blank" | "_parent" | "_self" | "_top"
  title?: string
}

export interface LinkUiPropertyInterface extends LinkUiPropertyBaseInterface {
  toProps(): LinkUiPropertyBaseInterface
}

interface OptionsInterface {
  external: LinkUiPropertyInterface["external"]
  href: LinkUiPropertyInterface["href"]
  label: LinkUiPropertyInterface["label"]
  onClick: LinkUiPropertyInterface["onClick"]
  onClickMiscHandlerKey: LinkUiPropertyInterface["onClickMiscHandlerKey"]
  rel: LinkUiPropertyInterface["rel"]
  target: LinkUiPropertyInterface["target"]
  title: LinkUiPropertyInterface["title"]
}

export default class LinkUiProperty implements LinkUiPropertyInterface {
  private readonly _className?: LinkUiPropertyInterface["className"]
  private readonly _disabled?: LinkUiPropertyInterface["disabled"]
  private readonly _external: LinkUiPropertyInterface["external"] = false
  private readonly _href?: LinkUiPropertyInterface["href"]
  private readonly _icon?: LinkUiPropertyInterface["icon"]
  private readonly _id: LinkUiPropertyInterface["id"]
  private readonly _label: LinkUiPropertyInterface["label"]
  private readonly _name: LinkUiPropertyInterface["name"]
  private readonly _onClick?: LinkUiPropertyInterface["onClick"]
  private readonly _onClickMiscHandlerKey?: LinkUiPropertyInterface["onClickMiscHandlerKey"]
  private readonly _rel?: LinkUiPropertyInterface["rel"]
  private readonly _target: LinkUiPropertyInterface["target"]
  private readonly _title: LinkUiPropertyInterface["title"]

  constructor(
    property: FormolPropertyInterface,
    opts?: {
      disabled?: LinkUiPropertyInterface["disabled"]
      className?: LinkUiPropertyInterface["className"]
      icon?: LinkUiPropertyInterface["icon"]
      onClick?: LinkUiPropertyInterface["onClick"]
    },
  ) {
    const options: OptionsInterface = LinkUiProperty._getOptions(property)
    const isOnClickEventEnable = !opts?.disabled && (options.onClick || opts?.onClick)
    const onClickEvent = (event) => {
      if (options.onClick) {
        options.onClick(event)
      }
      if (opts?.onClick) {
        opts.onClick(event)
      }
    }

    this._className = opts?.className
    this._disabled = opts?.disabled
    this._id = property.UID
    this._name = property.UID
    this._external = Boolean(options.external)
    this._href = options.href
    this._icon = opts?.icon
    this._label = options.label
    this._onClick = isOnClickEventEnable ? onClickEvent : undefined
    this._onClickMiscHandlerKey = options.onClickMiscHandlerKey
    this._rel = options.rel
    this._target = options.target ?? (options.external ? "_blank" : "_self")
    this._title = options.title
  }

  private static _getOptions(property: FormolPropertyInterface): OptionsInterface {
    const { label, ui = {} } = property.def
    const { options: uiOptions, linkOptions } = ui
    return { label, ...uiOptions, ...linkOptions }
  }

  get className(): UiPropertyInterface["className"] | undefined {
    return this._className
  }

  get disabled(): UiPropertyInterface["disabled"] | undefined {
    return this._disabled
  }

  get external(): boolean {
    return this._external
  }

  get href(): LinkUiPropertyInterface["href"] | undefined {
    return this._href
  }

  get icon(): LinkUiPropertyInterface["icon"] | undefined {
    return this._icon
  }

  get id(): UiPropertyInterface["id"] {
    return this._id
  }

  get label(): LinkUiPropertyInterface["label"] {
    return this._label
  }

  get name(): LinkUiPropertyInterface["name"] {
    return this._name
  }

  get onClick(): LinkUiPropertyInterface["onClick"] | undefined {
    return this._onClick
  }

  get onClickMiscHandlerKey(): LinkUiPropertyInterface["onClickMiscHandlerKey"] | undefined {
    return this._onClickMiscHandlerKey
  }

  get rel(): LinkUiPropertyInterface["rel"] | undefined {
    return this._rel
  }

  get target(): LinkUiPropertyInterface["target"] {
    return this._target
  }

  get title(): LinkUiPropertyInterface["title"] {
    return this._title
  }

  toProps(): LinkUiPropertyBaseInterface {
    return {
      className: this.className,
      disabled: this.disabled as boolean,
      external: this.external,
      href: this.href,
      icon: this.icon,
      id: this.id,
      label: this.label,
      name: this.name,
      onClick: this.onClick,
      onClickMiscHandlerKey: this.onClickMiscHandlerKey,
      rel: this.rel,
      target: this.target,
      title: this.title,
    }
  }
}
