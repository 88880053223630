export const isValidJsonString = (jsonString: string): boolean => {
  try {
    JSON.parse(jsonString)
    return true
  } catch (e) {
    return false
  }
}

export const isValidJsonObject = (jsonObject: Record<string, unknown>): boolean => {
  try {
    JSON.stringify(jsonObject)
    return true
  } catch (e) {
    return false
  }
}

export const safeJSONParse = (data: string): [boolean, Record<string, unknown>] => {
  try {
    const json = JSON.parse(data)
    return [true, json]
  } catch (e) {
    const error = (e + "").split("at JSON.parse")[0]
    return [false, { error }]
  }
}
