"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PropertyScorePlugin = void 0;
const PluginBase_1 = require("../../Form/Plugin/PluginBase");
const PropertyScore_1 = require("./PropertyScore");
class PropertyScorePlugin extends PluginBase_1.PluginBase {
    constructor(opts) {
        super();
        this.name = "propertyScore";
        this.scores = [];
        if (!Array.isArray(opts)) {
            opts = [opts];
        }
        for (const o of opts) {
            this.scores.push(new PropertyScore_1.PropertyScore(o));
        }
    }
    formCreated(ctx) {
        for (const score of this.scores) {
            score.formCreated(ctx);
        }
    }
    dataUpdated(ctx) {
        for (const score of this.scores) {
            score.dataUpdated(ctx);
        }
    }
    eventsRefreshed({ form }) {
        for (const score of this.scores) {
            score.calculate({ form });
        }
    }
    selectorAdded({ form, selector }) {
        for (const score of this.scores) {
            score.addSelector(selector, form);
        }
    }
    selectorRemoved({ form, selector }) {
        for (const score of this.scores) {
            score.removeSelector(selector, form);
        }
    }
    formPass(ctx) {
        for (const score of this.scores) {
            score.formPass(ctx);
        }
    }
}
exports.PropertyScorePlugin = PropertyScorePlugin;
