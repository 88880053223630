/**
 * For now SignalR is only used on the Upload component so everything related to SignalR
 * is setup here in this file. If there is ever a need to use SignalR elsewhere, most of
 * the logic here should be moved to a more global file
 */
import { useEffect } from "react"
import * as signalR from "@microsoft/signalr"

interface UseSignalROptionsInterface {
  updateHubUrl: string
  callback: (e: Event) => void
}

type Event = {
  type: string
  payload: unknown
}

type Update = {
  callback: ((e: Event) => void) | null
  queued: Event[]
}

const updates: Record<string, Update> = {}

export const useAttachmentUpdatedSignalR = ({ updateHubUrl, callback }: UseSignalROptionsInterface) => {
  useEffect(() => {
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(updateHubUrl)
      .withAutomaticReconnect([0, 1000, 5000, 15000, 30000])
      .build()

    connection.on("AttachmentUpdated", (id, status) => {
      const payload = { id, status }
      dispatchEvent("AttachmentUpdated", payload)
    })

    connection.start().catch((err) => {
      /* eslint-disable no-console */
      return console.error(err.toString())
    })

    subscribeToUpdates("Upload", (e: Event) => {
      if (e.type === "AttachmentUpdated") {
        callback(e)
      }
    })

    return () => {
      unsubscribeFromUpdates("Upload")
    }
  }, [])
}

function dispatchEvent(type, payload) {
  const evt = { type, payload }
  Object.values(updates).forEach((u) => {
    if (u.callback) {
      u.callback(evt)
    } else {
      u.queued.push(evt)
    }
  })
}

function subscribeToUpdates(name: string, callback: (e: Event) => void) {
  if (!updates[name]) {
    updates[name] = {
      callback,
      queued: [],
    }
  } else {
    updates[name].callback = callback
    while (updates[name].queued.length > 0) {
      const item = updates[name].queued.shift()
      callback(item as Event)
    }
  }
}

function unsubscribeFromUpdates(name: string) {
  if (updates[name]) {
    updates[name].callback = null
  }
}
