"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ArraySelector = void 0;
const tslib_1 = require("tslib");
const Property_1 = require("../../Schema/Property/Property");
const ArrayExpander_1 = require("./Array/ArrayExpander");
const ArrayReducer_1 = require("./Array/ArrayReducer");
const AbstractArrayOperation_1 = require("./Array/AbstractArrayOperation");
const PluginEvents_1 = require("../Plugin/PluginEvents");
class ArraySelector extends AbstractArrayOperation_1.AbstractArrayOperation {
    get keys() {
        return Object.keys(this.selectors);
    }
    get values() {
        return Object.values(this.selectors);
    }
    get selectors() {
        return this._selectors;
    }
    constructor(form) {
        super();
        this.form = form;
        this._selectors = {};
        this.arrayExpander = new ArrayExpander_1.ArrayExpander();
        this.arrayReducer = new ArrayReducer_1.ArrayReducer();
    }
    mapSelectors() {
        this._selectors = {};
        for (const selInfos of this.form.selectors.values) {
            if (selInfos.type === Property_1.Property.TYPE_ARRAY) {
                this.selectors[selInfos.UID] = selInfos;
            }
        }
    }
    expand(selector) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const result = yield this.arrayExpander.expand(selector, this.form);
            if (!result) {
                return false;
            }
            this.mapSelectors();
            this.form.schema.nodes.mapGroups();
            this.form.schema.nodes.mapArrays();
            yield this.form.events.refreshAll();
            yield this.form.compileStats();
            yield this.form.nodes.sections.updateValidations();
            this.form.nodes.groups.updateValidations();
            this.form.nodes.arrays.updateValidations();
            this.form.plugins.triggerEvent(PluginEvents_1.PluginEvents.EventsRefreshed, {
                form: this.form,
            });
            return true;
        });
    }
    reduce(selector) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const result = yield this.arrayReducer.reduce(selector, this.form);
            if (!result) {
                return false;
            }
            this.mapSelectors();
            this.form.schema.nodes.mapGroups();
            this.form.schema.nodes.mapArrays();
            yield this.form.events.refreshAll();
            yield this.form.compileStats();
            yield this.form.nodes.sections.updateValidations();
            this.form.nodes.groups.updateValidations();
            this.form.nodes.arrays.updateValidations();
            this.form.plugins.triggerEvent(PluginEvents_1.PluginEvents.EventsRefreshed, {
                form: this.form,
            });
            return true;
        });
    }
    splice(selector, index) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const result = yield this.arrayReducer.splice(selector, index, this.form);
            if (!result) {
                return false;
            }
            this.mapSelectors();
            this.form.schema.nodes.mapGroups();
            this.form.schema.nodes.mapArrays();
            yield this.form.schema.clearAllCachedSelectors();
            yield this.form.events.refreshAll();
            yield this.form.compileStats();
            yield this.form.nodes.sections.updateValidations();
            this.form.nodes.groups.updateValidations();
            this.form.nodes.arrays.updateValidations();
            this.form.plugins.triggerEvent(PluginEvents_1.PluginEvents.EventsRefreshed, {
                form: this.form,
            });
            return true;
        });
    }
    setMinItems(selector, newMin) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const arrayProp = this.form.schema.search(selector);
            if (!this.isArray(selector, this.form) || !arrayProp) {
                return false;
            }
            arrayProp.arrayMinItems = newMin;
            const currentData = this.form.dataSelector.get(selector);
            const diff = newMin - currentData.length;
            if (diff > 0) {
                for (let i = 0; i < diff; ++i) {
                    yield this.expand(selector);
                }
                return true;
            }
            return false;
        });
    }
    setMaxItems(selector, newMax) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const arrayProp = this.form.schema.search(selector);
            if (!this.isArray(selector, this.form) || !arrayProp) {
                return false;
            }
            arrayProp.arrayMaxItems = newMax;
            const currentData = this.form.dataSelector.get(selector);
            const diff = currentData.length - newMax;
            if (diff > 0) {
                for (let i = 0; i < diff; ++i) {
                    yield this.reduce(selector);
                }
            }
            return false;
        });
    }
}
exports.ArraySelector = ArraySelector;
