import React, { useContext, useEffect } from "react"
import useTranslation from "../../hooks/useTranslation"
import { ConfigContext } from "../../context/config/configState"
import { FormContext } from "../../context/form/formState"
import { MessagingContext } from "../../context/messaging/messagingState"
import { Property } from "../../components/Property/Property"
import { StepWarning } from "../../components/StepWarning/StepWarning"
import styles from "./StepLayout.module.css"

export const StepLayout = () => {
  const { translate } = useTranslation()
  const { readOnlyMode } = useContext(ConfigContext)
  const { postNavigateToMessage } = useContext(MessagingContext)
  const { form, formOptions, navigableSectionUids, stepIndex } = useContext(FormContext)
  const uid = navigableSectionUids[stepIndex]
  const property = form.selectors.get(uid).property()
  const { readOnly, subtitle, ui = {} } = property.def
  const { sectionOptions: { showSectionWarnings } = {} } = ui
  const isReadOnly = readOnly || readOnlyMode || (formOptions.readOnlyMode && readOnlyMode !== false)

  useEffect(() => {
    window.scrollTo(0, 0)
    postNavigateToMessage(uid)
  }, [stepIndex])

  return (
    <section id={`step-${uid}`} className={styles.StepLayout}>
      <Property
        isDivided
        parentIsReadOnly={isReadOnly}
        property={property}
        title={subtitle ? translate(subtitle) : undefined}
      />
      {showSectionWarnings && <StepWarning sectionUid={uid} />}
    </section>
  )
}
