import React, { ReactNode } from "react"
import { PropertyInterface } from "@akinoxsolutions/formol"
import useTranslation from "../../hooks/useTranslation"
import { Html } from "../index"
import styles from "./Item.module.css"

export interface ItemPropsInterface {
  children: ReactNode
  className?: string
  property: PropertyInterface
}

const Item = ({ children, className, property }: ItemPropsInterface): JSX.Element => {
  const { translate } = useTranslation()
  const { ui = {} } = property.def
  const { prefix, suffix } = ui
  const classNames: string[] = [styles.ItemWrapper]
  if (className) {
    classNames.push(className)
  }

  return (
    <div id={`${property.UID}-wrapper`} className={classNames.join(" ")}>
      {prefix && <Html className={styles.Prefix} content={translate(prefix)} />}
      {children}
      {suffix && <Html className={styles.Suffix} content={translate(suffix)} />}
    </div>
  )
}

export default Item
