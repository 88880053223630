import React from "react"
import { getStyle } from "../../utils/uiUtils"
import { Properties } from "../Properties/Properties"
import styles from "./Table.module.css"

export const Table = ({ isDisabled, isReadOnly, property }) => (
  <div className={styles.TableContent} style={getStyle(property.def.ui ?? {})}>
    {[...property.childProps().values()].map((childProperty) => {
      const isHeader = childProperty.def.ui?.options?.header
      return (
        <Properties
          className={isHeader ? styles.TableHeader : styles.TableRow}
          key={childProperty.UID}
          parentIsDisabled={isDisabled}
          parentIsReadOnly={isReadOnly}
          property={childProperty}
        />
      )
    })}
  </div>
)
