import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import resources from "./locales/resources"
import LanguageDetector from "i18next-browser-languagedetector"
import { Logger, replaceVars } from "@akinoxsolutions/formol"

const getStandardizeLanguage = (language) => language.toLowerCase().slice(0, 2)

const customNamespace = "custom"

const addTranslations = (translations, opts = {}) => {
  let isValid = true

  if (typeof translations === "object") {
    if (Array.isArray(translations)) {
      for (const currentTranslations of translations) {
        addTranslations(currentTranslations, opts)
      }
    } else {
      const { vars = {}, deep = true, overwrite = true } = opts

      Object.entries(translations).forEach(([language, languageTranslations]) => {
        if (languageTranslations) {
          const standardizedLanguage = getStandardizeLanguage(language)
          if (isSupportedLanguage(standardizedLanguage)) {
            i18n.addResourceBundle(
              standardizedLanguage,
              customNamespace,
              replaceVars(vars, languageTranslations),
              deep,
              overwrite,
            )
          } else {
            Logger.log(`Translations cannot be loaded: "${language}" is not a supported language.`, {
              language,
              supportedLanguages: i18n.languages,
            })
            isValid = false
          }
        }
      })
    }
  }
  return isValid
}

const changeLanguage = (language) => {
  if (language) {
    const standardizedLanguage = getStandardizeLanguage(language)
    if (isSupportedLanguage(standardizedLanguage)) {
      if (i18n.language !== standardizedLanguage) {
        i18n.changeLanguage(standardizedLanguage)
        return true
      }
    } else {
      Logger.log(`Language cannot be changed: "${language}" is not a supported language.`, {
        language,
        supportedLanguages: i18n.languages,
      })
    }
  } else {
    Logger.log("Language cannot be changed: no language were specified", { supportedLanguages: i18n.languages })
  }
  return false
}

const getAllResources = () => {
  const allResources = {
    [customNamespace]: {},
    translation: {},
  }
  for (const language of i18n.languages) {
    allResources[customNamespace][language] = i18n.getResourceBundle(language, customNamespace)
    allResources["translation"][language] = i18n.getResourceBundle(language, "translation")
  }
  return allResources
}

const getCustomResources = () => {
  const customResources = {}
  for (const language of i18n.languages) {
    customResources[language] = i18n.getResourceBundle(language, customNamespace)
  }
  return customResources
}

const removeCustomNamespace = () => {
  for (const language of i18n.languages) {
    i18n.removeResourceBundle(language, customNamespace)
  }
}

const isSupportedLanguage = (language) => language && i18n.languages.includes(language)

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: process?.env?.NODE_ENV === "development",
    lng: "fr",
    fallbackLng: Object.keys(resources),
    fallbackNS: "translation",
    defaultNS: customNamespace,
    nsSeparator: "::",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    react: {
      bindI18n: "languageChanged",
      bindI18nStore: "added",
      useSuspense: true,
    },
    resources,
  })

export default i18n
export {
  addTranslations,
  changeLanguage,
  customNamespace,
  getAllResources,
  getCustomResources,
  getStandardizeLanguage,
  removeCustomNamespace,
}
