import React, { useContext } from "react"
import Switch from "antd/lib/switch"
import { prettifyJsonString } from "../../../utils/devUtils"
import { themes } from "../../../context/devPanel/themes"
import { DevPanelContext } from "../../../context/devPanel/devPanelState"
import styles from "./PanelMenu.module.css"

export const PanelMenu = () => {
  const {
    autoUpdate,
    editorTheme,
    isEditable,
    reloadForm,
    schema,
    schemaIsValid,
    setAutoUpdate,
    setEditorTheme,
    setIsEditable,
    setSchema,
  } = useContext(DevPanelContext)

  const onChangeEditorTheme = (e) => setEditorTheme(e.target.value)
  const prettify = () => setSchema(prettifyJsonString(schema))
  const toggleAutoUpdate = () => setAutoUpdate(!autoUpdate)
  const toggleIsEditable = () => setIsEditable(!isEditable)

  return (
    <div className={styles.PanelMenu}>
      <Switch
        checked={isEditable}
        checkedChildren="Editable"
        onChange={toggleIsEditable}
        unCheckedChildren="From loaded form"
      />
      <Switch
        checked={autoUpdate}
        checkedChildren="Update automatically"
        disabled={!isEditable}
        onChange={toggleAutoUpdate}
        unCheckedChildren="Update manually"
      />
      <button className={styles.Button} type="button" disabled={!isEditable || !schemaIsValid} onClick={reloadForm}>
        Update
      </button>
      <button className={styles.Button} type="button" disabled={!isEditable} onClick={prettify}>
        Prettify
      </button>
      <select onChange={onChangeEditorTheme} value={editorTheme}>
        {themes.map((theme) => (
          <option key={theme} value={theme}>
            {theme}
          </option>
        ))}
      </select>
    </div>
  )
}
