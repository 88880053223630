"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SchemaGroupsMapper = void 0;
const PropertyGroup_1 = require("../Property/PropertyGroup");
class SchemaGroupsMapper {
    map(schema) {
        return this._map(new Map(), schema.childProps());
    }
    _map(groups, props) {
        props.forEach((prop) => {
            if (prop.get("group", false)) {
                groups.set(prop.UID, new PropertyGroup_1.PropertyGroup(prop));
            }
            else if (prop.hasChildProps()) {
                groups = new Map([
                    ...groups,
                    ...this._map(new Map(), prop.childProps()),
                ]);
            }
        });
        return groups;
    }
}
exports.SchemaGroupsMapper = SchemaGroupsMapper;
