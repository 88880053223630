"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LanguageValidator = void 0;
const tslib_1 = require("tslib");
const AbstractValidator_1 = require("./AbstractValidator");
class LanguageValidator extends AbstractValidator_1.AbstractValidator {
    constructor(options, type) {
        super(type);
        this.options = options;
    }
    validate({ form }) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return [this.options.equalsOneOf.includes(form.language || ""), null];
        });
    }
}
exports.LanguageValidator = LanguageValidator;
