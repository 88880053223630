import React from "react"
import {
  convertMomentToString,
  convertStringToMoment,
  convertStringPeriodsToMomentPeriods,
} from "../../utils/dateUtils"
import {
  getHoursRangeNumbersFromPeriods,
  getMinutesRangeNumbersFromPeriods,
  getSecondsFromPeriods,
} from "../../utils/timeUtils"
import { TimePicker as GerudoTimePicker } from "@akinoxsolutions/gerudo-ui/dist/TimePicker"
import useTranslation from "../../hooks/useTranslation"
import styles from "./TimePicker.module.css"

export const TimePicker = ({ autofocus, isDisabled, isReadOnly, onBlur, onChange, property, value, ...otherProps }) => {
  const { translate } = useTranslation()

  const {
    def: {
      ui: {
        size,
        timePickerOptions: { disabledPeriods = [], format, inputReadOnly, placeholder, ...timePickerOptions } = {},
      } = {},
    },
  } = property

  const periods = convertStringPeriodsToMomentPeriods(disabledPeriods, format)

  const disabledHours = () => {
    return getHoursRangeNumbersFromPeriods(periods)
  }

  const disabledMinutes = (selectedHours) => {
    return getMinutesRangeNumbersFromPeriods(periods, disabledHours(), selectedHours)
  }

  const disabledSeconds = (selectedHours, selectedMinutes) => {
    return getSecondsFromPeriods(periods, disabledHours(), selectedHours, disabledMinutes(), selectedMinutes)
  }

  return (
    <GerudoTimePicker
      autoFocus={autofocus}
      blur={onBlur}
      className={styles.TimePicker}
      disabled={isDisabled || isReadOnly}
      disabledHours={disabledHours}
      disabledMinutes={disabledMinutes}
      disabledSeconds={disabledSeconds}
      inputReadOnly={inputReadOnly ?? true}
      onChange={(e) => onChange(convertMomentToString(e, format))}
      placeholder={placeholder ? translate(placeholder) : undefined}
      value={convertStringToMoment(value, format)}
      format={format}
      size={size}
      {...otherProps}
      {...timePickerOptions}
    />
  )
}
