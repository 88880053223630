import React, { useState, useEffect } from "react"
import { getSubType } from "../../../utils/uiUtils"
import useTranslation from "../../../hooks/useTranslation"
import Input, { InputMaskValidator as GerudoInputMask } from "@akinoxsolutions/gerudo-ui/dist/Input"
import { masks } from "./Masks"
import { getInputMaskRegExpPattern } from "./InputMask.helpers"

export const InputMask = ({ autofocus, id, isDisabled, isReadOnly, name, onBlur, onChange, property, value = "" }) => {
  const { translate } = useTranslation()
  const { maxLength, ui = {}, validations } = property.def

  const mask = masks.getMask(getSubType(ui.type))
  const defaultMask = masks.getDefaultMask()

  const [fieldValue, setFieldValue] = useState(value)

  const getMaskElement = (element) => ui[element] ?? mask[element] ?? defaultMask[element]

  const ariaLabel = translate(getMaskElement("ariaLabel"))
  const inputType = getMaskElement("inputType")
  const maskChar = getMaskElement("maskChar")
  const maskString = getMaskElement("mask")
  const placeholder = translate(getMaskElement("placeholder"))
  const regExpPattern = getInputMaskRegExpPattern(validations)
  const showCustomInput = ui.inputPrefix

  const onChangeHandler = (e) => {
    const newValue = e.target.value

    setFieldValue(newValue)

    // make sure to change the state field value only once it passes the regex to update internal value.
    // validation is on blur.
    if (regExpPattern && (regExpPattern.test(newValue) || (regExpPattern.test(value) && value !== newValue))) {
      onChange(newValue)
    }
  }

  const onBlurHandler = (e) => {
    const newValue = e.target.value

    onChange(newValue)
    onBlur(e)
  }

  useEffect(() => {
    if (value !== fieldValue) {
      setFieldValue(value)
    }
  }, [value])

  return (
    <GerudoInputMask
      ariaLabel={ariaLabel}
      autoFocus={autofocus}
      disabled={isDisabled || isReadOnly}
      id={id}
      mask={maskString}
      maskChar={maskChar}
      maxLength={maxLength}
      name={name}
      onBlur={onBlurHandler}
      onChange={onChangeHandler}
      placeholder={placeholder}
      size={ui.size}
      type={inputType}
      value={fieldValue ?? ""}
    >
      {showCustomInput && ((inputProps) => <Input {...inputProps} prefix={ui.inputPrefix} />)}
    </GerudoInputMask>
  )
}
