import React, { useContext, useEffect, useState } from "react"
import MiniSearch, { SearchResult } from "minisearch"
import { RadioCardGroup } from "../index"
import { SearchInput } from "@akinoxsolutions/gerudo-ui/dist/Input"
import { AnswerableComponentPropsInterface } from "../interfaces"
import { FormContext } from "../../context/form/formState"
import useTranslation from "../../hooks/useTranslation"

import styles from "./SearchableRadioCard.module.css"
export interface SearchableRadioCardInterface extends AnswerableComponentPropsInterface<string | string[]> {}

const SearchableRadioCard = ({
  property,
  onChange,
  isDisabled,
  isReadOnly,
  autofocus,
  value,
  ...otherProps
}: SearchableRadioCardInterface) => {
  if (isDisabled || isReadOnly) return <></>

  const { translate } = useTranslation()

  const [searchText, setSearchText] = useState("")
  const { enum: searchableFields, ui } = property.def
  const { miniSearchOptions, inputProps } = ui

  let { placeholder, searchingPlaceholder, continueSearchingPlaceholder, searchingTimeDelay } = inputProps || {}

  placeholder = placeholder ? translate(placeholder) : translate("search.placeholder")

  // text to display when the search is in progress
  searchingPlaceholder = searchingPlaceholder ? translate(searchingPlaceholder) : null

  // text if less than three characters are entered
  continueSearchingPlaceholder = continueSearchingPlaceholder ? translate(continueSearchingPlaceholder) : null

  // delay before displaying the searchingPlaceholder in ms
  searchingTimeDelay = searchingTimeDelay || 0

  const { updateData, refreshUi, form } = useContext(FormContext)

  if (!form) return null

  const [initialSearchFields] = useState(searchableFields)

  const [enumerable, setEnumerable] = useState(null as SearchResult[] | null)
  const [isSearchingVisible, setIsSearchingVisible] = useState(false)
  const [isContinueSearchingVisible, setIsContinueSearchingVisible] = useState(false)

  // Tokenize search term and indexing fields on whitespace and apostrophes, removing special characters
  const tokenize = (string: string) =>
    string
      .normalize("NFD")
      .replace(/[^\w\s]|_/g, "")
      .match(/\b\w+\b/g)

  // Process search term that are less than 3 characters, skipping common stop-words both in French and in English
  const processTerm = (term: string) => (term.length < 3 ? null : term.toLowerCase())

  // Add the tokenize and processTerm functions to the search options, which are used on search input
  miniSearchOptions.searchOptions = { ...miniSearchOptions.searchOptions, tokenize: tokenize, processTerm: processTerm }

  // Add the tokenize and processTerm functions to the search options, which are used on indexing terms
  const searchOptions = { ...miniSearchOptions, tokenize: tokenize, processTerm: processTerm }

  const miniSearch = new MiniSearch(searchOptions)

  const { searchStatusVar, searchInput, fieldSelectors } = miniSearchOptions

  const onChangeRadioCard = (selectedValue: string | null | undefined) => {
    onChange && onChange(selectedValue)
    updateData(property.UID, selectedValue)
  }

  const translatedFields = () => {
    return initialSearchFields.map((field) => {
      return { ...field, label: translate(field.label), subtitle: translate(field.subtitle) }
    })
  }

  miniSearch.addAll(translatedFields())

  const getProperty = () => {
    property.def.enum = enumerable
    return form.selectors.get(property.UID).property()
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchText = event.target.value

    // hide the searchingPlaceholder while typing
    setIsSearchingVisible(false)
    setIsContinueSearchingVisible(false)

    // On change, reset the value of the property and all the fields selectors
    updateData(property.UID, null)

    fieldSelectors.forEach((fieldSelector: string) => {
      updateData(fieldSelector, null)
    })

    setSearchText(searchText)

    if (searchInput) {
      form.dataSelector.set(searchInput, searchText)
    }

    const emptySearch = searchText === "" || !searchText

    setEnumerable(emptySearch ? null : miniSearch.search(searchText))

    if (searchStatusVar) {
      form.vars[searchStatusVar] = !emptySearch
      refreshUi()
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsSearchingVisible(searchText.length >= 3 && enumerable && enumerable?.length == 0 && searchingPlaceholder)
      // show the continueSearchingPlaceholder if the search term is less than 3 characters
      setIsContinueSearchingVisible(!!(searchText.length < 3 && searchText.length > 0 && continueSearchingPlaceholder))
    }, searchingTimeDelay)

    // clear on unmount
    return () => clearTimeout(timer)
  }, [enumerable])

  return (
    <>
      <SearchInput
        autoFocus={autofocus}
        allowClear
        onChange={handleChange}
        placeholder={placeholder}
        value={searchText}
      />
      {/* This is in a div with a set min-height to avoid a weird iframe resizing because of the delay in the placeholder */}
      {enumerable && enumerable.length == 0 && (
        <div className={styles["no-results"]}>
          {isSearchingVisible && <p>{searchingPlaceholder}</p>}
          {isContinueSearchingVisible && <p>{continueSearchingPlaceholder}</p>}
        </div>
      )}
      {enumerable && (
        <RadioCardGroup
          autofocus={autofocus}
          onChange={onChangeRadioCard}
          property={getProperty()}
          value={value as string | undefined}
          {...otherProps}
        />
      )}
    </>
  )
}

export default SearchableRadioCard
