import React from "react"
import { Textarea as GerudoTextArea } from "@akinoxsolutions/gerudo-ui/dist/Input"
import styles from "./TextArea.module.css"

export const TextArea = ({
  autofocus,
  disabled,
  id,
  maxLength,
  name,
  onBlur,
  onChange,
  placeholder,
  showCount = false,
  value,
  ...otherProps
}) => (
  <div className={styles.TextArea}>
    <GerudoTextArea
      autoFocus={autofocus}
      disabled={disabled}
      id={id}
      maxLength={maxLength}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      placeholder={placeholder}
      showCount={showCount}
      value={value}
      {...otherProps}
    />
  </div>
)
