import React, { useContext, useEffect, useState } from "react"
import { PropertyInterface } from "@akinoxsolutions/formol"
import { FormContext } from "../../context/form/formState"
import { UseFormActionInterface } from "../../context/form/useFormAction"
import loaderService from "../../services/LoaderService"
import { BodyPartPicker } from "../BodyPartPicker/BodyPartPicker"
import { DatePicker } from "../DatePicker/DatePicker"
import { Input } from "../Input/Input"
import { InputMask } from "../Input/InputMask/InputMask"
import { InputNumber } from "../Input/InputNumber"
import { Select } from "../Select/Select"
import { TimePicker } from "../TimePicker/TimePicker"
import { AnswerableItem, Checkbox, CheckboxGroup, RadioGroup, RadioCardGroup, Switch, Upload } from "../index"

export type AnswerableType =
  | typeof BodyPartPicker
  | typeof Checkbox
  | typeof CheckboxGroup
  | typeof DatePicker
  | typeof InputMask
  | typeof RadioGroup
  | typeof RadioCardGroup
  | typeof Select
  | typeof Switch
  | typeof Input
  | typeof TimePicker
  | typeof Upload
  | typeof InputNumber

export interface AnswerablePropsInterface {
  Component: AnswerableType
  data: unknown
  isDisabled: boolean
  isReadOnly: boolean
  property: PropertyInterface
  updateData: UseFormActionInterface["updateData"]
}

const Answerable = ({
  Component,
  data,
  property,
  updateData,
  ...componentProps
}: AnswerablePropsInterface): JSX.Element => {
  const { ui = {} } = property.def
  const { autofocus, debounceDelay, instantUpdate } = ui
  const { autofocusUid, clearAutofocusUid, form }: UseFormActionInterface = useContext(FormContext)
  if (!form) return <></>

  const [value, setValue] = useState<unknown>(form?.dataSelector.get(property.UID))

  const executeUpdate = () => {
    if (data !== value) {
      updateData(property.UID, value)
    }
  }
  const onBlur = () => loaderService.forceDebounce(property.UID)
  const onChange = (newValue) => setValue(newValue)

  useEffect(() => {
    if (!loaderService.isDebouncing(property.UID)) {
      setValue(form?.dataSelector.get(property.UID))
    }
  }, [property])

  useEffect(() => {
    // It is possible that at the time of the render here the data is stale because
    // it was already updated in the form. If it is the case, we ignore it and a
    // subsequent render will eventually trigger this useEffect with up-to-date data
    const isStaleData = data !== form.dataSelector.get(property.UID)
    if (isStaleData) return

    if (!loaderService.isDebouncing(property.UID)) {
      setValue(data)
    }
  }, [data])

  useEffect(() => {
    if (instantUpdate) {
      executeUpdate()
    } else {
      loaderService.debounce({ fnc: executeUpdate, uid: property.UID }, debounceDelay)()
    }
  }, [value])

  useEffect(() => {
    if (autofocusUid === property.UID) {
      clearAutofocusUid()
    }
  }, [autofocusUid])

  return (
    <AnswerableItem property={property} value={data}>
      <Component
        autofocus={autofocusUid ? autofocusUid === property.UID : autofocus === true}
        id={property.UID}
        name={property.UID}
        onBlur={onBlur}
        onChange={onChange}
        property={property}
        value={value}
        {...componentProps}
      />
    </AnswerableItem>
  )
}

export default Answerable
