import React, { useContext } from "react"
import { createGlobalStyle } from "styled-components"
import { versions } from "../../utils/devUtils"
import { ConfigContext } from "../../context/config/configState"
import { FormContext } from "../../context/form/formState"
import { DevPanel } from "../DevPanel/DevPanel"
import { FormRenderer } from "../FormRenderer/FormRenderer"
import { LoadingBar } from "../../components"
import { MessageLayout } from ".."
import styles from "./Viewer.module.css"

const { sdk: sdkVersion, lib: libVersion } = versions()

const GlobalStyle = createGlobalStyle`
    div#gerudo {
      ${(props) => props}
    }
  `

export const Viewer = () => {
  const { customStyle, displayDevPanel } = useContext(ConfigContext)
  const { formStatus, isUpdating, style: formStyle } = useContext(FormContext)

  const style = { ...formStyle, ...customStyle }

  return (
    <div data-sdk-version={sdkVersion} data-lib-version={libVersion} className={styles.Viewer}>
      <GlobalStyle {...style} />
      <div className={formStatus === "LOADING_IN_PROGRESS" || isUpdating ? styles.Loading : undefined}>
        {isUpdating && <LoadingBar className={styles.LoadingBar} />}

        <MessageLayout />

        {formStatus === "SUCCESSFULLY_LOADED" && <FormRenderer />}
      </div>
      {displayDevPanel && (
        <div className={styles.DevPanelWrapper}>
          <DevPanel />
        </div>
      )}
    </div>
  )
}
