import React, { useContext } from "react"
import { FormContext } from "../../context/form/formState"
import { SubmitButton, CustomFormActions } from "../index"
import styles from "./NavigationButtons.module.css"

export const NavigationButtons = () => {
  const { formOptions } = useContext(FormContext)
  const classNames = [styles.NavigationButtons]
  if (formOptions.fixedNavigationButtons) {
    classNames.push(styles.Fixed)
  }

  return (
    <section id="navigation-buttons" className={classNames.join(" ")}>
      <SubmitButton />
      <CustomFormActions />
    </section>
  )
}
