"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SchemaEventsService = void 0;
const tslib_1 = require("tslib");
const Events_1 = require("../Event/Events");
const Property_1 = require("../../Schema/Property/Property");
class SchemaEventsService {
    constructor(form, validatorFactory) {
        this.form = form;
        this.validatorFactory = validatorFactory;
    }
    registerEvents() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.parse(this.form.schema.childProps());
            yield this.form.events.refresh([]);
            yield this.form.events.triggerEvent(Events_1.Events.DataUpdated, { form: this.form });
        });
    }
    parse(props) {
        props.forEach((prop) => {
            if (prop.def.validations && prop.isAnswerable()) {
                this.registerSelectorValidators(Events_1.Events.DataValidation, prop, prop.def.validations);
            }
            if (prop.def.qualifications) {
                if (prop.type === Property_1.Property.TYPE_OBJECT) {
                    this.registerQualificationValidators(prop);
                }
                else {
                    this.registerSelectorValidators(Events_1.Events.DataQualification, prop, prop.def.qualifications);
                }
            }
            if (prop.hasChildProps()) {
                this.parse(prop.childProps());
            }
        });
    }
    registerSelectorValidators(type, prop, validators) {
        for (const [validatorName, validatorOpts] of Object.entries(validators)) {
            const validator = this.validatorFactory.create(validatorName, validatorOpts, type);
            this.form.events.addEventTo(prop.UID, type, ({ form, data }) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                return yield validator.validate({ form, data, prop });
            }));
        }
    }
    registerQualificationValidators(prop) {
        const validators = prop.def.qualifications;
        for (const [validatorName, validatorOpts] of Object.entries(validators)) {
            const validator = this.validatorFactory.create(validatorName, validatorOpts, Events_1.Events.DataQualification);
            this.form.events.addEvent(Events_1.Events.DataUpdated, ({ form, data }) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                const [result, errorEvent] = yield validator.validate({ form, data, prop });
                yield form.updateQualification(prop.UID, result);
                form.qualificationsErrors[prop.UID] = errorEvent;
            }));
        }
    }
}
exports.SchemaEventsService = SchemaEventsService;
