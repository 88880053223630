import Mask from "./Mask"
import maskList from "./maskList"

export default class Masks {
  constructor() {
    this.maskList = {
      default: new Mask("", { inputType: "text", maskChar: "_" }),
    }
  }

  addMask(id, maskString, opts = {}) {
    if (this.maskList[id]) {
      return false
    }
    const mask = new Mask(maskString, opts)
    this.maskList[id] = mask
    return mask
  }

  getMask(key) {
    if (!key) {
      return false
    }
    const id = Object.keys(this.maskList).find((idKey) => idKey.toLowerCase() === key)
    if (id) {
      return this.maskList[id]
    }
    return false
  }

  getDefaultMask() {
    return this.maskList["default"]
  }
}

const masks = new Masks()
for (const [key, { mask, opts }] of Object.entries(maskList)) {
  masks.addMask(key, mask, opts)
}

export { masks }
