import React, { useContext } from "react"
import { AvailableInfoInterface } from "../interfaces"
import { FormContext } from "../../../context/form/formState"
import { UseFormActionInterface } from "../../../context/form/useFormAction"
import { NextLabel } from "../../Label/NextLabel/NextLabel"
import { Button } from "../../index"

export interface NextStepButtonPropsInterface {
  availableInfo: AvailableInfoInterface
  id?: string
}

const NextStepButton = ({ availableInfo, id = "navigate-to-next-step" }: NextStepButtonPropsInterface): JSX.Element => {
  const { isFormStarted, setStep, setAutofocusUid, form }: UseFormActionInterface = useContext(FormContext)
  const isDisabled = availableInfo.isLastStep || (!availableInfo.isSectionValid && !availableInfo.allowInvalidSection)
  const incrementStepIndex = () => {
    if (!isDisabled) {
      setStep({ stepUid: availableInfo.nextSectionId })
    }

    if (availableInfo.nextSectionId && form) {
      const nextUid = form.selectors.getChildrenAnswerableSelectors(availableInfo.nextSectionId)[0]
      if (nextUid) {
        setAutofocusUid(nextUid)
      }
    }
  }

  return (
    <Button
      disabled={isDisabled}
      id={id}
      label={<NextLabel labelKey={availableInfo.isFirstStep && !isFormStarted ? "start" : "next"} />}
      onClick={incrementStepIndex}
    />
  )
}

export default NextStepButton
