import React from "react"
import { Modal as GerudoModal, ModalType, ModalFooter, ModalHeader } from "@akinoxsolutions/gerudo-ui/dist/Modal"
import { Button, Title, Html } from "../../index"
import { ButtonGroup } from "@akinoxsolutions/gerudo-ui"
import { ButtonTypes } from "@akinoxsolutions/gerudo-ui/dist/Button"
import { GenericDataValueType } from "@akinoxsolutions/formol/src"
import { WarningOutlinedIcon } from "@akinoxsolutions/gerudo-ui/dist/Icons"
import { InfoCircleIcon } from "@akinoxsolutions/gerudo-ui/dist/Icons/InfoCircle"
import useTranslation from "../../../hooks/useTranslation"
import styles from "./NotificationModal.module.css"

export interface NotificationModalPropsInterface {
  modalOptions: GenericDataValueType
  className?: string
  visible: boolean
  onCancel: () => void
}

const NotificationModal = ({ modalOptions, className, visible, onCancel }: NotificationModalPropsInterface) => {
  const { translate } = useTranslation()
  const { title, text, cancelText, isNewModal, isCustomRender, asNotification } = modalOptions
  const modalClassNames = [styles["modal"]]
  if (className) modalClassNames.push(className)

  const isNotification = () => {
    switch (asNotification) {
      case "warning":
        return { icon: <WarningOutlinedIcon />, type: ModalType.Warning }
      case "info":
        return { icon: <InfoCircleIcon />, type: ModalType.Info }
      default:
        return undefined
    }
  }

  return (
    <GerudoModal
      className={modalClassNames.join(" ")}
      asNotification={isNotification()}
      centered
      isNewModal={isNewModal}
      visible={visible}
      closable
      isCustomRender={isCustomRender}
      onCancel={onCancel}
      destroyOnClose={true}
    >
      <ModalHeader className={isNotification() ? styles["header-notification"] : styles["header"]} onCancel={onCancel}>
        <Title level={3}>{translate(title)}</Title>
      </ModalHeader>
      <div className={styles["html"]}>
        <Html content={translate(text)} />
      </div>
      <ModalFooter className={isNotification() ? styles["footer-notification"] : styles["footer"]}>
        <ButtonGroup>
          <Button id="btn_cancel" onClick={onCancel} type={ButtonTypes.Default} label={translate(cancelText)} />
        </ButtonGroup>
      </ModalFooter>
    </GerudoModal>
  )
}

export default NotificationModal
