"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validators = void 0;
const ConditionalValueCompareValidator_1 = require("./ConditionalValueCompareValidator");
const IsInEnumValidator_1 = require("./IsInEnumValidator");
const FormPassValidator_1 = require("./FormPassValidator");
const NotEmptyValidator_1 = require("./NotEmptyValidator");
const PassValidationValidator_1 = require("./PassValidationValidator");
const FailValidationValidator_1 = require("./FailValidationValidator");
const RangeNumberValidator_1 = require("./RangeNumberValidator");
const RegExpValidator_1 = require("./RegExpValidator");
const ScoringValidator_1 = require("../../Plugin/PropertyScore/ScoringValidator");
const UploadValidator_1 = require("./UploadValidator");
const ValueCompareValidator_1 = require("./ValueCompareValidator");
const WordCountValidator_1 = require("./WordCountValidator");
const LanguageValidator_1 = require("./LanguageValidator");
const HealthInsuranceValidator_1 = require("./HealthInsuranceValidator");
const FnValidator_1 = require("./FnValidator");
const FormNotPassValidator_1 = require("./FormNotPassValidator");
const DisableFutureDateValidator_1 = require("./DisableFutureDateValidator");
const validators = {
    formPass: (opts, type) => new FormPassValidator_1.FormPassValidator(opts, type),
    formNotPass: (opts, type) => new FormNotPassValidator_1.FormNotPassValidator(opts, type),
    isInEnum: (opts, type) => new IsInEnumValidator_1.IsInEnumValidator(opts, type),
    notEmpty: (opts, type) => new NotEmptyValidator_1.NotEmptyValidator(opts, type),
    passValidation: (opts, type) => new PassValidationValidator_1.PassValidationValidator(opts, type),
    failValidation: (opts, type) => new FailValidationValidator_1.FailValidationValidator(opts, type),
    rangeNumber: (opts, type) => new RangeNumberValidator_1.RangeNumberValidator(opts, type),
    valueCompare: (opts, type) => new ValueCompareValidator_1.ValueCompareValidator(opts, type),
    conditionalValueCompare: (opts, type) => new ConditionalValueCompareValidator_1.ConditionalValueCompareValidator(opts, type),
    regExp: (opts, type) => new RegExpValidator_1.RegExpValidator(opts, type),
    wordCount: (opts, type) => new WordCountValidator_1.WordCountValidator(opts, type),
    scoring: (opts, type) => new ScoringValidator_1.ScoringValidator(opts, type),
    upload: (opts, type) => new UploadValidator_1.UploadValidator(opts, type),
    language: (opts, type) => new LanguageValidator_1.LanguageValidator(opts, type),
    healthInsurance: (opts, type) => new HealthInsuranceValidator_1.HealthInsuranceValidator(opts, type),
    fn: (opts, type) => new FnValidator_1.FnValidator(opts, type),
    disableFutureDates: (opts, type) => new DisableFutureDateValidator_1.DisableFutureDateValidator(opts, type),
};
exports.validators = validators;
