import React from "react"
import useTranslation from "../../../hooks/useTranslation"
import { ArrowLeftIcon } from "../../Icons"
import { Html } from "../../index"

export const PreviousLabel = ({ labelKey = "previous" }) => {
  const { translate } = useTranslation()

  return (
    <>
      <ArrowLeftIcon />
      &nbsp;
      <Html content={translate(labelKey)} />
    </>
  )
}
