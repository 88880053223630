import React from "react"
import { getStyle } from "../../utils/uiUtils"
import { Property } from "../Property/Property"
import styles from "./Properties.module.css"

export const Properties = ({ className, parentIsDisabled, parentIsReadOnly, property }) => {
  const { ui = {} } = property.def
  const { disabled, readOnly } = ui
  const isDisabled = parentIsDisabled || disabled
  const isReadOnly = parentIsReadOnly || readOnly
  const classNames = [styles.Properties]
  if (className) {
    classNames.push(className)
  }

  return (
    <div className={classNames.join(" ")} style={getStyle(ui)}>
      {[...property.childProps().values()].map((childProperty) => (
        <Property
          key={childProperty.UID}
          parentIsDisabled={isDisabled}
          parentIsReadOnly={isReadOnly}
          property={childProperty}
        />
      ))}
    </div>
  )
}
