"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormStats = void 0;
const utils_1 = require("../utils");
class FormStats {
    constructor(form) {
        this.form = form;
    }
    compile() {
        const statsResult = this.compileGlobalStats();
        if (statsResult.qualified > 0) {
            statsResult.progression = (statsResult.valid / statsResult.qualified) * 100;
        }
        statsResult.sections = this.compileSectionsStats();
        return statsResult;
    }
    compileGlobalStats() {
        const stats = Object.assign({}, FormStats.defaultStats);
        const compiledGroupSelectors = [];
        const excludedSelectors = [];
        for (const prop of this.form.selectors.values) {
            if (excludedSelectors.includes(prop.UID)) {
                continue;
            }
            if (prop.excludedFromStats) {
                if (prop.isGroup && !compiledGroupSelectors.includes(prop.UID)) {
                    compiledGroupSelectors.push(prop.UID);
                }
                if (prop.isSection) {
                    excludedSelectors.push(...this.form.selectors.getChildrenSelectors(prop.UID));
                }
                continue;
            }
            if (!prop.belongToSection || this.form.selectors.isParentQualified(prop.sectionUID)) {
                if (prop.isGroup && !compiledGroupSelectors.includes(prop.UID)) {
                    compiledGroupSelectors.push(prop.UID);
                    if (prop.isQualified() && prop.isParentQualified()) {
                        stats.qualified++;
                        this.form.nodes.groups.isValid(prop.UID) ? stats.valid++ : stats.invalid++;
                    }
                }
                else if (prop.isAnswerable && prop.isQualified() && !prop.belongToGroup && prop.isParentQualified()) {
                    stats.qualified++;
                    prop.isValid() ? stats.valid++ : stats.invalid++;
                }
            }
        }
        return stats;
    }
    compileSectionsStats() {
        let compiledGroupSelectors = [];
        const sectionsStats = new Map();
        const sectionsMap = new Map();
        this.form.schema.nodes.sections.forEach((sectionProp) => {
            const props = {};
            sectionsStats.set(sectionProp.property.UID, { qualified: 0, valid: 0, invalid: 0, progression: 0 });
            if (!sectionProp.property.isExcludedFromStats()) {
                for (const selector of this.form.selectors.getChildrenSelectors(sectionProp.property.UID)) {
                    props[selector] = this.form.selectors.get(selector);
                }
            }
            sectionsMap.set(sectionProp.property.UID, props);
        });
        sectionsMap.forEach((props, sectionUID) => {
            compiledGroupSelectors = [];
            for (const prop of Object.values(props)) {
                if (prop.excludedFromStats) {
                    continue;
                }
                if (!prop.belongToSection || this.form.selectors.isParentQualified(prop.sectionUID)) {
                    const sectionStats = sectionsStats.get(sectionUID);
                    if (prop.belongToGroup && !compiledGroupSelectors.includes(prop.groupUID)) {
                        compiledGroupSelectors.push(prop.groupUID);
                        if (this.form.qualifications[prop.groupUID] && this.form.selectors.isParentQualified(prop.groupUID)) {
                            sectionStats.qualified++;
                            this.form.nodes.groups.isValid(prop.groupUID) ? sectionStats.valid++ : sectionStats.invalid++;
                        }
                    }
                    else if (prop.isAnswerable && prop.isQualified() && !prop.belongToGroup && prop.isParentQualified()) {
                        sectionStats.qualified++;
                        prop.isValid() ? sectionStats.valid++ : sectionStats.invalid++;
                    }
                }
            }
        });
        return (0, utils_1.mapToObject)(sectionsStats);
    }
}
exports.FormStats = FormStats;
FormStats.defaultStats = {
    qualified: 0,
    valid: 0,
    invalid: 0,
    progression: 0,
    sections: {},
};
