import React from "react"

import GerudoHiddenText, { HiddenTextPropsWithChildren } from "@akinoxsolutions/gerudo-ui/dist/HiddenText"

interface HiddenTextPropsInterface extends HiddenTextPropsWithChildren {}

const HiddenText = (props: HiddenTextPropsInterface) => {
  return <GerudoHiddenText {...props} />
}

export default HiddenText
