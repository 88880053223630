import React, { ElementType, ReactNode } from "react"
import { IconSizes } from "@akinoxsolutions/gerudo-ui/dist/Icons/PropTypes/IconSizes"
import { Tooltip, TooltipPropsInterface } from ".."
import styles from "./Label.module.css"

export interface LabelPropsInterface {
  children: ReactNode
  className?: string
  tooltip?: TooltipPropsInterface
  Tag?: ElementType
  htmlFor?: HTMLLabelElement["htmlFor"]
  inheritStyle?: boolean
}

const Label = ({
  children,
  className,
  Tag = "label",
  tooltip,
  inheritStyle,
  htmlFor,
  ...props
}: LabelPropsInterface): JSX.Element => {
  const classNames = [styles.Label]
  if (inheritStyle) classNames.push(styles.Inherit)
  if (className) classNames.push(className)

  return (
    <Tag className={classNames.join(" ")} {...(Tag === "label" ? { htmlFor } : {})} {...props}>
      {children}
      {tooltip && (
        <>
          &nbsp;
          <Tooltip {...tooltip} size={IconSizes.Small} />
        </>
      )}
    </Tag>
  )
}

export default Label
