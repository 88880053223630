import React, { ReactNode, useContext, useEffect, useState } from "react"
import i18n from "i18next"
import FormItem, { ValidateStatus } from "antd/lib/form/FormItem"
import { PropertyInterface } from "@akinoxsolutions/formol"
import { FormContext } from "../../context/form/formState"
import { UseFormActionInterface } from "../../context/form/useFormAction"
import { ValidationErrorInterface } from "../../context/form/formReducer"
import useTranslation from "../../hooks/useTranslation"
import { Html, Item } from "../index"
import { getHideValidationErrorDefaultValue } from "./helpers"

import style from "./AnswerableItem.module.css"

export interface AnswerableItemPropsInterface {
  children: ReactNode
  property: PropertyInterface
  value: unknown
}

export interface AnswerableItemHelpInfoInterface {
  text?: string
  type: ValidateStatus
}

const AnswerableItem = ({ children, property, value }: AnswerableItemPropsInterface): JSX.Element => {
  const { translate } = useTranslation()
  const { ui = {} } = property.def
  const { type: uiType, notes, hideValidationError = getHideValidationErrorDefaultValue(uiType) } = ui
  const {
    formStatus,
    formValidationsErrors: { [property.UID]: validationErrors },
    formValidations: { [property.UID]: isValid },
    formQualifications: { [property.UID]: isQualify },
    isUpdating,
  }: UseFormActionInterface = useContext(FormContext)

  const getHelperInfo = (): AnswerableItemHelpInfoInterface => {
    const displayError = isQualify && !isUpdating && (value !== null || formStatus === "SUBMITTED")
    const { code, context, status }: ValidationErrorInterface = displayError ? validationErrors ?? {} : {}
    const text: string | undefined = code || notes ? translate(code ?? notes, context) : undefined

    return { text, type: status ?? (code ? (!isValid ? "error" : "warning") : "success") }
  }

  const [helperInfo, setHelperInfo] = useState(getHelperInfo())
  useEffect(() => {
    if (!isUpdating) {
      const info = getHelperInfo()
      if (hideValidationError && info.type === "error") {
        info.text = ""
      }
      setHelperInfo(info)
    }
  }, [isUpdating, i18n.language])

  return (
    <Item property={property}>
      <FormItem
        className={style.FormItem}
        help={helperInfo.text ? <Html content={helperInfo.text} /> : undefined}
        validateStatus={helperInfo.type}
      >
        {children}
      </FormItem>
    </Item>
  )
}

export default AnswerableItem
