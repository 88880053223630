import { defaultTheme } from "./themes"
import devPanelTypes from "./devPanelTypes"

export const devPanelInitialState = {
  autoUpdate: false,
  customEvents: [],
  debounceTime: 2000,
  debounceUid: "devPanel.reloadForm",
  editorTheme: defaultTheme,
  isEditable: false,
  filteredQvTable: [],
  qvFilters: {
    uid: "",
    qualification: undefined,
    validation: undefined,
    errorCode: "",
  },
  qvTable: [],
  schema: "{}",
  schemaIsValid: true,
  schemaErrors: null,
  tabActiveKey: "0",
}

const getFilteredQvTable = (qvTable, qvFilters) =>
  qvTable.filter(
    ({ selector, qualification, validation, errorCode }) =>
      selector.match(new RegExp(qvFilters.uid, "i")) &&
      (qvFilters.qualification === undefined || qualification === qvFilters.qualification) &&
      (qvFilters.validation === undefined || validation === qvFilters.validation) &&
      errorCode.match(new RegExp(qvFilters.errorCode, "i")),
  )

const getMergedQvFilters = ({ state: { qvFilters: stateQvFilters }, action: { qvFilters: actionQvFilters } }) => ({
  ...stateQvFilters,
  ...actionQvFilters,
})

const devPanelReducer = (state, action) => {
  switch (action.type) {
    case devPanelTypes.INIT_DEV_PANEL: {
      return devPanelInitialState
    }

    case devPanelTypes.SET_CUSTOM_EVENTS: {
      return {
        ...state,
        customEvents: action.customEvents,
      }
    }

    case devPanelTypes.UPDATE_AUTO_UPDATE: {
      return {
        ...state,
        autoUpdate: action.value,
      }
    }

    case devPanelTypes.UPDATE_EDITOR_THEME: {
      return {
        ...state,
        editorTheme: action.value,
      }
    }

    case devPanelTypes.UPDATE_IS_EDITABLE: {
      return {
        ...state,
        isEditable: action.value,
      }
    }

    case devPanelTypes.UPDATE_Q_V_FILTERS: {
      return {
        ...state,
        filteredQvTable: getFilteredQvTable(state.qvTable, getMergedQvFilters({ state, action })),
        qvFilters: getMergedQvFilters({ state, action }),
      }
    }

    case devPanelTypes.UPDATE_Q_V_TABLE: {
      return {
        ...state,
        filteredQvTable: getFilteredQvTable(action.value, state.qvFilters),
        qvTable: action.value,
      }
    }

    case devPanelTypes.UPDATE_SCHEMA: {
      return {
        ...state,
        schema: action.schema,
        schemaIsValid: action.isValid,
        schemaErrors: action.errorMessage,
      }
    }

    case devPanelTypes.UPDATE_TAB_ACTIVE_KEY: {
      return {
        ...state,
        tabActiveKey: action.value,
      }
    }

    default:
      return state
  }
}

export default devPanelReducer
