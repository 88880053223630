import React, { ReactNode, useContext } from "react"
import { PropertyInterface } from "@akinoxsolutions/formol"
import { Button as GerudoButton, ButtonPropsBase, ButtonTypes } from "@akinoxsolutions/gerudo-ui/dist/Button"
import { IconSizes } from "@akinoxsolutions/gerudo-ui/dist/Icons/PropTypes/IconSizes"
import useTranslation from "../../hooks/useTranslation"
import { getSubType } from "../../utils/uiUtils"
import { EditIcon, PrinterIcon, UploadIcon } from "../Icons"
import styles from "./Button.module.css"
import { ConfigContext } from "../../context/config/configState"

export interface ButtonPropsInterface extends ButtonPropsBase<HTMLAnchorElement | HTMLButtonElement> {
  id: string
  label?: string | ReactNode
  property?: PropertyInterface
}

export const Button = ({
  className,
  disabled,
  label,
  onClick,
  property,
  type,
  ...props
}: ButtonPropsInterface): JSX.Element => {
  const { translate } = useTranslation()
  const classNames: string[] = [styles.Button]
  if (className) {
    classNames.push(className)
  }

  let icon: undefined | JSX.Element
  let labelToUse: undefined | string | ReactNode = label
  if (property) {
    const { name = "", size = "" } = property.def.ui?.buttonOptions?.icon ?? {}
    const getSize = (): IconSizes => (size.toLowerCase() in IconSizes ? size.toLowerCase() : IconSizes.xsmall)

    switch (name.toLowerCase()) {
      case "edit":
        icon = <EditIcon size={getSize()} />
        labelToUse ||= translate("edit.defaultButtonText")
        break
      case "printer":
        icon = <PrinterIcon size={getSize()} />
        labelToUse ||= translate("print.defaultButtonText")
        break
      case "upload":
        icon = <UploadIcon size={getSize()} />
        labelToUse ||= translate("upload.defaultButtonText")
        break
      default:
        break
    }
  }
  const finalType = (type ?? getSubType(property?.def?.ui?.type)) || "default"

  const { readOnlyMode } = useContext(ConfigContext)

  return (
    <GerudoButton
      className={classNames.join(" ")}
      disabled={disabled || readOnlyMode}
      icon={icon}
      onClick={disabled ? undefined : onClick}
      type={finalType as ButtonTypes}
      {...props}
    >
      {labelToUse}
    </GerudoButton>
  )
}

export default Button
