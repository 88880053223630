"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AbstractValidator = void 0;
const EventErrorInterface_1 = require("../Event/EventErrorInterface");
const Events_1 = require("../Event/Events");
class AbstractValidator {
    constructor(type) {
        this.type = type;
    }
    formatResult(result, opts) {
        var _a;
        const [hasPass, errorData] = result;
        let errorAnswer = {
            code: "",
            context: {},
        };
        if (errorData) {
            errorAnswer.code = (_a = opts.errorMsg) !== null && _a !== void 0 ? _a : errorData.code;
            if (errorData.context) {
                errorAnswer.context = errorData.context;
            }
        }
        if (this.type === Events_1.Events.DataQualification) {
            const behavior = opts["disqualificationBehavior"]
                ? opts["disqualificationBehavior"]
                : EventErrorInterface_1.defaultDisqualificationBehavior;
            errorAnswer = Object.assign(Object.assign({}, errorAnswer), { behavior });
        }
        return [hasPass, !hasPass ? errorAnswer : null];
    }
}
exports.AbstractValidator = AbstractValidator;
