import { FormInterface } from "@akinoxsolutions/formol"
import { SectionId } from "./formTypes"

export default class FormNavigation {
  public sectionUids: SectionId[]
  public navigableSectionUids: SectionId[] = []
  public hasSections: boolean
  public navigationTree: NavigationTreeItemInterface[] = []

  constructor(form: FormInterface) {
    this.sectionUids = [...form.schema.nodes.sections.keys()] ?? []
    this.hasSections = this.sectionUids.length > 0
    this._setNavigableSectionUids()
    this._setNavigationTree()
  }

  _setNavigableSectionUids() {
    if (this.hasSections) {
      const getParentUid = (currentUid) => currentUid.split(".").slice(0, -1).join(".")
      this.navigableSectionUids = this.sectionUids.filter(
        (currentSectionId) => !this.sectionUids.some((sectionId) => getParentUid(sectionId) === currentSectionId),
      )
    }
  }

  _setNavigationTree() {
    if (this.hasSections) {
      this.sectionUids.forEach((uid) => {
        this.navigationTree = FormNavigation.getNavigationTree({
          navigationTree: this.navigationTree,
          splitUid: uid.split("."),
          uid,
        })
      })
    }
  }

  static getNavigationTree({
    navigationTree = [],
    parentUid,
    splitUid,
    uid,
  }: GetNavigationTreeParametersInterface): NavigationTreeItemInterface[] {
    const rootId = splitUid.shift()
    if (!rootId) {
      throw new Error("splitUid needs at least one element")
    }

    let rootElement = navigationTree.find(({ id }) => id === rootId)
    if (!rootElement) {
      rootElement = { id: rootId, uid }
      navigationTree.push(rootElement)
    }

    if (splitUid.length > 0) {
      rootElement.children = FormNavigation.getNavigationTree({
        navigationTree: rootElement.children,
        parentUid: parentUid ? `${parentUid}.${rootId}` : rootId,
        splitUid,
        uid,
      })
    }

    return navigationTree
  }
}

export interface NavigationTreeItemInterface {
  uid: SectionId
  id: string
  children?: NavigationTreeItemInterface[]
}

interface GetNavigationTreeParametersInterface {
  navigationTree?: NavigationTreeItemInterface[]
  parentUid?: SectionId
  splitUid: string[]
  uid: SectionId
}
