import React, { useContext } from "react"
import useTranslation from "../../hooks/useTranslation"
import { FormContext } from "../../context/form/formState"
import { Button, Html } from "../index"

const getType = (value) => {
  switch (value) {
    case "PASS":
      return "emphasis"
    case "FAIL":
      return "danger"
    default:
      return "default"
  }
}

export const Status = ({ property }) => {
  const { translate } = useTranslation()
  const { form } = useContext(FormContext)
  const { enum: enumeration = {} } = property.def
  const value = form.dataSelector.get(property.UID)
  if (!value) {
    return null
  }

  const getLabel = () => {
    const { label } = enumeration.find(({ value: optionValue }) => optionValue === value) ?? {}
    return label ? translate(label) : undefined
  }
  const trLabel = getLabel()
  if (!trLabel) {
    return null
  }

  return <Button id={property.UID} label={<Html content={trLabel} />} type={getType(value)} />
}
