import React from "react"
import styles from "./Watermark.module.css"
import useTranslation from "../../hooks/useTranslation"
import { WatermarkOptions } from "./WatermarkOptions"

export const Watermark = ({ id, ...otherProps }) => {
  const { translate } = useTranslation()
  const watermarkOptions = new WatermarkOptions(otherProps)

  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={styles.Watermark} {...watermarkOptions.getSvgOptions()}>
      <defs>
        <pattern id={`${id}-watermark-pattern`} {...watermarkOptions.getPatternOptions()}>
          <text {...watermarkOptions.getTextOptions()}>
            {translate(otherProps?.text?.content ?? "watermark.text.content")}
          </text>
        </pattern>
      </defs>
      <rect fill={`url(#${id}-watermark-pattern)`} {...watermarkOptions.getRectOptions()} />
    </svg>
  )
}
