import React, { useEffect } from "react"
import "antd/dist/antd.css"
import "./gerudo"
import "iframe-resizer/js/iframeResizer.contentWindow.min"
import { ConfigProvider } from "./context/config/configState"
import { DevPanelProvider } from "./context/devPanel/devPanelState"
import { FormProvider } from "./context/form/formState"
import { MessagingProvider } from "./context/messaging/messagingState"
import { Viewer } from "./layouts/Viewer/Viewer"
import { loadJson } from "./utils/devUtils"
import { isValidJsonObject } from "./utils/jsonUtils"
import { WsClient } from "./autorefresh/WsClient"

export interface AppPropsInterface {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  json?: any
  isPartialDisplay?: boolean
  themeName?: string
}
export const App = ({ json, isPartialDisplay = true, themeName }: AppPropsInterface): JSX.Element => {
  const queryParams = new URLSearchParams(window.location.search)

  useEffect(() => {
    const ws = queryParams.get("ws")

    if (ws) {
      WsClient.create(ws)
      return
    }

    if (json && isValidJsonObject(json)) {
      loadJson(json, { isPartialDisplay, themeName })
    }
  }, [json])

  return (
    <ConfigProvider>
      <FormProvider>
        <DevPanelProvider>
          <MessagingProvider containerId={queryParams.get("containerId")}>
            <Viewer />
          </MessagingProvider>
        </DevPanelProvider>
      </FormProvider>
    </ConfigProvider>
  )
}
