"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormNodes = void 0;
const tslib_1 = require("tslib");
const GroupsNodes_1 = require("./Node/GroupsNodes");
const ArraysNodes_1 = require("./Node/ArraysNodes");
const SectionsNodes_1 = require("./Node/SectionsNodes");
class FormNodes {
    constructor(form) {
        this.form = form;
        this.sections = new SectionsNodes_1.SectionsNodes(form);
        this.groups = new GroupsNodes_1.GroupsNodes(form);
        this.arrays = new ArraysNodes_1.ArraysNodes(this.form);
    }
    updateValidations() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.sections.updateValidations();
            this.groups.updateValidations();
            this.arrays.updateValidations();
        });
    }
    isValidGroup(groupSelector) {
        return this.groups.isValid(groupSelector);
    }
    isValidSection(sectionSelector) {
        return this.sections.isValid(sectionSelector);
    }
    isValidArray(arraySelector) {
        return this.arrays.isValid(arraySelector);
    }
}
exports.FormNodes = FormNodes;
