"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Logger = void 0;
class Logger {
    static log(msg, context = {}) {
        Logger.stack.push({
            msg,
            context,
        });
        if (Logger.outputToConsole && console) {
            console.log(msg, context);
        }
    }
}
exports.Logger = Logger;
Logger.stack = [];
Logger.outputToConsole = true;
