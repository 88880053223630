"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HybridPropertyTypeProcessor = void 0;
class HybridPropertyTypeProcessor {
    constructor() {
        this.splitChar = ":";
    }
    process(def) {
        return this.parse(def);
    }
    parse(def) {
        for (const [key, val] of Object.entries(def)) {
            if (!val) {
                continue;
            }
            else if (val && typeof val === "object" && !["ui", "uiDefinitions"].includes(key)) {
                def[key] = this.parse(val);
                continue;
            }
            if (key === "type") {
                const parts = val.split(this.splitChar);
                if (parts.length > 1) {
                    def.type = parts.shift();
                    if (!def.ui) {
                        def.ui = {};
                    }
                    if (!def.ui.type) {
                        def.ui.type = parts.join(this.splitChar);
                    }
                }
                else if (["group", "section"].includes(val)) {
                    def.type = "object";
                    def[val] = true;
                }
            }
        }
        return def;
    }
}
exports.HybridPropertyTypeProcessor = HybridPropertyTypeProcessor;
