import React, { useContext } from "react"
import { FormContext } from "../../../context/form/formState"
import { HeaderLine } from "./HeaderLine"

export const Header = ({ property }) => {
  const { form } = useContext(FormContext)
  const ancestors = form.schema.getAncestors(property.UID).slice(0, -1)

  return ancestors.map((ancestor) => <HeaderLine key={ancestor.UID} property={ancestor} />)
}
