"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SelectorEventsManager = void 0;
const tslib_1 = require("tslib");
const SchemaEventsSelectorsReferenceMapper_1 = require("../../Schema/Mapper/SchemaEventsSelectorsReferenceMapper");
const SelectorEvents_1 = require("./SelectorEvents");
const BatchSelectorEvents_1 = require("./BatchSelectorEvents");
const Events_1 = require("./Events");
const Logger_1 = require("../../Logger");
class SelectorEventsManager {
    constructor(form) {
        this.form = form;
        this.events = new Map();
        this.eventsSelectors = [];
        this.batchSelectorEvents = new BatchSelectorEvents_1.BatchSelectorEvents();
        this.eventsTypes = [Events_1.Events.DataValidation, Events_1.Events.DataQualification, Events_1.Events.DataUpdated];
        this.selectorsTargetedByEvents = new SchemaEventsSelectorsReferenceMapper_1.SchemaEventsSelectorsReferenceMapper().map(form.schema);
    }
    addEvent(selector, type, fn, dependencies = []) {
        if (!this.form.selectors.has(selector)) {
            Logger_1.Logger.log(`cannot add an event on an unknown selector ${selector}`);
            return;
        }
        const event = { type, fn };
        let selectorEvent = this.events.get(selector);
        if (!selectorEvent) {
            selectorEvent = {
                selector,
                dataUpdated: [],
                dataQualification: [],
                dataValidation: [],
            };
            this.events.set(selector, selectorEvent);
            if (!this.eventsSelectors.includes(selector)) {
                this.eventsSelectors.push(selector);
            }
            if (!this.selectorsTargetedByEvents.includes(selector)) {
                this.selectorsTargetedByEvents.push(selector);
            }
        }
        const eventsArray = selectorEvent[SelectorEvents_1.SelectorEvents.typeNameToVarName(type)];
        eventsArray.push(event);
        this.addSelectorsTargetedByEvents(dependencies);
    }
    addSelectorsTargetedByEvents(selectors) {
        this.selectorsTargetedByEvents = [...new Set([...this.selectorsTargetedByEvents, ...selectors])];
    }
    deleteSelectorEvents(selector) {
        this.events.delete(selector);
        this.deleteSelectorTarget(selector);
    }
    deleteSelectorTarget(selector) {
        const steIndex = this.selectorsTargetedByEvents.indexOf(selector);
        if (steIndex !== -1) {
            this.selectorsTargetedByEvents.splice(steIndex, 1);
        }
    }
    hasValidations(selector) {
        if (this.events.has(selector)) {
            const selectorEvents = this.events.get(selector);
            return selectorEvents.dataValidation.length > 0;
        }
        return false;
    }
    hasTargetedSelector(selector) {
        return this.selectorsTargetedByEvents.includes(selector);
    }
    triggerEvent(selector, eventType, eventCtx) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.events.has(selector)) {
                yield this.batchSelectorEvents.executeBatch(this.events.get(selector), eventType, eventCtx);
            }
        });
    }
    triggerEvents(selector, eventsType, eventCtx) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            for (const eventType of eventsType) {
                yield this.triggerEvent(selector, eventType, eventCtx);
            }
        });
    }
    triggerAllEventsType(selector, eventCtx) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.events.has(selector)) {
                const se = this.events.get(selector);
                for (const eventType of this.eventsTypes) {
                    yield this.batchSelectorEvents.executeBatch(se, eventType, eventCtx);
                }
            }
        });
    }
}
exports.SelectorEventsManager = SelectorEventsManager;
