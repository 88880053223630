"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ArrayExpander = void 0;
const tslib_1 = require("tslib");
const AbstractArrayOperation_1 = require("./AbstractArrayOperation");
const Logger_1 = require("../../../Logger");
const PluginEvents_1 = require("../../Plugin/PluginEvents");
class ArrayExpander extends AbstractArrayOperation_1.AbstractArrayOperation {
    expand(selector, form) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const arrayProp = form.schema.search(selector);
            if (!this.isArray(selector, form) || !arrayProp) {
                return false;
            }
            const newProp = arrayProp.arrayExpand();
            if (!newProp) {
                Logger_1.Logger.log(`Cannot expand array property ${selector}`);
                return false;
            }
            const createdSelectors = [newProp.UID];
            form.selectors.addProperty(newProp);
            createdSelectors.push(...this.registerFormSelectors([], newProp.childProps(), form));
            yield this.handleCreatedSelectors(createdSelectors, form);
            return true;
        });
    }
    handleCreatedSelectors(createdSelectors, form) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            for (const createdSelector of createdSelectors) {
                this.addSelectorReferences(createdSelector, form);
                const formSelector = form.selectors.selectors[createdSelector];
                if (formSelector.isAnswerable && !form.dataSelector.has(createdSelector)) {
                    form.dataSelector.trySet(createdSelector, form.selectors.getDefaultValue(createdSelector));
                }
                if (formSelector.isArray) {
                    if (!form.dataSelector.has(createdSelector)) {
                        form.dataSelector.trySet(createdSelector, []);
                        let minItems = 1;
                        const def = formSelector.property().def;
                        if (def["minItems"] && def["minItems"] > 1) {
                            minItems = def["minItems"];
                        }
                        for (let i = 0; i < minItems; i++) {
                            yield this.expand(createdSelector, form);
                        }
                    }
                }
                form.plugins.triggerEvent(PluginEvents_1.PluginEvents.SelectorAdded, {
                    form,
                    selector: createdSelector,
                });
            }
        });
    }
    registerFormSelectors(selectors, props, form) {
        props.forEach((prop) => {
            form.selectors.addProperty(prop);
            selectors.push(prop.UID);
            if (prop.hasChildProps()) {
                this.registerFormSelectors(selectors, prop.childProps(), form);
            }
        });
        return selectors;
    }
}
exports.ArrayExpander = ArrayExpander;
