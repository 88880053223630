"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PropertyGroup = void 0;
class PropertyGroup {
    get answerableSelectors() {
        return this._selectors;
    }
    constructor(property) {
        this.property = property;
        this._selectors = [];
        this.mapChildrenAncestors();
        this.mapChildrenAnswerableSelectors();
    }
    _mapChildrenAncestors(props, groupUID) {
        props.forEach((prop) => {
            prop.groupUID = groupUID;
            if (prop.hasChildProps()) {
                this._mapChildrenAncestors(prop.childProps(), groupUID);
            }
        });
    }
    mapChildrenAncestors() {
        this._mapChildrenAncestors(this.property.childProps(), this.property.UID);
    }
    _mapChildrenAnswerableSelectors(props) {
        props.forEach((prop) => {
            if (prop.isAnswerable()) {
                this._selectors.push(prop.UID);
            }
            if (prop.hasChildProps()) {
                this._mapChildrenAnswerableSelectors(prop.childProps());
            }
        });
    }
    mapChildrenAnswerableSelectors() {
        this._selectors = [];
        this._mapChildrenAnswerableSelectors(this.property.childProps());
    }
}
exports.PropertyGroup = PropertyGroup;
