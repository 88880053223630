"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ArraySelectorsDataInitializer = void 0;
const tslib_1 = require("tslib");
const Property_1 = require("../../Schema/Property/Property");
class ArraySelectorsDataInitializer {
    constructor(form) {
        this.form = form;
    }
    init() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.expandArrayProperties(this.form.schema.childProps());
        });
    }
    expandArrayProperties(props) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            for (const [, prop] of props.entries()) {
                if (prop.type === Property_1.Property.TYPE_ARRAY && !prop.hasChildProps()) {
                    const currentData = this.form.dataSelector.get(prop.UID);
                    const minItems = Array.isArray(currentData) && currentData.length > 0 ? currentData.length : prop.arrayMinItems;
                    yield this.form.selectors.expandArray(prop.UID, minItems);
                    if (prop.hasChildProps()) {
                        yield this.expandArrayProperties(prop.childProps());
                    }
                }
                else if (prop.hasChildProps()) {
                    yield this.expandArrayProperties(prop.childProps());
                }
            }
        });
    }
}
exports.ArraySelectorsDataInitializer = ArraySelectorsDataInitializer;
