import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Modal as GerudoModal } from "@akinoxsolutions/gerudo-ui/dist/Modal"
import { ConfigContext } from "../../context/config/configState"
import { FormContext } from "../../context/form/formState"
import { Html } from "../index"

export const Modal = ({ property }) => {
  const { t: translate } = useTranslation()
  const { readOnlyMode } = useContext(ConfigContext)
  const [visible, setVisible] = useState(false)
  const {
    cancelText,
    content,
    okText,
    title,
    ui = {},
    okHandler: { formContextFunctionName: onOkFunctionName } = {},
  } = property.def
  const {
    formQualifications: { [property.UID]: isQualified },
    ...formContext
  } = useContext(FormContext)
  const { options: { closable = true, destroyOnClose = true } = {} } = ui

  useEffect(() => {
    if (!readOnlyMode && isQualified !== visible) {
      setVisible(isQualified)
    }
  }, [isQualified, readOnlyMode])

  const okHandler = () => {
    if (formContext[onOkFunctionName] && typeof formContext[onOkFunctionName] === "function") {
      formContext[onOkFunctionName]()
    }
  }

  return (
    <GerudoModal
      centered={true}
      closable={closable}
      destroyOnClose={destroyOnClose}
      title={<Html content={translate(title)} />}
      visible={visible}
      cancelText={cancelText && <Html content={translate(cancelText)} />}
      okText={okText && <Html content={translate(okText)} />}
      onCancel={() => setVisible(false)}
      onOk={okHandler}
    >
      <Html content={translate(content)} />
    </GerudoModal>
  )
}
