import React, { useContext } from "react"
import { ConfigContext } from "../../context/config/configState"
import { FormContext } from "../../context/form/formState"
import { NavigationButtons } from "../../components/NavigationButtons/NavigationButtons"
import { Properties } from "../../components/Properties/Properties"

export const StandardFormRenderer = () => {
  const { readOnlyMode } = useContext(ConfigContext)
  const { form } = useContext(FormContext)

  return (
    <>
      <Properties parentIsReadOnly={readOnlyMode} property={form.schema} />
      <NavigationButtons />
    </>
  )
}
