"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConversationalMode = void 0;
const AbstractNavigationMode_1 = require("./AbstractNavigationMode");
const Property_1 = require("../../Schema/Property/Property");
class ConversationalMode extends AbstractNavigationMode_1.AbstractNavigationMode {
    getNavigationList(filter = null) {
        const candidates = [];
        const selectors = this.form.selectors.values.sort(this.globalIndexSortingClosure);
        for (const sel of selectors) {
            if (sel.isSection || (sel.type === Property_1.Property.TYPE_OBJECT && !sel.isGroup)) {
                continue;
            }
            let candidate;
            const parentArraySelector = this.form.selectors.isParentTypeOf(sel.UID, Property_1.Property.TYPE_ARRAY);
            if (parentArraySelector) {
                const parentSelectorInfo = this.form.selectors.selectors[parentArraySelector];
                if (this.form.selectors.is(parentArraySelector, { belongToGroup: true })) {
                    candidate = this.form.selectors.selectors[parentSelectorInfo.groupUID];
                }
                else {
                    candidate = parentSelectorInfo;
                }
            }
            else if (sel.belongToGroup) {
                candidate = this.form.selectors.selectors[sel.groupUID];
            }
            else {
                candidate = sel;
            }
            candidate.relatedSelectors = this.propertiesSelectorsMapper.mapSelectors(this.form.schema.search(candidate.UID));
            candidates.push(candidate);
        }
        let list = [...new Set(candidates)].filter((selector) => this.form.qualifications[selector.UID]);
        if (filter) {
            list = list.filter(filter);
        }
        return list;
    }
}
exports.ConversationalMode = ConversationalMode;
