"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SchemaBuilder = void 0;
const tslib_1 = require("tslib");
const SchemaResolver_1 = require("./Builder/SchemaResolver");
const Schema_1 = require("./Schema");
const DefinitionProcessors_1 = require("./Processor/DefinitionProcessors");
const UiDefinitionsProcessor_1 = require("./Processor/UiDefinitionsProcessor");
const DefinitionProcessor_1 = require("./Processor/DefinitionProcessor");
const VarsProcessor_1 = require("./Processor/VarsProcessor");
const HybridPropertyTypeProcessor_1 = require("./Processor/HybridPropertyTypeProcessor");
class SchemaBuilder {
    build(options) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!options.schema) {
                throw new Error("Missing schema option");
            }
            return new Schema_1.Schema(new DefinitionProcessors_1.DefinitionProcessors([
                new VarsProcessor_1.VarsProcessor(options.vars || {}),
                new UiDefinitionsProcessor_1.UiDefinitionsProcessor(),
                new DefinitionProcessor_1.DefinitionProcessor(),
                new HybridPropertyTypeProcessor_1.HybridPropertyTypeProcessor(),
            ]).process(yield new SchemaResolver_1.SchemaResolver().resolve(options.schema, {})));
        });
    }
}
exports.SchemaBuilder = SchemaBuilder;
