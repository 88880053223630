"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdateTrackerPlugin = void 0;
const PluginBase_1 = require("../../Form/Plugin/PluginBase");
class UpdateTrackerPlugin extends PluginBase_1.PluginBase {
    constructor() {
        super(...arguments);
        this.name = "updateTracker";
    }
    dataUpdated({ form }) {
        if (!form.meta["updateTracker"]) {
            form.meta["updateTracker"] = 0;
        }
        form.meta["updateTracker"]++;
    }
}
exports.UpdateTrackerPlugin = UpdateTrackerPlugin;
