import React from "react"
import { Checkbox as GerudoCheckbox } from "@akinoxsolutions/gerudo-ui/dist/Checkbox"
import useTranslation from "../../hooks/useTranslation"
import { AnswerableComponentPropsInterface } from "../interfaces"
import { Html } from "../index"
import styles from "./Checkbox.module.css"

export interface CheckboxPropsInterface extends AnswerableComponentPropsInterface<boolean> {}

const Checkbox = ({
  autofocus,
  isDisabled,
  isReadOnly,
  name,
  onBlur,
  onChange,
  property,
  value,
  ...otherProps
}: CheckboxPropsInterface) => {
  const { translate } = useTranslation()
  const { default: defaultValue, enum: options, label: propertyLabel, ui = {} } = property.def
  const { size } = ui
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const shouldUseDefault = ![true, false].includes(value!) && [true, false].includes(defaultValue)
  const currentValue = shouldUseDefault ? defaultValue : value
  const { label, tooltip } = options.find(({ value: optionValue }) => optionValue === currentValue) ?? {}
  const onChangeUnion = (e) => {
    if (onBlur) onBlur()
    if (onChange) onChange(e.target.checked)
  }

  return (
    <GerudoCheckbox
      autoFocus={autofocus}
      checked={currentValue}
      className={styles.Checkbox}
      disabled={isDisabled || isReadOnly}
      name={name}
      onChange={(e) => onChangeUnion(e)}
      size={size}
      value={true}
      {...otherProps}
    >
      <Html content={translate(label ?? propertyLabel ?? currentValue)} tooltip={tooltip} />
    </GerudoCheckbox>
  )
}

export default Checkbox
