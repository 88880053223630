import React from "react"
import { TFunction } from "react-i18next"
import { FormDataInterface, FormInterface } from "@akinoxsolutions/formol"
import { Step, StepStatus } from "@akinoxsolutions/gerudo-ui/dist/Stepper/types"
import { FormOptionsInterface, FormStatusType } from "../../context/form/formReducer"
import { NavigationTreeItemInterface } from "../../context/form/FormNavigation"
import { getCurrentSectionScore } from "../../utils/scoreUtil"
import { Html } from "../index"

export interface StepHasInvalidPrecedingSectionPropsInterface {
  formValidations: Record<string, boolean>
  sectionUids: string[]
  currentSectionIndex: number
}

export interface GetStatusPropsInterface {
  allowInvalidSection: boolean
  currentUid: string
  formValidations: FormDataInterface
  hasChildren: boolean
  formOptions: FormOptionsInterface
  isFormStarted: boolean
  isQualified: boolean
  isSubmitted: boolean
  isValid: boolean
  stats?: {
    qualified?: number
    valid?: number
  }
  isViewed: boolean
  readOnlyMode: boolean
  sectionUids: string[]
  uid: string
  type: string
}

export interface GetStepSuffixPropsInterface {
  displayStats: boolean
  isDisabled: boolean
  totalValidProps: number
  totalQualifiedProps: number
}

export interface GetStepsPropsInterface {
  currentUid: string
  devMode: boolean
  form: FormInterface
  formOptions: FormOptionsInterface
  formStatus: FormStatusType
  isFormStarted: boolean
  navigationTree?: NavigationTreeItemInterface[]
  readOnlyMode: boolean
  sectionUids: string[]
  translate: TFunction
}

class Steps {
  static getStatus = (props: GetStatusPropsInterface): StepStatus | ["progress", "finish"] => {
    const {
      allowInvalidSection,
      currentUid,
      formOptions: { highlightInvalid },
      formValidations,
      hasChildren,
      isFormStarted,
      isQualified,
      isSubmitted,
      isValid,
      stats,
      isViewed,
      readOnlyMode,
      sectionUids,
      uid,
      type,
    } = props
    const currentSectionIndex = sectionUids.indexOf(uid)

    let isFinished
    if (isSubmitted) {
      isFinished = true
    } else if (currentSectionIndex === 0 && isFormStarted && isValid) {
      isFinished = true
    } else if (
      (!isQualified && type !== "null") ||
      (!allowInvalidSection &&
        currentSectionIndex > 0 &&
        Steps.stepHasInvalidPrecedingSection({ formValidations, sectionUids, currentSectionIndex }))
    ) {
      return "disabled"
    } else if (isValid) {
      isFinished = isViewed || readOnlyMode
    } else if (stats && stats.qualified !== undefined && stats.valid !== undefined && stats.qualified === stats.valid) {
      isFinished = true
    }

    if (hasChildren) {
      return "progress"
    }

    if (currentUid === uid) {
      if (isFinished) {
        return ["progress", "finish"]
      }
      return "progress"
    }
    if (isFinished) {
      return "finish"
    }

    if (!isFinished && isViewed && highlightInvalid) {
      return "warning"
    }

    return "wait"
  }

  static getSteps = (props: GetStepsPropsInterface): Array<Step | undefined> => {
    const {
      currentUid,
      devMode,
      form,
      formOptions,
      formStatus,
      isFormStarted,
      navigationTree = [],
      readOnlyMode,
      sectionUids,
      translate,
    } = props

    return navigationTree
      .map(({ uid, children }) => {
        const step: Step = { uid }
        const { qualified: totalQualifiedProps, valid: totalValidProps } = form.stats.sections[uid]
        const property = form.selectors.get(uid).property()
        const { title, ui = {} } = property.def
        const {
          displayStats = true,
          hideStepOnDisabled = false,
          milestone = "",
        } = { ...ui.options, ...ui.stepperOptions } ?? {}

        step.status = Steps.getStatus({
          allowInvalidSection: formOptions.allowInvalidSection,
          currentUid,
          formOptions,
          formValidations: form.validations,
          hasChildren: Boolean(children),
          isFormStarted,
          isQualified: form.qualifications[uid],
          isSubmitted: formStatus === "SUBMITTED",
          isValid: form.validations[uid],
          stats: form.stats.sections[uid] || {},
          isViewed: form.nodes.sections.isViewed(uid),
          readOnlyMode,
          sectionUids,
          uid,
          type: property.type,
        })

        const isDisabled = step.status === "disabled"
        if (hideStepOnDisabled && isDisabled) {
          return
        }

        let name = translate(title)
        if (devMode && !isDisabled && form.plugins.get("propertyScore")) {
          const score = getCurrentSectionScore(form, uid)
          if (!isNaN(score)) {
            name += ` (${score})`
          }
        }
        step.name = (<Html content={name} />) as unknown as string // hack coz GerudoUI expect a string! :)
        if (milestone) {
          step.type = "milestone"
        }
        if (children) {
          step.steps = Steps.getSteps({ ...props, navigationTree: children }) as Step[]
        }
        step.suffix = Steps.getStepSuffix({ displayStats, isDisabled, totalValidProps, totalQualifiedProps })
        return step
      })
      .filter((val: Step | undefined) => val !== undefined)
  }

  static getStepSuffix = ({
    displayStats,
    isDisabled,
    totalValidProps,
    totalQualifiedProps,
  }: GetStepSuffixPropsInterface): string => {
    if (displayStats !== false && !isDisabled && totalValidProps + totalQualifiedProps > 0) {
      return `${totalValidProps} / ${totalQualifiedProps}`
    }
    return ""
  }

  static stepHasInvalidPrecedingSection = (props: StepHasInvalidPrecedingSectionPropsInterface): boolean => {
    const { currentSectionIndex, formValidations, sectionUids } = props

    let index = currentSectionIndex
    while (--index >= 0) {
      if (!formValidations[sectionUids[index]]) {
        return true
      }
    }
    return false
  }
}

export default Steps
