import React, { useContext, useEffect } from "react"
import useTranslation from "../../hooks/useTranslation"
import { Stepper as GerudoStepper } from "@akinoxsolutions/gerudo-ui/dist/Stepper"
import { ConfigContext } from "../../context/config/configState"
import { FormContext } from "../../context/form/formState"
import { Image } from "../Image/Image"
import Steps from "./Steps"

export const Stepper = () => {
  const { translate } = useTranslation()
  const { devMode, readOnlyMode } = useContext(ConfigContext)
  const {
    form,
    formOptions,
    formStatus,
    isFormStarted,
    navigableSectionUids,
    navigationTree,
    sectionUids,
    setStep,
    stepIndex,
  } = useContext(FormContext)
  const currentUid = navigableSectionUids[stepIndex]
  const logo = form.schema.def.ui?.stepper?.logo
  const onChangeHandler = ({ uid }) => setStep({ stepUid: uid })

  useEffect(() => {
    form.nodes.sections.addViewedSection(currentUid)
  }, [currentUid])

  return (
    <GerudoStepper
      before={stepIndex > 0 && logo ? <Image {...logo} /> : undefined}
      onChange={onChangeHandler}
      steps={Steps.getSteps({
        currentUid,
        devMode,
        form,
        formOptions,
        formStatus,
        isFormStarted,
        navigationTree,
        readOnlyMode,
        sectionUids,
        translate,
      })}
      mobileBreakpoint={900}
      mobileCloseLabel={translate("actions.close")}
    />
  )
}
