import React, { useEffect, useState } from "react"
import { EyeClosedIcon, EyeOpenIcon } from "../../Icons"
import { getSubType } from "../../../utils/uiUtils"
import { Input as GerudoInput } from "@akinoxsolutions/gerudo-ui/dist/Input"

export const TextField = ({
  autofocus,
  disabled,
  id,
  maxLength,
  name,
  onBlur,
  onChange,
  placeholder,
  type,
  value,
  ...otherProps
}) => {
  const [passwordInfo, setPasswordInfo] = useState({ isPassword: getSubType(type) === "password" })
  const togglePassword = () => {
    if (passwordInfo.isPassword) {
      setPasswordInfo({ ...passwordInfo, showPassword: !passwordInfo.showPassword })
    }
  }

  useEffect(() => {
    if (passwordInfo.isPassword) {
      const IconComponent = passwordInfo.showPassword ? EyeClosedIcon : EyeOpenIcon
      setPasswordInfo({ ...passwordInfo, icon: <IconComponent onClick={togglePassword} /> })
    }
  }, [passwordInfo.showPassword])

  return (
    <GerudoInput
      autoFocus={autofocus}
      disabled={disabled}
      id={id}
      maxLength={maxLength}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      placeholder={placeholder}
      suffix={passwordInfo.isPassword ? passwordInfo.icon : undefined}
      type={passwordInfo.isPassword && !passwordInfo.showPassword ? "password" : "text"}
      value={value}
      {...otherProps}
    />
  )
}
