"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RefDefinitionProcessor = void 0;
const utils_1 = require("../../utils");
class RefDefinitionProcessor {
    constructor(definitionKey) {
        this.definitionKey = definitionKey;
        this.definitions = new Map();
        this.regex = `^#/${this.definitionKey}/([A-Za-z_0-9]+)$`;
    }
    process(def) {
        if (!def[this.definitionKey]) {
            return def;
        }
        this.parseDefinitions(def[this.definitionKey]);
        return this.parse(def);
    }
    parseDefinitions(data) {
        for (const [id, val] of Object.entries(data)) {
            this.definitions.set(id, val);
        }
        this.definitions.forEach((def, id) => {
            this.definitions.set(id, this.parse(def));
        });
    }
    parse(data) {
        for (const [key, val] of Object.entries(data)) {
            if (key === "$ref" && typeof val === "string") {
                const newVal = this.resolve(val);
                if (newVal) {
                    data = (0, utils_1.deepMerge)(newVal, data);
                }
            }
            else if (val && typeof val === "object") {
                data[key] = (0, utils_1.deepMerge)(data[key], this.parse(val));
            }
        }
        return data;
    }
    resolve(expression) {
        const result = new RegExp(this.regex, "gi").exec(expression);
        if (result && this.definitions.has(result[1])) {
            return this.definitions.get(result[1]);
        }
        return null;
    }
}
exports.RefDefinitionProcessor = RefDefinitionProcessor;
