import { CSSProperties, Dispatch, useReducer } from "react"
import configReducer from "./configReducer"
import locales, { defaultLocaleKey } from "./locales"
import { getStandardizeLanguage } from "../../i18n"
import { UseConfigActionInterface } from "./configInterfaces"
import { ConfigReducerActionType, DisplayModeType, ThemeNameType } from "./configTypes"
import { configInitialState } from "./configState"

const useConfigAction = (): [UseConfigActionInterface, Dispatch<ConfigReducerActionType>] => {
  const [state, dispatch] = useReducer(configReducer, configInitialState, () => {
    return configInitialState
  })

  const increaseNumberOfErrors = (): void => {
    dispatch({ type: "INCREASE_NUMBER_OF_ERRORS" })
  }

  const resetConfig = (): void => {
    dispatch({ type: "RESET_CONFIG" })
  }

  const setCustomStyle = (customStyle: CSSProperties): void => {
    dispatch({ type: "SET_CUSTOM_STYLE", customStyle })
  }

  const setDevMode = (devMode: boolean): void => {
    dispatch({ type: "SET_DEV_MODE", devMode: state.isDevEnv ? devMode : false })
  }

  const setDisplayMode = (displayMode: DisplayModeType): void => {
    if (["CONVERSATIONAL", "SECTIONAL", "STANDARD"].includes(`${displayMode}`.toUpperCase())) {
      dispatch({ type: "SET_DISPLAY_MODE", displayMode })
    }
  }

  const setIsPartialDisplay = (isPartialDisplay: boolean): void => {
    dispatch({ type: "SET_IS_PARTIAL_DISPLAY", isPartialDisplay })
  }

  const setLocale = (language: string): void => {
    const parseLanguage = language ? getStandardizeLanguage(language) : undefined
    const localeKey = locales[parseLanguage] ? parseLanguage : defaultLocaleKey
    const locale = locales[localeKey]

    dispatch({ type: "SET_LOCALE", localeKey, locale })
  }

  const setReadOnlyMode = (readOnlyMode: boolean): void => {
    dispatch({ type: "SET_READ_ONLY_MODE", readOnlyMode })
  }

  const setTheme = (themeName: ThemeNameType): void => {
    if (["default", "old-school", "akinox", "msss"].includes(`${themeName}`.toLowerCase())) {
      dispatch({ type: "SET_THEME", themeName })
    }
  }

  const toggleDisplayDevPanel = (): void => {
    dispatch({ type: "TOGGLE_DISPLAY_DEV_PANEL" })
  }

  return [
    {
      ...state,
      increaseNumberOfErrors,
      resetConfig,
      setCustomStyle,
      setDevMode,
      setDisplayMode,
      setIsPartialDisplay,
      setLocale,
      setReadOnlyMode,
      setTheme,
      toggleDisplayDevPanel,
    },
    dispatch,
  ]
}

export default useConfigAction
