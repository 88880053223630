"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Selector = void 0;
class Selector {
    constructor() {
        this.selectorsSplitted = {};
    }
    map(selector) {
        if (!this.selectorsSplitted[selector]) {
            this.selectorsSplitted[selector] = selector.split(Selector.separator);
        }
        return this.selectorsSplitted[selector];
    }
    get(selector, data) {
        return this._get(this.map(selector), data, selector);
    }
    tryGet(selector, data) {
        try {
            return [true, this._get(this.map(selector), data, selector)];
        }
        catch (e) {
            return [false, e];
        }
    }
    getOrNull(selector, data) {
        const [r, val] = this.tryGet(selector, data);
        return r ? val : null;
    }
    set(selector, value, data) {
        this._set(selector, value, data);
    }
    trySet(selector, value, data) {
        try {
            this._set(selector, value, data);
            return [true, null];
        }
        catch (e) {
            return [false, e];
        }
    }
    delete(selector, data) {
        if (this.has(selector, data)) {
            this._delete(selector, data);
        }
    }
    tryDelete(selector, data) {
        if (!this.has(selector, data)) {
            return [false, "selector not found"];
        }
        try {
            this._delete(selector, data);
            return [true, null];
        }
        catch (e) {
            return [false, e];
        }
    }
    has(selector, data) {
        try {
            this.get(selector, data);
            return true;
        }
        catch (e) {
            return false;
        }
    }
    _get(selectorPaths, data, originalSelector) {
        const lastIndex = selectorPaths.length - 1;
        for (let i = 0; i < selectorPaths.length; ++i) {
            const dataKey = data[selectorPaths[i]];
            if (dataKey !== undefined) {
                if (lastIndex === i) {
                    return dataKey;
                }
                else if (dataKey !== null && typeof dataKey === "object") {
                    return this._get(selectorPaths.slice(i + 1, selectorPaths.length), dataKey, originalSelector);
                }
                throw new Error(`Data not found for selector ${originalSelector}`);
            }
        }
        throw new Error(`Data not found for selector ${originalSelector}`);
    }
    _set(propPath, value, obj) {
        const [head, ...rest] = propPath.split(Selector.separator);
        if (!rest.length) {
            obj[head] = value;
        }
        else {
            if (obj[head] === undefined && Array.isArray(obj)) {
                obj.push({});
            }
            this._set(rest.join(Selector.separator), value, obj[head]);
        }
    }
    _delete(propPath, obj) {
        const [head, ...rest] = propPath.split(Selector.separator);
        if (!rest.length) {
            Array.isArray(obj) ? obj.splice(parseInt(head), 1) : delete obj[head];
        }
        else {
            this._delete(rest.join(Selector.separator), obj[head]);
        }
    }
}
exports.Selector = Selector;
Selector.separator = ".";
Selector.wildcard = "*";
