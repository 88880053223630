import { componentNames, propertyNames } from "./constants"

/**
 * Semantic type of a property for accessibility purposes
 * */
export enum PropertySemanticType {
  /** The property's title will be inside a <label> */
  Input = "input",

  /** The property will be wrapped in a <fieldset> and the property's title will be inside a <legend> */
  InputGroup = "input-group",

  /** The property will use non-semantic elements */
  Display = "display",
}

export type ComponentName = (typeof componentNames)[number]

export type PropertyName = (typeof propertyNames)[number]
