import React from "react"
import useTranslation from "../../hooks/useTranslation"
import { InputNumber as GerudoInputNumber } from "@akinoxsolutions/gerudo-ui/dist/Input"

export const InputNumber = ({
  autofocus,
  id,
  isDisabled,
  isReadOnly,
  name,
  onBlur,
  onChange,
  property,
  value,
  ...otherProps
}) => {
  const { translate } = useTranslation()
  const {
    def: { minimum, maximum, maxLength, ui: { fullWidth, placeholder, size, step } = {} },
  } = property
  const numberValue = isNaN(value) ? null : parseFloat(value)

  return (
    <GerudoInputNumber
      autoFocus={autofocus}
      disabled={isDisabled || isReadOnly}
      fullWidth={fullWidth}
      id={id}
      maxLength={maxLength}
      max={parseFloat(maximum)}
      min={parseFloat(minimum)}
      name={name}
      type="number"
      onBlur={onBlur}
      onChange={onChange}
      placeholder={placeholder ? translate(placeholder) : undefined}
      size={size}
      step={step}
      value={numberValue}
      {...otherProps}
    />
  )
}
