import React, { useContext } from "react"
import { FormContext } from "../../../context/form/formState"
import { NextLabel } from "../../../components/Label/NextLabel/NextLabel"
import { Button } from "../../../components"

export const NextButton = ({ property, setProperty }) => {
  const {
    form: { navigation },
    formValidations: { [property.UID]: isValid },
    isFormStarted,
  } = useContext(FormContext)

  const onClick = () => {
    const nextQualifiedProperty = navigation.conversational.getNextQualifiedProperty()

    if (nextQualifiedProperty) {
      navigation.setCurrentSelector(nextQualifiedProperty.UID)
      setProperty(nextQualifiedProperty)
    }
  }

  const buttonLabel = !navigation.conversational.isCurrentSelectorFirst() || isFormStarted ? "next" : "start"

  return (
    <Button
      disabled={!isValid}
      id="navigate-to-next-step"
      label={<NextLabel labelKey={buttonLabel} />}
      onClick={onClick}
    />
  )
}
