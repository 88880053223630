import React from "react"
import { Tile as GerudoTile } from "@akinoxsolutions/gerudo-ui/dist/Tile"
import { TileTitleAndText, TileStyle } from "@akinoxsolutions/gerudo-ui/dist/Tile/Tile"
import { PropertyInterface } from "../../../../lib"
import useTranslation from "../../hooks/useTranslation"
import { Link } from "../index"
import styles from "./Tile.module.css"

export interface TilePropsInterface {
  property: PropertyInterface
  className?: string
}

const Tile = ({ property, className }: TilePropsInterface) => {
  const { translate } = useTranslation()
  const { title, text, link, ui = {} } = property.def
  const { tileOptions = {} } = ui
  const tileClassNames = [styles["tile"]]
  if (className) tileClassNames.push(className)

  return (
    <GerudoTile
      className={tileClassNames.join(" ")}
      style={tileOptions.style}
      icon={tileOptions.icon || null}
      tileStyle={(tileOptions.tileStyle as TileStyle) || TileStyle.Default}
    >
      <TileTitleAndText title={translate(title)} text={translate(text)} />
      {link && <Link label={translate(link.label)} style={link.style} href={link.href} onClick={link.onClick} />}
    </GerudoTile>
  )
}

export default Tile
