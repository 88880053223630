import React, { createContext } from "react"
import { default as GerudoThemeProvider } from "@akinoxsolutions/gerudo-ui/dist/ThemeProvider"
import { defaultLocale, defaultLocaleKey, gerudoDefaultLocale, gerudoLocale } from "./locales"
import useConfigAction from "./useConfigAction"
import { ConfigProviderPropsInterface, ConfigStateInterface, UseConfigActionInterface } from "./configInterfaces"

const nodeEnv = (process && process.env?.NODE_ENV) ?? "production"

export const configInitialState: ConfigStateInterface = {
  customStyle: undefined,
  devMode: false,
  displayDevPanel: false,
  displayMode: undefined,
  isPartialDisplay: false,
  isDevEnv: nodeEnv === "development",
  locale: defaultLocale,
  localeKey: defaultLocaleKey,
  nodeEnv,
  numberOfErrors: 0,
  readOnlyMode: false,
  themeName: "default",
}

export const ConfigContext = createContext<UseConfigActionInterface>(configInitialState as never)

export const ConfigProvider = ({ children }: ConfigProviderPropsInterface): JSX.Element => {
  const [state] = useConfigAction()

  return (
    <ConfigContext.Provider value={state}>
      <GerudoThemeProvider locale={gerudoLocale[state.localeKey] ?? gerudoDefaultLocale} theme={state.themeName}>
        {children}
      </GerudoThemeProvider>
    </ConfigContext.Provider>
  )
}
