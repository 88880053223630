import React, { useContext } from "react"

import { AnswerableComponentPropsInterface } from "../interfaces"
import { UseFormActionInterface } from "../../context/form/useFormAction"
import { FormContext } from "../../context/form/formState"

import AttachmentUpload from "./attachments/"
import LegacyAttachmentUpload from "./legacyAttachments/"
import { isAttachment as isLegacyAttachment } from "./legacyAttachments/helpers"

export interface UploadPropsInterface
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  extends AnswerableComponentPropsInterface<any[]> {} // TODO replace ugly any

const Upload = (props: UploadPropsInterface) => {
  const { value } = props
  const { form }: UseFormActionInterface = useContext(FormContext)
  const { vars: { useMedias = false } = {} } = form ?? {}

  const hasLegacyAttachments = Array.isArray(value) && value.some(isLegacyAttachment)

  if (useMedias && !hasLegacyAttachments) {
    return <AttachmentUpload {...props} />
  }

  return <LegacyAttachmentUpload {...props} />
}

export default Upload
