import React, { useContext, useEffect } from "react"
import { ConfigContext } from "../../context/config/configState"
import { FormContext } from "../../context/form/formState"
import { ConversationalFormRenderer } from "../ConversationalFormRenderer/ConversationalFormRenderer"
import { SectionalFormRenderer } from "../SectionalFormRenderer/SectionalFormRenderer"
import { StandardFormRenderer } from "../StandardFormRenderer/StandardFormRenderer"
import { Watermark } from "../../components/Watermark/Watermark"

export const FormRenderer = () => {
  const { devMode, displayMode } = useContext(ConfigContext)
  const { form, formOptions, hasSections } = useContext(FormContext)
  const { displayWatermark, watermarkOptions } = form.schema.def.ui ?? {}

  const getComponent = (mode) => {
    switch (`${mode}`.toUpperCase()) {
      case "CONVERSATIONAL":
        return <ConversationalFormRenderer />

      case "SECTIONAL":
        if (hasSections) {
          return <SectionalFormRenderer />
        }
        break

      case "STANDARD":
        return <StandardFormRenderer />

      default:
        return
    }
  }

  const getDefaultMode = () => {
    if (hasSections) {
      return <SectionalFormRenderer />
    }
    return <StandardFormRenderer />
  }

  useEffect(() => {
    if (devMode) {
      window.form = form
    } else {
      delete window.form
    }
  }, [devMode, form])

  return (
    <>
      {displayWatermark && <Watermark id="form" fullCover {...watermarkOptions} />}
      {getComponent(displayMode) ?? getComponent(formOptions.displayMode ?? formOptions.mode) ?? getDefaultMode()}
    </>
  )
}
