"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DataSelector = void 0;
const Selector_1 = require("./Selector");
class DataSelector {
    constructor(data) {
        this.data = data;
        this.selector = new Selector_1.Selector();
    }
    set(selector, value) {
        this.selector.set(selector, value, this.data);
    }
    trySet(selector, value) {
        return this.selector.trySet(selector, value, this.data);
    }
    get(selector) {
        return this.selector.get(selector, this.data);
    }
    getOrNull(selector) {
        return this.selector.getOrNull(selector, this.data);
    }
    tryGet(selector) {
        return this.selector.tryGet(selector, this.data);
    }
    delete(selector) {
        this.selector.delete(selector, this.data);
    }
    tryDelete(selector) {
        return this.selector.tryDelete(selector, this.data);
    }
    has(selector) {
        return this.selector.has(selector, this.data);
    }
}
exports.DataSelector = DataSelector;
