import React, { ChangeEvent } from "react"
import useTranslation from "../../hooks/useTranslation"
import { Switch as GerudoSwitch } from "@akinoxsolutions/gerudo-ui"
import { AnswerableComponentPropsInterface } from "../interfaces"
import { Label } from "../index"
import styles from "./Switch.module.css"

export interface SwitchPropsInterface extends AnswerableComponentPropsInterface<boolean> {}

const Switch = (props: SwitchPropsInterface) => {
  const { translate } = useTranslation()
  const { autofocus, id, isDisabled, isReadOnly, name, onBlur, onChange, property, value, ...otherProps } = props

  const { default: defaultValue, enum: options, label: propertyLabel, ui = {} } = property.def
  const { switchOptions = {} } = ui
  const { reversed, spaced } = switchOptions

  let currentValue = false
  if (typeof value === "boolean") {
    currentValue = value
  } else if (typeof defaultValue === "boolean") {
    currentValue = defaultValue
  }

  const { label, tooltip } = (options && options.find((opt) => opt.value === currentValue)) || { label: propertyLabel }

  const htmlLabel = (
    <Label htmlFor={id ?? name} tooltip={tooltip}>
      {translate(label)}
    </Label>
  )

  const wrapperClassNames = [styles["switch-wrapper"]]
  if (spaced) {
    wrapperClassNames.push(styles["spaced"])
  }

  return (
    <div className={wrapperClassNames.join(" ")}>
      {reversed && htmlLabel}
      <GerudoSwitch
        aria-label={translate(label)}
        autoFocus={autofocus}
        checked={currentValue}
        className={styles["switch"]}
        disabled={isDisabled || isReadOnly}
        id={id ?? name}
        name={name}
        onBlur={onBlur}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          onChange(e.target.checked)
        }}
        {...otherProps}
      />
      {!reversed && htmlLabel}
    </div>
  )
}

export default Switch
