import { DefinitionInterface, FormInterface } from "@akinoxsolutions/formol"
import { TranslationFn } from "../hooks/useTranslation"

type EnumMember = string | DefinitionInterface

const getSortedEnum = <TEnumMember extends EnumMember = EnumMember>(
  translate: TranslationFn,
  enumData: TEnumMember[],
  direction,
): TEnumMember[] => {
  if (!direction) {
    return getTranslatedEnum(translate, enumData)
  }

  return getTranslatedEnum(translate, enumData).sort((a, b) => {
    const labelA = typeof a === "object" ? a.label : a
    const labelB = typeof b === "object" ? b.label : b

    return compareLabels(direction, labelA, labelB)
  })
}

const compareLabels = (direction: string, labelA: string, labelB: string): number => {
  switch (direction.toLowerCase()) {
    case "asc":
      return labelA.localeCompare(labelB)
    case "desc":
      return labelB.localeCompare(labelA)
    default:
      return 0
  }
}

const getTranslatedEnum = <TEnumMember extends EnumMember = EnumMember>(
  translate: TranslationFn,
  enumData: TEnumMember[],
): TEnumMember[] => {
  return enumData.map((opt) =>
    typeof opt === "object" ? { ...opt, label: translate(opt.label) } : translate(opt),
  ) as TEnumMember[]
}

const getNestedInfoFromObjectIds = (obj: undefined | DefinitionInterface, keys: string[]): unknown => {
  if (typeof obj !== "object" || keys.length === 0) return

  const remainingKeys = [...keys]
  const key = remainingKeys.shift()
  const value = obj[key as string]
  if (remainingKeys.length > 0) {
    return getNestedInfoFromObjectIds(value, remainingKeys)
  }
  return value
}

interface GetTranslationKeyToUseInterface {
  conditionalKey: Record<string, string>
  formSelectors: FormInterface["selectors"]
  key: string
  uid: string
}

const getTranslationKeyToUse = ({
  formSelectors,
  key,
  conditionalKey,
  uid,
}: GetTranslationKeyToUseInterface): string => {
  return key ?? conditionalKey[formSelectors.getConditionId(uid)]
}

const replaceSubstitutionStrings = (form: FormInterface, str: string): string => {
  let newString = str
  const matches = str.match(/\${[\w.]+}/g)
  if (matches) {
    for (const substitutionString of matches) {
      const id = substitutionString.match(/[\w]+/g) as string[]
      const substitutionValue = getNestedInfoFromObjectIds(form, id) as string
      newString = newString.replace(substitutionString, substitutionValue)
    }
  }
  return newString
}

export { getSortedEnum, getTranslatedEnum, getTranslationKeyToUse, replaceSubstitutionStrings }
